<template>
    <div v-if="!isQuickCutOpen" class="preview-timeline">
        <button class="preview-control preview-control-play-pause" :class="playClasses" @click.prevent="togglePlay">
            <svg-icon icon="play-icon" class="play" />
            <svg-icon icon="pause-icon" class="pause"></svg-icon>
        </button>
        <div
            class="preview-control-timeline"
            :class="timelineClasses"
            :style="timelineStyles"
            ref="$controlTimeline"
            @click.prevent="seek($event)"
            @mousemove="updateTimelineHandle($event)"
        >
            <ol v-if="!reduced" class="timeline-sequences">
                <li
                    v-for="(time, index) in timelineLabelTimes"
                    class="timeline-sequence"
                    :class="timelineLabelsClasses[index]"
                    :style="timelineLabelStyles[index]"
                >
                    <span v-if="isMainTimeline" class="visually-hidden">{{ index + 1 }} / {{ sequences.length }}</span>
                </li>
                <li class="timeline-handle" :class="timelineHandleClasses">
                    <div v-if="isMainTimeline" class="timeline-handle-sequence">
                        {{ timelineHoveredSequenceNumber }}
                    </div>
                    <div class="timeline-tooltip">
                        <div class="timeline-tooltip-time">{{ timelineHoveredTime }}</div>
                        <div class="timeline-tooltip-sequence">{{ timelineHoveredSequenceName }}</div>
                    </div>
                </li>
            </ol>
            <ul v-if="!reduced && !isMainTimeline" class="timeline-markers">
                <li
                    v-for="(time, index) in timelineLabelTimes"
                    class="timeline-marker"
                    :class="timelineLabelsClasses[index]"
                    :style="timelineMarkerStyles[index]"
                >
                    <a href="#" class="timeline-sequence-icon" @click.prevent.stop="seekElement(time, index)">
                        <svg-icon :icon="timelineLabelIcons[index]" />
                    </a>
                </li>
            </ul>
            <div class="timeline-progress"></div>
        </div>
        <div v-if="!reduced" class="preview-control-time">
            <span class="time-elapsed">{{ timeElapsed }}</span>
            <span class="time-total">{{ timeTotal }}</span>
        </div>
        <button class="preview-control preview-control-sound" :class="soundClasses" @click.prevent="toggleMute">
            <svg-icon icon="sound-on-icon" class="sound-on"></svg-icon>
            <svg-icon icon="sound-off-icon" class="sound-off"></svg-icon>
        </button>
    </div>
</template>

<script>
import _throttle from 'lodash/throttle';
import { mapState, mapGetters } from 'vuex';
import { Dimension, Message, Visual, conversions, STAGE_TIMELINE_UPDATED_EVENT } from 'cte-video-studio';
import { SETTINGS_ITEM_ID } from '../../../constants';

const TIMELINE_MARKER_WIDTH = 28; // Icon width in pixels
const TIMELINE_MARKER_MARGIN = 5;
const TIMELINE_MARKER_GROUP_MARGIN = 10;

export default {
    data() {
        return {
            timelineMarkerWidth: 0,
            timelineHandleLeft: 0,
            isHandlingEdit: false
        };
    },

    props: {
        reduced: {
            type: Boolean,
            default: false
        }
    },

    inject: ['$videoStudio'],

    computed: {
        ...mapState({
            sequenceMode: (state) => state.ui.sequenceMode,
            currentEditedItemId: (state) => state.ui.currentEditedItemId,
            currentTimelineId: (state) => state.preview.currentTimelineId,
            currentEditedElement: (state) => state.edition.editingElement,
            playing: (state) => state.display.timeline.playing,
            muted: (state) => state.display.timeline.muted,
            elapsedTime: (state) => state.display.timeline.elapsedTime,
            totalTime: (state) => state.display.timeline.totalTime,
            sequenceElapsedTime: (state) => state.display.timeline.sequenceElapsedTime,
            sequenceTotalTime: (state) => state.display.timeline.sequenceTotalTime,
            labels: (state) => state.display.timeline.labels,
            sequencesLabels: (state) => state.display.timeline.sequencesLabels,
            sequenceIdPrefix: (state) => state.ui.prefixes.sequenceId
        }),

        ...mapGetters({
            isMainTimeline: 'preview/isMainTimeline',
            currentTimelineLabels: 'preview/currentTimelineLabels',
            currentEditedItem: 'ui/currentEditedItem',
            loading: 'loading/active',
            seeking: 'display/seeking',
            sequences: 'sequences/allVisible',
            sequencesWithHidden: 'sequences/all',
            isQuickCutOpen: 'ui/quickcut/isOpenQuickCut'
        }),

        timelineClasses() {
            return {
                seeking: this.seeking,
                loading: this.loading
            };
        },

        timelineStyles() {
            return {
                '--controls-timeline-handle-left': this.timelineHandleLeft * 100 + Dimension.PERCENT_UNIT,
                '--controls-timeline-progress': this.timeProgress - 1
            };
        },

        playClasses() {
            return {
                playing: this.playing
            };
        },

        timelineLabels() {
            let labels = this.isMainTimeline
                ? Object.entries(this.labels).filter((entry) => entry[0].indexOf(this.sequenceIdPrefix) == 0)
                : Object.entries(this.currentTimelineLabels).filter((entry) =>
                      RegExp('^(?:' + Visual.PREFIX_ID + '|' + Message.PREFIX_ID + ')').test(entry[0])
                  );

            if (!this.isMainTimeline) {
                const regexEndTimeLabel = /_end$/;
                const endTimeLabels = Object.keys(this.currentTimelineLabels).filter(
                    (label) => regexEndTimeLabel.test(label) && this.currentTimelineLabels[label] !== 0
                );

                // Keep only labels with non-zero timing, or with matching end labels
                labels = labels.filter(([label, time]) => time !== 0 || endTimeLabels.includes(label + '_end'));
            }

            return labels.sort((a, b) => a[1] - b[1]);
        },

        timelineLabelTimes() {
            let times = this.timelineLabels.map((entry) => entry[1]);

            return this.isMainTimeline ? times : times.filter((time) => time <= this.sequenceTotalTime);
        },

        timelineLabelIcons() {
            return this.isMainTimeline
                ? this.timelineLabels.map((entry) => '')
                : this.timelineLabels.map((entry) => entry[0].split('_')[0] + '-icon');
        },

        timelineLabelsClasses() {
            return this.timelineLabels.map(([label, time]) => ({
                'timeline-sequence-played':
                    (this.isMainTimeline
                        ? time
                        : Math.min(time, this._currentTimelineEnd - this.labels[this.currentTimelineId])) <=
                    +(this.isMainTimeline ? this.elapsedTime : this.sequenceElapsedTime).toFixed(3),
                edited:
                    !this.isMainTimeline &&
                    !!this.currentEditedElement &&
                    RegExp('^' + this.currentEditedElement.id).test(label),
                'non-edited':
                    !this.isMainTimeline &&
                    !!this.currentEditedElement &&
                    !RegExp('^' + this.currentEditedElement.id).test(label)
            }));
        },

        timelineLabelStyles() {
            let totalTime = this.isMainTimeline ? this.totalTime : this.sequenceTotalTime;
            return this.timelineLabelTimes.map((time) => ({ left: (100 * time) / totalTime + Dimension.PERCENT_UNIT }));
        },

        timelineMarkerStyles() {
            let totalTime = this.isMainTimeline ? this.totalTime : this.sequenceTotalTime,
                markerStyles = [],
                markerGroups = Object.entries(
                    this.timelineLabelTimes.reduce((groups, time, index) => {
                        if (groups[time] == undefined) groups[time] = [];
                        groups[time].push(index);
                        return groups;
                    }, {})
                ).sort((a, b) => Number(a[0]) - Number(b[0]));

            markerGroups.forEach(([time, group], groupIndex) => {
                group.forEach((markerIndex, indexInGroup) => {
                    markerStyles[markerIndex] = {
                        posX:
                            indexInGroup == 0
                                ? Math.max(
                                      time / totalTime - (this.timelineMarkerWidth * group.length) / 2,
                                      -this.timelineMarkerWidth / 2
                                  )
                                : markerStyles[markerIndex - 1].posX +
                                  this.timelineMarkerMargin +
                                  this.timelineMarkerWidth,
                        posVer: indexInGroup == 0 ? 'bottom' : markerStyles[group[0]].posVer
                    };

                    if (indexInGroup == 0 && markerIndex > 0) {
                        if (
                            markerStyles[markerIndex].posX <
                            markerStyles[markerIndex - 1].posX +
                                this.timelineMarkerWidth +
                                this.timelineMarkerGroupMargin
                        ) {
                            markerStyles[markerIndex].posVer =
                                markerStyles[markerIndex - 1].posVer == 'bottom' ? 'top' : 'bottom';
                        }
                        if (
                            markerIndex > 1 &&
                            markerStyles[markerIndex - 2].posVer == markerStyles[markerIndex].posVer
                        ) {
                            markerStyles[markerIndex].posX = Math.max(
                                markerStyles[markerIndex].posX,
                                markerStyles[markerIndex - 2].posX +
                                    this.timelineMarkerWidth +
                                    this.timelineMarkerGroupMargin
                            );
                        }
                    }
                });
            });

            return markerStyles.map(({ posX, posVer }) => ({
                left: 100 * posX + Dimension.PERCENT_UNIT,
                '--marker-icon-bottom': posVer == 'bottom' ? 0 : Dimension.AUTO,
                '--marker-icon-top': posVer == 'top' ? 0 : Dimension.AUTO
            }));
        },

        timeTotal() {
            return conversions.hundredths(this.isMainTimeline ? this.totalTime : this.sequenceTotalTime, 0);
        },
        timeProgress() {
            return this.isMainTimeline
                ? this.elapsedTime / this.totalTime
                : this.sequenceElapsedTime / this.sequenceTotalTime;
        },
        timeElapsed() {
            return conversions.hundredths(this.isMainTimeline ? this.elapsedTime : this.sequenceElapsedTime, 0);
        },

        timelineHandleClasses() {
            return {
                'timeline-handle-played': this.timelineHandleLeft <= this.timeProgress
            };
        },

        timelineHoveredSequenceNumber() {
            if (!this.isMainTimeline)
                return this.sequencesWithHidden.findIndex((sequence) => sequence.id === this.currentTimelineId) + 1;

            let visibleSequencesIndex =
                Math.max(
                    1,
                    this.timelineLabelTimes.filter((time) => time <= this.timelineHandleLeft * this.totalTime).length
                ) - 1;
            return (
                this.sequencesWithHidden.findIndex(
                    (sequence) => sequence.id === this.sequences[visibleSequencesIndex].id
                ) + 1
            );
        },

        timelineHoveredSequenceName() {
            let index = this.timelineHoveredSequenceNumber - 1;
            return this.sequencesWithHidden[index] && /\S/.test(this.sequencesWithHidden[index].name)
                ? this.sequencesWithHidden[index].name
                : this.$t('Untitled sequence');
        },

        timelineHoveredTime() {
            const seconds = this.isMainTimeline
                ? this.timelineHandleLeft * this.totalTime
                : this.timelineHandleLeft * this.sequenceTotalTime;
            return conversions.hundredths(seconds, 0);
        },

        soundClasses() {
            return {
                muted: this.muted
            };
        }
    },

    watch: {
        loading(newValue) {
            if (!newValue) {
                if (this._initializing) {
                    this._initializing = false;
                    this.updateTimelineRect();
                    this.$videoStudio.studio.$stage.$el.addEventListener(
                        STAGE_TIMELINE_UPDATED_EVENT,
                        this.handleTimelineChange
                    );
                    if (this.sequenceMode) {
                        this.handleTimelineChange();
                        this.updateTimelinePlayhead();
                    }
                }
            }
        },

        currentTimelineId() {
            if (!this.sequenceMode) this.handleTimelineChange();
        },

        currentEditedItemId: {
            handler(newId, oldId) {
                if (newId === oldId || this.isHandlingEdit) return;
                this.handleEditedItemChange();
            }
        },
        'currentEditedItem.options.hidden': {
            handler() {
                if (!this.sequenceMode && !this._willRunEditedItemChangeHandler) this.handleEditedItemChange();
            }
        }
    },

    methods: {
        togglePlay() {
            if (!this.playing && !this.isMainTimeline && this._isEndSequencePause) {
                this.$videoStudio.studio.$stage.seekSequenceTimeline(this.currentTimelineId, 0.001);
            }
            this._isEndSequencePause = false;
            this.$videoStudio.studio.$stage.toggleTimeline();
        },

        toggleMute() {
            this.$videoStudio.studio.$stage.toggleMuteTimeline();
        },

        seek(event) {
            if (!this.loading) {
                let p = Math.max(0.001, (event.clientX - this._timelineRect.left) / this._timelineRect.width);
                if (this.isMainTimeline) {
                    this.$videoStudio.studio.$stage.seekTimeline(p * this.totalTime);
                } else {
                    this.$videoStudio.studio.$stage.seekSequenceTimeline(
                        this.currentTimelineId,
                        p * this.sequenceTotalTime
                    );
                    this._isEndSequencePause = false;
                }
            }
        },

        seekElement(time, index) {
            this.$videoStudio.studio.$stage.pauseTimeline();
            this.$videoStudio.studio.$stage.seekSequenceTimeline(
                this.currentTimelineId,
                Math.max(0.001, Math.min(time, this._currentTimelineEnd - this.labels[this.currentTimelineId]))
            );
            this._isEndSequencePause =
                Math.abs(this._currentTimelineEnd - this.$videoStudio.studio.$stage.getTimeline().totalTime()) < 0.02;

            let elementId = this.timelineLabels[index][0].split('_end')[0];
            this.$videoStudio.studio.$stage
                .getSequenceElement(this.currentTimelineId)
                .startEditingElement(null, elementId);
        },

        updateTimelineRect(event) {
            if (this.$refs.$controlTimeline == undefined) return;
            this._timelineRect = this.$refs.$controlTimeline.getBoundingClientRect();
            this.timelineMarkerWidth = TIMELINE_MARKER_WIDTH / this._timelineRect.width;
            this.timelineMarkerMargin = TIMELINE_MARKER_MARGIN / this._timelineRect.width;
            this.timelineMarkerGroupMargin = TIMELINE_MARKER_GROUP_MARGIN / this._timelineRect.width;
        },

        updateTimelineHandle(event) {
            if (!this.loading) {
                this.timelineHandleLeft = Math.max(
                    0,
                    Math.min((event.clientX - this._timelineRect.left) / this._timelineRect.width, 1)
                );
            }
        },

        handleTimelineChange() {
            let timeline = this.$videoStudio?.studio.$stage.getTimeline();

            if (timeline == null) return;

            if (this._currentTimelineEnd != null) timeline.removePause(this._currentTimelineEnd);
            this._isEndSequencePause = false;

            if (this.currentTimelineId != null) {
                this._currentTimelineEnd = Number(
                    (this.labels[timeline.nextLabel(this.currentTimelineId)] - 0.001).toFixed(3)
                ); // Workaround: pause js computed timing could be slightly different than expected timing
                timeline.addPause(this._currentTimelineEnd, this.pauseOnSequenceEnd);
                if (Math.abs(this._currentTimelineEnd - timeline.totalTime()) < 0.02) this._isEndSequencePause = true;
            } else {
                this._currentTimelineEnd = null;
            }
        },
        handleEditedItemChange() {
            if (this.isHandlingEdit) {
                return;
            }

            this.isHandlingEdit = true;
            this.unwatchLoading();

            try {
                const editedItem = this.$store.state.sequences[this.currentEditedItemId];
                const newTimelineId =
                    this.currentEditedItemId === SETTINGS_ITEM_ID || editedItem?.options.hidden
                        ? null
                        : this.currentEditedItemId;

                // Mettre à jour la timeline de manière synchrone
                if (this.$store.state.preview.currentTimelineId !== newTimelineId) {
                    this.$store.commit('preview/setCurrentTimelineId', newTimelineId);
                }

                if (newTimelineId) {
                    // Mise en pause si nécessaire
                    if (this.$store.state.display.timeline.playing) {
                        this.$store.commit('display/setPlaying', false);
                    }

                    // Effectuer le seek immédiatement
                    this.$videoStudio.studio.$stage.seekSequenceTimeline(newTimelineId, 0.001);

                    // Mise à jour de la timeline si nécessaire
                    if (
                        !this.$videoStudio.studio.$stage.isCurrentSequenceTimeline(newTimelineId) ||
                        !this.currentEditedElement
                    ) {
                        this.updateTimelinePlayhead();
                    }
                }

                this.isHandlingEdit = false;
            } catch (error) {
                console.warn('Error in handleEditedItemChange:', error);
                this.isHandlingEdit = false;
            }
        },

        updateTimelinePlayhead() {
            if (this.isHandlingEdit) return;

            this.unwatchLoading();
            const currentId = this.$store.state.preview.currentTimelineId;
            if (currentId) {
                this.$videoStudio.studio.$stage.seekSequenceTimeline(currentId, 0.001, true);
            }
        },

        clearPendingUpdates() {
            this._willRunEditedItemChangeHandler = false;
        },

        pauseOnSequenceEnd() {
            this.$videoStudio.studio.$stage.pauseTimeline();
            this._isEndSequencePause = true;
        },

        unwatchLoading() {
            if (this._unwatchLoading) {
                this._unwatchLoading();
                this._unwatchLoading = null;
            }
        }
    },

    mounted() {
        this._initializing = true;
        this._unwatchLoading = null;
        this._currentTimelineEnd = null;
        this._isEndSequencePause = false;
        this._willRunEditedItemChangeHandler = false;
        this.updateTimelineRect();
        window.addEventListener('resize', _throttle(this.updateTimelineRect, 200));
    },

    created() {
        this._processingUpdate = false;
    },

    beforeDestroy() {
        this.unwatchLoading();
    },
    beforeUnmount() {
        this.isHandlingEdit = false;
        this.unwatchLoading();
    }
};
</script>

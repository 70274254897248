<template>
    <header class="ui-header" :class="containerClasses">
        <div class="ui-header-content">
            <a :href="backUrl" class="ui-header-back" :title="backLabel" v-once>
                <svg-icon icon="chevron-left-icon" />
                <span>{{ backLabel }}</span>
            </a>
            <div class="ui-header-details">
                <h1 class="ui-header-title ui-header-input-title" :aria-description="$t('Brand name')">
                    <input
                        id="brand-name-input"
                        ref="$brandNameInput"
                        class="brand-name"
                        v-model.trim.lazy="brandName"
                        :disabled="readOnly"
                        @focus="startEditBrandName"
                        @blur="stopEditBrandName"
                        @keyup.enter="stopEditBrandName"
                    />
                    <label class="visually-hidden" for="brand-name-input">{{ $t('Edit brand name') }}</label>
                    <span class="brand-name-placeholder" aria-hidden="true">{{ prefixedBrandName }}</span>
                </h1>
            </div>
        </div>

        <div class="ui-header-actions">
            <div v-if="!brand.isMaster" class="ui-auto-activate">
                <ui-switch-button
                    class="action-auto-activate"
                    id="auto-activate"
                    :label="$t('Creative Flow')"
                    v-model="autoActivateToggle"
                    :disabled="readOnly"
                />
                <a href="#" @click.prevent="openAutoActivationHelp">
                    <fa-icon class="icon" icon="fa-regular fa-circle-question" />
                </a>
            </div>
            <div v-if="showTemplateTools" class="ui-template-tools">
                <ui-text-input
                    id="template-filter"
                    type="search"
                    :placeholder="searchTemplateLabel"
                    :show-label="false"
                    icon="search-icon"
                    v-model="currentTemplateFilter"
                    class="ui-template-filter"
                />

                <ui-dropdown
                    v-if="!!availableTemplateLanguages.length"
                    id="template-language"
                    class="ui-template-language"
                    :caret="false"
                    toggle-class="ui-action-button"
                    :select="true"
                    v-model="currentTemplateLanguage"
                    menu-placement="bottom-end"
                >
                    <ui-dropdown-item
                        v-for="lang in availableTemplateLanguages"
                        :key="lang.value"
                        :value="lang.value"
                        :label="lang.label"
                    />
                </ui-dropdown>

                <ui-dropdown
                    id="template-sort"
                    class="ui-template-sort"
                    toggle-class="ui-action-button"
                    :select="true"
                    :icon="true"
                    :caret="false"
                    v-model="currentTemplateSortType"
                    menu-placement="bottom-end"
                >
                    <template #dropdown-toggle>
                        <span class="visually-hidden">{{ $t('Sort templates...') }}</span>
                        <fa-icon class="icon" icon="fa-solid fa-arrow-down-wide-short" />
                    </template>
                    <ui-dropdown-item
                        v-for="(label, type) in templateSortTypes"
                        :key="type"
                        :value="type"
                        :label="label"
                    />
                </ui-dropdown>

                <ui-dropdown
                    id="template-activations"
                    class="ui-template-activations"
                    toggle-class="ui-action-button"
                    :icon="true"
                    :caret="false"
                    menu-placement="bottom-end"
                >
                    <template #dropdown-toggle>
                        <span class="visually-hidden">{{ $t('Template actions...') }}</span>
                        <svg-icon icon="menu-icon" />
                    </template>
                    <ui-dropdown-item
                        @click.prevent="toggleAllTemplates"
                        :disabled="readOnly || !hasTemplatesFiltered"
                        >{{ templatesToggleLabel }}</ui-dropdown-item
                    >
                    <ui-dropdown-item
                        @click.prevent="activateTemplates"
                        :disabled="readOnly || !hasTemplatesSelected"
                        >{{ $t('Enable') }}</ui-dropdown-item
                    >
                    <ui-dropdown-item
                        v-if="!brand.isMaster"
                        @click.prevent="deactivateTemplates"
                        :disabled="readOnly || !hasTemplatesSelected"
                        >{{ $t('Disable') }}</ui-dropdown-item
                    >
                    <ui-dropdown-item
                        v-if="canHideElement"
                        @click.prevent="hideTemplates"
                        :disabled="readOnly || !hasTemplatesSelected"
                        >{{ $t('Hide') }}</ui-dropdown-item
                    >
                    <ui-dropdown-item
                        v-if="canTryProducingTemplate(currentTemplateLibraryId)"
                        @click.prevent="startTryProducingTemplates"
                        :disabled="readOnly || !hasTemplatesSelected"
                        >{{ $t('Produce') }}</ui-dropdown-item
                    >
                </ui-dropdown>

                <button
                    v-if="canCreateTemplate(currentTemplateLibraryId)"
                    class="ui-template-create ui-loading-button"
                    :class="createButtonClasses"
                    :disabled="isCreatingTemplate"
                    :title="createTemplateLabel"
                    @click.prevent="startCreateTemplate"
                >
                    <span class="visually-hidden">{{ createTemplateLabel }}</span>
                    <fa-icon class="icon" icon="fa-solid fa-plus" />
                    <svg-icon class="loader" icon="dots-loader" />
                </button>
            </div>
        </div>
    </header>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import _debounce from 'lodash/debounce';
import { printf as sprintf } from 'fast-printf';
import {
    LIBRARY_FILTER_PROPERTIES,
    LIBRARY_SORT_AI_NAME,
    LIBRARY_SORT_CATEGORY,
    LIBRARY_SORT_LANGUAGE,
    LIBRARY_SORT_NAME,
    LIBRARY_SORT_OWNER,
    LIBRARY_SORT_STATUS,
    LIBRARY_SORT_UPDATED_AT,
    SEQUENCES_BRAND_PANEL_ID,
    TEMPLATES_BRAND_PANEL_ID
} from '../../../../constants';
import { ProvidesIcons } from '../../../../mixins';
import { UiDropdown, UiDropdownItem, UiIcon, UiSwitchButton, UiTextInput } from '../../../../components';
import { UI_DROPDOWN_SELECT_CHANGE } from '@/js/components/UiDropdown.vue';
import i18n from '@/libs/i18n.js';
import router from '@/router/index.js';
import ProduceVideoService from '@/js/application/services/video/ProduceVideoService.js';
import { useStore } from 'vuex';

const store = useStore();

const SORT_TYPES = {
    [LIBRARY_SORT_UPDATED_AT]: i18n.global.t('Last modified date'),
    [LIBRARY_SORT_NAME]: i18n.global.t('Alphabetical'),
    [LIBRARY_SORT_CATEGORY]: i18n.global.t('Category'),
    [LIBRARY_SORT_OWNER]: i18n.global.t('Creator'),
    [LIBRARY_SORT_STATUS]: i18n.global.t('Status'),
    [LIBRARY_SORT_LANGUAGE]: i18n.global.t('Language'),
    [LIBRARY_SORT_AI_NAME]: i18n.global.t('AI name')
};

export default {
    mixins: [ProvidesIcons],

    components: {
        UiDropdown,
        UiDropdownItem,
        UiIcon,
        UiTextInput,
        UiSwitchButton
    },

    data() {
        return {
            dropdownChangeEvent: UI_DROPDOWN_SELECT_CHANGE,
            backLabel: i18n.global.t('Back'),

            editBrandName: false,
            currentTemplateLibraryId: '',
            isCreatingTemplate: false
        };
    },

    computed: {
        ...mapState({
            state: (state) => state.ui,
            shortLanguage: (state) => state.ui.shortLanguage,
            readOnly: (state) => state.ui.readOnly,
            canViewAiSettings: (state) => state.ui.permissions.viewAiSettings,
            currentPanel: (state) => state.ui.currentPanel,
            brand: (state) => state.branding
        }),

        ...mapGetters({
            canCreateTemplate: 'ui/canCreateTemplate',
            canTryProducingTemplate: 'ui/canTryProducingTemplate',
            canHideElement: 'ui/canHideElement',
            needsSave: 'ui/needsSave',
            isSaving: 'ui/isSaving',
            getLibraryFiltered: 'branding/libraries/libraryFiltered',
            itemIsSelected: 'branding/libraries/libraryItemIsSelected',
            getAvailableLanguages: 'branding/libraries/libraryAvailableLanguages'
        }),

        containerClasses() {
            return {
                saving: this.isSaving
            };
        },

        brandName: {
            get() {
                return this.editBrandName ? this.brand.name : this.prefixedBrandName;
            },
            set(value) {
                value = value.replace(/\s+/g, ' ');
                if (!/\S/.test(value)) value = this.$t('Untitled brand');
                this.$store.commit('branding/setName', value);
            }
        },

        prefixedBrandName() {
            return !this.brand.isMaster ? this.brand.name : sprintf(this.$t('MASTER - %1$s'), this.brand.name);
        },

        autoActivateToggle: {
            get() {
                return this.brand.autoActivationEnabled;
            },
            set(value) {
                this.updateAutoActivation(value);
            }
        },

        showTemplateTools() {
            return [SEQUENCES_BRAND_PANEL_ID, TEMPLATES_BRAND_PANEL_ID].includes(this.currentPanel);
        },

        currentTemplateLibrary() {
            return this.brand.libraries[this.currentTemplateLibraryId] || null;
        },

        currentTemplateFilter: {
            get() {
                return this.brand.libraries.filters[this.currentTemplateLibraryId] || '';
            },
            set(filter) {
                this._debouncedSetTemplateFilter(filter);
            }
        },

        currentTemplateLibraryFiltered() {
            return this.getLibraryFiltered(this.currentTemplateLibraryId, false, LIBRARY_FILTER_PROPERTIES);
        },

        hasTemplatesFiltered() {
            return !!this.currentTemplateLibraryFiltered.length;
        },

        availableTemplateLanguages() {
            return this.getAvailableLanguages(this.currentTemplateLibraryId, false)
                .map((language) => ({ value: language, label: this.$t('studio.template_languages.' + language) }))
                .sort((languageA, languageB) => languageA.label.localeCompare(languageB.label, this.shortLanguage));
        },

        currentTemplateLanguage: {
            get() {
                return (
                    this.brand.libraries.languages[this.currentTemplateLibraryId] ||
                    this.availableTemplateLanguages[0]?.value
                );
            },
            set(language) {
                this.$store.commit('branding/libraries/setLanguage', { name: this.currentTemplateLibraryId, language });
            }
        },

        templateSortTypes() {
            let { [LIBRARY_SORT_LANGUAGE]: languageType, [LIBRARY_SORT_AI_NAME]: aiNameType, ...types } = SORT_TYPES;

            if (!!this.availableTemplateLanguages.length) {
                types[LIBRARY_SORT_LANGUAGE] = languageType;
            }

            if (this.canViewAiSettings && this.currentTemplateLibraryId == 'sequenceTemplates') {
                types[LIBRARY_SORT_AI_NAME] = aiNameType;
            }

            return types;
        },

        currentTemplateSortType: {
            get() {
                return this.brand.libraries.sortTypes[this.currentTemplateLibraryId] || '';
            },
            set(type) {
                this.$store.commit('branding/libraries/setSortType', { name: this.currentTemplateLibraryId, type });
            }
        },

        templatesToggleLabel() {
            if (!this.currentTemplateLibraryId) return '';

            return this.allTemplatesAreSelected ? this.$t('Deselect all') : this.$t('Select all');
        },

        allTemplatesAreSelected() {
            return (
                this.hasTemplatesFiltered &&
                this.currentTemplateLibraryFiltered.every((item) =>
                    this.itemIsSelected(this.currentTemplateLibraryId, item.id)
                )
            );
        },

        currentTemplateLibraryFilteredAndSelected() {
            return this.currentTemplateLibraryFiltered.filter((item) =>
                this.itemIsSelected(this.currentTemplateLibraryId, item.id)
            );
        },

        hasTemplatesSelected() {
            return !!this.currentTemplateLibraryFilteredAndSelected.length;
        },

        searchTemplateLabel() {
            switch (this.currentTemplateLibraryId) {
                case 'templates':
                    return this.$t('Search a template');
                case 'sequenceTemplates':
                    return this.$t('Search a sequence template');
            }

            return '';
        },

        createTemplateLabel() {
            switch (this.currentTemplateLibraryId) {
                case 'templates':
                    return this.$t('Create a new template');
                case 'sequenceTemplates':
                    return this.$t('Create a new sequence template');
            }

            return '';
        },

        createButtonClasses() {
            return {
                loading: this.isCreatingTemplate
            };
        },

        backUrl() {
            return (
                window.location.origin + (router.options.history.state.back ?? router.resolve({ name: 'Home' }).href)
            );
        }
    },

    watch: {
        currentPanel(newValue, oldValue) {
            this.updateTemplateLibraryId(newValue);
        },

        availableTemplateLanguages: {
            handler(newValue) {
                if (!!newValue.length && !newValue.find((language) => language.value == this.currentTemplateLanguage)) {
                    this.currentTemplateLanguage = this.availableTemplateLanguages[0].value;
                }
            },
            deep: true
        }
    },

    methods: {
        ...mapMutations({
            addModal: 'ui/modals/add',
            setFilter: 'branding/libraries/setFilter'
        }),

        ...mapActions({
            createTemplate: 'ui/createTemplate',
            updateAutoActivation: 'branding/updateAutoActivation',
            hideItem: 'branding/libraries/hideItem',
            deactivateItem: 'branding/libraries/deactivateItem',
            activateItem: 'branding/libraries/activateItem'
        }),

        sprintf,

        updateTemplateLibraryId(panelId) {
            if (panelId == SEQUENCES_BRAND_PANEL_ID) this.currentTemplateLibraryId = 'sequenceTemplates';
            else if (panelId == TEMPLATES_BRAND_PANEL_ID) this.currentTemplateLibraryId = 'templates';
            else this.currentTemplateLibraryId = '';
        },

        startEditBrandName() {
            this.editBrandName = true;
            this.$nextTick(() => {
                this.$refs['$brandNameInput'].setSelectionRange(0, this.brandName.length);
            });
        },

        stopEditBrandName() {
            this.editBrandName = false;
            this.$refs['$brandNameInput'].blur();
        },

        openAutoActivationHelp() {
            this.addModal({
                id: 'auto-activation-help',
                type: 'modal-auto-activation'
            });
        },

        setTemplateFilter(filter) {
            this.setFilter({ name: this.currentTemplateLibraryId, filter });
        },

        toggleAllTemplates() {
            let selection = !this.allTemplatesAreSelected
                ? this.currentTemplateLibraryFiltered.map((item) => item.id)
                : [];
            this.$store.commit('branding/libraries/setLibrarySelection', {
                library: this.currentTemplateLibraryId,
                selection
            });
        },

        activateTemplates() {
            this.currentTemplateLibraryFilteredAndSelected.forEach((template) => {
                this.activateItem({ library: this.currentTemplateLibraryId, id: template.id });
            });
        },

        deactivateTemplates() {
            this.currentTemplateLibraryFilteredAndSelected.forEach((template) => {
                this.deactivateItem({ library: this.currentTemplateLibraryId, id: template.id });
            });
        },

        hideTemplates() {
            this.currentTemplateLibraryFilteredAndSelected.forEach((template) => {
                this.hideItem({ library: this.currentTemplateLibraryId, id: template.id });
            });
        },

        async startTryProducingTemplates() {
            const templateIds = this.currentTemplateLibraryFilteredAndSelected.map((template) => template.id);
            try {
                await new ProduceVideoService(store).handleTemplates(templateIds, this.brand.id);
            } catch {}
        },

        async startCreateTemplate() {
            let newTemplate;
            if (!this.needsSave) {
                newTemplate = await this.handleCreateTemplate();
                router.push(this.getRoute(newTemplate));
            } else {
                this.addModal({
                    id: 'create-template',
                    type: 'modal-create-without-saving',
                    actionLabel: this.$t('Create without saving'),
                    waitingAction: async () => {
                        newTemplate = await this.handleCreateTemplate();
                    },
                    afterDestroyAction: () => {
                        if (newTemplate) router.push(this.getRoute(newTemplate));
                    }
                });
            }
        },

        async handleCreateTemplate() {
            this.isCreatingTemplate = true;
            try {
                return await this.createTemplate(this.currentTemplateLibraryId);
            } catch (error) {
                this.isCreatingTemplate = false;
                // TODO: Display an error notification
            }
        },

        getRoute(resource) {
            return router.resolve({
                name: resource.is_sequence_template ? 'EditSequenceTemplate' : 'EditTemplate',
                params: { id: resource.id, brandId: this.brand.id }
            });
        }
    },

    created() {
        this._debouncedSetTemplateFilter = _debounce(this.setTemplateFilter, 600);
        this.updateTemplateLibraryId(this.currentPanel);
    },

    mounted() {
        //
    }
};
</script>

import simpleMedia from '@/js/store/modules/commons/simple-media.js';

const state = () => ({
    ...simpleMedia.state(),
    useBranding: false
});

const getters = {
    source: (state, getters, rootState) => (!state.useBranding ? state.src : rootState.branding.logo.src)
};

const mutations = {
    ...simpleMedia.mutations,

    useBranding(state, use) {
        state.useBranding = use;
    }
};

const actions = {
    ...simpleMedia.actions,

    init({ commit }, data) {
        simpleMedia.actions.init({ commit }, data);
        commit('useBranding', data.useBranding || false);
    }
};

export default {
    namespaced: true,

    state,
    getters,
    mutations,
    actions
};

<template>
    <ui-card
        :id="cardElementId"
        :class="[{ 'ui-card-editing': editing }, 'ui-card-' + id, 'video-onboarding-step-3-content']"
        :title="$t('Sequence')"
        v-on:mousedown="editingSequence"
    >
        <template #header-icon>
            <fa-icon class="icon" icon="fa-solid fa-play" />
        </template>
        <template #menu-content>
            <UiBackgroundCopySelector :seqId="seqId" :id="id" />
            <UiBackgroundPasteSelector :seqId="seqId" :id="id" />
        </template>

        <UiBackgroundContainer :seq-id="seqId" :cardElementId="cardElementId" />

        <template #footer>
            <UiBackgroundStyleSelector :seqId="seqId" :cardElementId="cardElementId" :readOnly="readOnly" />
            <UiMusicVolumeSelector :seqId="seqId" :cardElementId="cardElementId" :readOnly="readOnly" />
            <UiBackgroundDurationSelector :seqId="seqId" :cardElementId="cardElementId" :readOnly="readOnly" />
        </template>
    </ui-card>
</template>

<script>
import { SEQUENCE_ELEMENT_ID } from '../../../../../constants';
import { defineComponent, computed, getCurrentInstance } from 'vue';
import { useStore } from 'vuex';

import UiLocationSelector from '../UiLocationSelector.vue';
import UiIcon from '../../../../../components/UiIcon.vue';
import UiBackgroundContainer from '../UiBackgroundContainer.vue';
import UiBackgroundOverlaySelector from '../UiBackgroundOverlaySelector.vue';
import UiMusicVolumeSelector from '../selectors/volume/UiMusicVolumeSelector.vue';
import UiBackgroundDurationSelector from '../selectors/duration/UiBackgroundDurationSelector.vue';
import UiBackgroundStyleSelector from '../selectors/style/UiBackgroundStyleSelector.vue';
import UiBackgroundCopySelector from '../selectors/ui/UiBackgroundCopySelector.vue';
import UiBackgroundPasteSelector from '../selectors/ui/UiBackgroundPasteSelector.vue';
import UiCard from '../../../../../components/UiCard.vue';

export default defineComponent({
    inject: ['$videoStudio'],
    name: 'CardSequence',
    components: {
        UiCard,
        UiBackgroundOverlaySelector,
        UiBackgroundContainer,
        UiIcon,
        UiLocationSelector,
        UiMusicVolumeSelector,
        UiBackgroundDurationSelector,
        UiBackgroundStyleSelector,
        UiBackgroundCopySelector,
        UiBackgroundPasteSelector
    },
    props: {
        id: {
            type: String,
            default: SEQUENCE_ELEMENT_ID
        },
        seqId: {
            type: String,
            default: ''
        },
        editing: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const store = useStore();
        const cardElementId = computed(() => props.seqId + '-' + props.id);
        const readOnly = computed(() => store.getters['ui/reoadOnly']);
        const instance = getCurrentInstance();

        function editingSequence() {
            instance.proxy.$videoStudio.studio.$stage.startEditingSequence(props.seqId);
        }

        return {
            cardElementId,
            editingSequence,
            readOnly
        };
    }
});
</script>

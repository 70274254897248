/**
 * Edit video UI history Vuex store
 */

import { nextTick } from 'vue';
import _isString from 'lodash/isString';
import _merge from 'lodash/merge';
import { getPrefixedUuid } from '@/js/utils';
import { filterState } from '@/js/videos/utils';
import { memoize, throttle } from 'lodash';

const memoizedFilterState = memoize(filterState, (...args) => JSON.stringify(args));

const STEP_MARKER = 'history-step';
const LOADING_PREFIX = 'history-';
const EXCLUDED_MUTATIONS = [
    'ui/history/.*',
    'ui/clipboard/.*',
    'ui/processes/.*',
    'ui/quickcut/.*',
    'ui/modals/.*',
    'ui/setEditionMode',
    'ui/setSaveTimestamp',
    'ui/setSaveStatus',
    'ui/setSaveError',
    'ui/setCurrentLibrary.*',
    'ui/setCurrentMediaLibrary.*',
    'ui/setCurrentCaption.*',
    'preview/.*',
    'display/(?!setFormatSize).*',
    'edition/.*',
    'loading/.*',
    'branding/libraries/.*',
    'settings/setAiUrl',
    'sequences/[^/]+/setEl',
    'sequences/[^/]+/setBackgroundEl',
    'sequences/[^/]+(?:/[^/]+)?/set.*Captioning(?:Status)?',
    '.*Timeline(?:Out)?',
    '.*/enable[^/]*Refresh'
];

const isExcludedMutation = (mutation) => {
    return EXCLUDED_MUTATIONS.reduce(
        (excluded, exclusion) => excluded || RegExp('^' + exclusion + '$').test(mutation),
        false
    );
};

const recordSteps = (mutation, state) => {
    if (state.ui.history.recording && !isExcludedMutation(mutation.type)) {
        state.ui.history.undoSteps.push(mutation);
    }
};

const waitLoadingEnd = function (mutation) {
    if (
        mutation.type == 'loading/prepared' &&
        _isString(mutation.payload) &&
        RegExp('^' + LOADING_PREFIX).test(mutation.payload) &&
        !this.state.loading.preparing.filter((asset) => _isString(asset) && RegExp('^' + LOADING_PREFIX).test(asset))
            .length
    ) {
        unsubscribeLoading();
        this.dispatch('ui/history/startRecording');
    }
};

let unsubscribeRecord = () => {};
let unsubscribeLoading = () => {};

const state = {
    originalState: () => {},

    recording: false,
    undoSteps: [],
    redoSteps: []
};

const getters = {
    canUndo: (state, getters, rootState, rootGetters) =>
        !!state.undoSteps.length &&
        state.undoSteps.lastIndexOf(STEP_MARKER) != -1 &&
        !rootGetters['loading/active'] &&
        !rootGetters['ui/libraryIsShown'] &&
        !rootGetters['ui/mediaLibraryIsShown'] &&
        (!rootGetters['ui/captionEditorIsShown'] ||
            (!rootState.ui.currentCaptionButton?.captioning &&
                !rootState.ui.currentCaptionTranslating &&
                getters.latestUndoStep.length &&
                getters.latestUndoStep[0].type === rootState.ui.currentCaptionButton?.modifier)),

    canRedo: (state, getters, rootState, rootGetters) =>
        !!state.redoSteps.length &&
        state.redoSteps.lastIndexOf(STEP_MARKER) != -1 &&
        !rootGetters['loading/active'] &&
        !rootGetters['ui/libraryIsShown'] &&
        !rootGetters['ui/mediaLibraryIsShown'] &&
        (!rootGetters['ui/captionEditorIsShown'] ||
            (!rootState.ui.currentCaptionButton?.captioning &&
                !rootState.ui.currentCaptionTranslating &&
                getters.latestRedoStep.length &&
                getters.latestRedoStep[0].type === rootState.ui.currentCaptionButton?.modifier)),

    latestUndoStep: (state) => {
        let marker = state.undoSteps.lastIndexOf(STEP_MARKER);
        if (marker == -1) return [];

        return state.undoSteps.slice(marker + 1);
    },

    latestRedoStep: (state) => {
        let marker = state.redoSteps.lastIndexOf(STEP_MARKER);
        if (marker == -1) return [];

        return state.redoSteps.slice(marker + 1);
    }
};

const mutations = {
    setOriginalState(state, originalState) {
        state.originalState = () => originalState;
    },

    setRecording(state, recording) {
        state.recording = recording;
    },

    pushUndoStep(state, mutation) {
        state.undoSteps.push(mutation);
    },

    emptyRedoSteps(state, mutation) {
        state.redoSteps = [];
    },

    undoStep(state, keep = true) {
        let marker = state.undoSteps.lastIndexOf(STEP_MARKER);
        if (marker != -1) {
            let steps = state.undoSteps.splice(marker, state.undoSteps.length - marker);
            if (keep) state.redoSteps.splice(state.redoSteps.length, 0, ...steps);
        }
    },

    redoStep(state, mutation) {
        let marker = state.redoSteps.lastIndexOf(STEP_MARKER);
        if (marker != -1) {
            let steps = state.redoSteps.splice(marker, state.redoSteps.length - marker);
            state.undoSteps.splice(state.undoSteps.length, 0, ...steps);
        }
    }
};

const actions = {
    saveOriginalState({ rootState, commit }) {
        const { loading, ...filteredState } = rootState;
        // TODO NOTE: including loading property in the filterState function throws a Vue warning and makes the production compilation crash

        const throttledCommit = throttle((state) => {
            commit('setOriginalState', state);
        }, 1000);

        const filteredResult = memoizedFilterState(
            filteredState,
            ['ui', 'display', 'settings', 'sequences'],
            [
                'sequences.cached',
                'sequences.*.audio.track.captioning',
                'sequences.*.background.video.captioning',
                'sequences.*.*.video.captioning'
            ]
        );

        throttledCommit(filteredResult);
    },

    // saveOriginalState({ rootState, commit }) {
    //     // TODO NOTE: including loading property in the filterState function throws a Vue warning and makes the production compilation crash
    //     const { loading, ...filteredState } = rootState;

    //     commit(
    //         'setOriginalState',
    //         filterState(
    //             filteredState,
    //             ['ui', 'display', 'settings', 'sequences'],
    //             [
    //                 'sequences.cached',
    //                 'sequences.*.audio.track.captioning',
    //                 'sequences.*.background.video.captioning',
    //                 'sequences.*.*.video.captioning'
    //             ]
    //         )
    //     );
    // },

    resetState({ state, dispatch }) {
        dispatch('ui/resetState', null, { root: true });
        dispatch('display/setFormat', _merge({}, state.originalState().display.format), { root: true });
        dispatch('settings/resetState', null, { root: true });
        dispatch('sequences/resetState', null, { root: true });
    },

    endResetState({ state, dispatch }) {
        dispatch('sequences/endResetState', null, { root: true });
    },

    startRecording({ state, commit }) {
        commit('setRecording', true);
        unsubscribeRecord();
        unsubscribeRecord = this.subscribe(recordSteps);
    },

    stopRecording({ state, commit }) {
        unsubscribeRecord();
        commit('setRecording', false);
    },

    startStep({ commit }) {
        commit('pushUndoStep', STEP_MARKER);
        commit('emptyRedoSteps');
    },

    ignoreStep({ commit, dispatch }, actions) {
        let loadingId = getPrefixedUuid(LOADING_PREFIX);
        commit('loading/prepare', loadingId, { root: true });
        dispatch('stopRecording').then(() => {
            unsubscribeLoading();
            unsubscribeLoading = this.subscribe(waitLoadingEnd.bind(this));

            actions();

            nextTick(() => {
                commit('loading/prepared', loadingId, { root: true });
            });
        });
    },

    undoStep({ state, commit, dispatch }, { keep = true, total = 1, save = true } = {}) {
        let marker = state.undoSteps.lastIndexOf(STEP_MARKER);
        if (marker != -1) {
            let loadingId = getPrefixedUuid(LOADING_PREFIX);
            commit('loading/prepare', loadingId, { root: true });
            return dispatch('stopRecording').then(() => {
                unsubscribeLoading();
                unsubscribeLoading = this.subscribe(waitLoadingEnd.bind(this));

                commit('edition/setEditingElement', null, { root: true });
                for (let i = 0; i < total; ++i) {
                    commit('undoStep', keep);
                }
                dispatch('resetState');
                state.undoSteps.forEach((mutation) => {
                    if (mutation != STEP_MARKER) {
                        commit(mutation.type, mutation.payload, { root: true });
                    }
                });
                dispatch('endResetState');

                if (save) dispatch('ui/saveVideo', null, { root: true });

                nextTick(() => {
                    commit('loading/prepared', loadingId, { root: true });
                });
            });
        }
    },

    redoStep({ state, getters, commit, dispatch }) {
        let redoSteps = getters.latestRedoStep;
        if (redoSteps.length != -1) {
            let loadingId = getPrefixedUuid(LOADING_PREFIX);
            commit('loading/prepare', loadingId, { root: true });
            return dispatch('stopRecording').then(() => {
                unsubscribeLoading();
                unsubscribeLoading = this.subscribe(waitLoadingEnd.bind(this));

                commit('edition/setEditingElement', null, { root: true });
                commit('redoStep');
                redoSteps.forEach((mutation) => {
                    commit(mutation.type, mutation.payload, { root: true });
                });

                dispatch('ui/saveVideo', null, { root: true });

                nextTick(() => {
                    commit('loading/prepared', loadingId, { root: true });
                });
            });
        }
    }
};

export default {
    namespaced: true,

    modules: {
        //
    },

    state,
    getters,
    mutations,
    actions
};

<template>
    <div class="ui-library-selector" :class="containerClasses">
        <button
            :id="selectorId"
            class="selector"
            :class="selectorClasses"
            :style="previewStyles"
            :disabled="disabled"
            @click="showLibrary"
        >
            <span class="visually-hidden">{{ currentLabel }}</span>
        </button>
        <div v-if="isEmpty || !currentMetadata" class="selector-empty">
            <svg class="border">
                <rect x="0" y="0" width="100%" height="100%" rx="10" fill="none" />
            </svg>
            <fa-icon class="icon" :icon="icon" />
        </div>
        <div v-else class="selector-actions">
            <button class="action-change" :title="$t('Change selection')" @click="showLibrary">
                <span class="visually-hidden">{{ $t('Change selection') }}</span>
                <fa-icon class="icon" icon="fa-solid fa-rotate" />
            </button>
            <button class="action-remove" :title="$t('Remove selection')" @click="handleRemove">
                <span class="visually-hidden">{{ $t('Remove selection') }}</span>
                <svg-icon icon="close-icon" />
            </button>
        </div>
        <label :class="labelClasses" :for="selectorId" :aria-hidden="!showLabel || null">{{ currentLabel }}</label>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import UiIcon from './UiIcon.vue';

export const UI_LIBRARY_SELECTOR_SHOW = 'ui-library-selector-show';
export const UI_LIBRARY_SELECTOR_HIDE = 'ui-library-selector-hide';
export const UI_LIBRARY_SELECTOR_PREVIEW_CHANGE = 'ui-library-selector-preview-change';
export const UI_LIBRARY_SELECTOR_CHANGE = 'update:modelValue';

export default {
    emits: [UI_LIBRARY_SELECTOR_SHOW, UI_LIBRARY_SELECTOR_PREVIEW_CHANGE, UI_LIBRARY_SELECTOR_CHANGE],

    components: {
        UiIcon
    },

    props: {
        id: {
            type: String
        },
        modelValue: {
            type: String,
            default: ''
        },
        defaultValue: {
            type: String,
            default: ''
        },
        emptyValue: {
            type: String,
            default: ''
        },
        library: {
            type: String,
            default: ''
        },
        category: {
            type: String,
            default: ''
        },
        tagOrder: {
            type: [Array, Function],
            default: null
        },
        previewPath: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: ''
        },
        updateLabel: {
            type: String,
            default: ''
        },
        showLabel: {
            type: Boolean,
            default: true
        },
        useSelectionLabel: {
            type: Boolean,
            default: true
        },
        icon: {
            type: String,
            default: 'fa solid fa-plus'
        },
        triggerOpening: {
            type: Boolean,
            default: false
        },
        showError: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            currentValue: this.modelValue || this.defaultValue
        };
    },

    computed: {
        ...mapState({
            selectedItemId: (state) => state.ui.currentLibrary.selectedItemId
        }),

        ...mapGetters({
            findLibraryItem: 'branding/libraries/findLibraryItem'
        }),

        isEmpty() {
            return this.currentValue === this.emptyValue;
        },

        currentMetadata() {
            if (this.isEmpty) return null;
            return this.findLibraryItem(this.library, this.category, this.currentValue) || null;
        },

        currentLabel() {
            // if (this.forceLabel) return this.forceLabel;
            if (this.isEmpty || !this.useSelectionLabel || !this.currentMetadata)
                return this.label || this.$t('Select...');
            else if (this.updateLabel && !this.isEmpty) return this.updateLabel;
            return (
                this.$t('studio.tags.' + this.currentMetadata.tags[0]) +
                ' ' +
                (this.currentMetadata.tagIndexes[this.currentMetadata.tags[0]] + 1)
            );
        },

        containerClasses() {
            return {
                disabled: this.disabled
            };
        },

        selectorId() {
            return this.id + '-selector';
        },

        selectorClasses() {
            return {
                empty: this.isEmpty || !this.currentMetadata
            };
        },

        previewStyles() {
            if (this.isEmpty || !this.currentMetadata) return {};

            return {
                backgroundImage: `url(/assets/thumbnails/${this.previewPath + this.currentValue}.gif)`
            };
        },

        labelClasses() {
            return {
                'visually-hidden': !this.showLabel,
                'text-error-color': this.showError
            };
        }
    },

    watch: {
        modelValue(newValue) {
            this.currentValue = newValue || this.defaultValue;
        }
    },

    methods: {
        showLibrary() {
            this._savedValue = this.currentValue;
            this.$emit(UI_LIBRARY_SELECTOR_SHOW, this);
        },

        hideLibrary() {
            this.$el.dispatchEvent(new Event(UI_LIBRARY_SELECTOR_HIDE));
        },

        select() {
            this.currentValue = this.selectedItemId;
            this.emitChangeEvent();
            this.hideLibrary();
        },

        cancel() {
            this.$emit(UI_LIBRARY_SELECTOR_PREVIEW_CHANGE, this._savedValue, true);
            this.hideLibrary();
        },

        previewChange() {
            this.$emit(UI_LIBRARY_SELECTOR_PREVIEW_CHANGE, this.selectedItemId);
        },

        handleRemove(event) {
            this.currentValue = this.emptyValue;
            this.emitChangeEvent();
            this.hideLibrary();
        },

        emitChangeEvent() {
            this.$emit(UI_LIBRARY_SELECTOR_CHANGE, this.currentValue);
        }
    },

    mounted() {
        this._savedValue = null;
        if (this.triggerOpening) this.showLibrary();
    }
};
</script>

/**
 * Edit video UI Vuex store
 */

import _debounce from 'lodash/debounce';
import _merge from 'lodash/merge';
import _throttle from 'lodash/throttle';
import axios from 'axios';
import { Format } from 'cte-video-studio';
import i18n from '@/libs/i18n';
import {
    CAPTIONS_STATUS_DISPLAY_TIME,
    CAPTIONS_STATUS_ERROR,
    CARD_CLIPBOARD_TYPE,
    CONTENT_EDITION_MODE,
    DEFAULT_TEMPLATE_LANGUAGE,
    EMOTION_EDITION_MODE,
    isBrandPanel,
    isCaptionsStatus,
    isEditionMode,
    isSaveStatus,
    isSequenceTemplateStatus,
    SEQUENCE_CLIPBOARD_TYPE,
    SEQUENCE_TEMPLATE_STATUS_COMPLETED,
    SEQUENCE_TEMPLATE_STATUS_DISPLAY_TIME,
    SEQUENCE_TEMPLATE_STATUS_ERROR,
    SETTINGS_BRAND_PANEL_ID,
    SETTINGS_ITEM_ID,
    STATUS_AUTH_ERROR,
    STATUS_NO_CHANGE,
    STATUS_REFRESHING_TOKEN,
    STATUS_SAVE_ERROR,
    STATUS_SAVED,
    STATUS_SAVING,
    STATUS_UNSAVED,
    TTS_STATUS_COMPLETED
} from '../../../constants';
import { maybeRefreshLogin, requestWithServiceTokenRefresh, resolveMediaApiUrl } from '@/js/utils';
import {
    filterState as filterStateVideo,
    formatSequenceStateForSave,
    formatStateForSave as formatStateForSaveVideo,
    startCaptionsTranslating
} from '@/js/videos/utils';
import { filterState as filterStateBrand, formatStateForSave as formatStateForSaveBrand } from '@/js/brands/utils';
import modals from './modals';
import history from './history';
import clipboard from './clipboard';
import processes from './processes';
import quickcut from './quickcut';
import files from './files';
import _isEqual from 'lodash/isEqual.js';
import accordions from './accordions.js';
import { setTemplateThumbnail } from '@/js/videos/application/services/SaveVideoService.js';

let _feedbackTimeoutId = null;
let _captionsTranslationController = null;

const axiosInstance = axios.create({
    withCredentials: true
});

const defaultState = () => ({
    userId: null,
    organizationId: null,
    organizationName: '',
    folderId: null,
    isCte: false,
    isSupervisor: false,
    supportEmail: '',
    language: 'fr-FR',
    shortLanguage: 'fr',
    availableTemplateLanguages: [DEFAULT_TEMPLATE_LANGUAGE],
    defaultTemplateCategory: null,
    availableTemplateCategories: [],
    availableTemplateFormats: [],
    availableBrands: [],
    defaultSequenceCategory: null,
    availableSequenceCategories: [],
    postMessageAllowedOrigins: [],

    readOnly: false,
    studioDataVersion: null,
    dataVersion: null,

    mediaVersion: null,

    permissions: {
        changeEditionMode: true,
        editAiSettings: false,
        useSequenceTemplate: true,
        create: {
            templates: false,
            sequenceTemplates: false
        },
        canResize: true,
        produce: {
            templates: false,
            sequenceTemplates: false
        },
        hideElement: false,
        viewAiSettings: false
    },

    restrictions: {
        maxSequenceMessageCards: null,
        maxSequenceVisualCards: null,
        disableRightAlignment: false,
        enableTTS: true
    },

    autoSave: true,
    saveStatus: STATUS_NO_CHANGE,
    saveTimestamp: null,
    saveError: null,

    sequenceMode: false,

    editionMode: EMOTION_EDITION_MODE,

    currentEditedItemId: SETTINGS_ITEM_ID,
    currentLibrary: {
        id: null,
        category: null,
        tagOrder: null,
        selectedItemId: null,
        selector: null
    },
    currentMediaLibrary: {
        mediaType: null,
        selectedMediaId: null,
        selector: null
    },
    currentCaptionButton: null,
    currentCaptionTranslating: false,

    availableFormats: [],

    showTTSEditor: false,
    ttsEditorConfig: {
        seqId: null,
        useInSequence: false
    },
    ttsStatus: null,

    captionsConfig: {
        languages: []
    },
    captionsStatus: null,
    captionsError: null,

    translationConfig: {
        languages: []
    },

    sequenceTemplateStatus: null,
    sequenceTemplateError: null,

    aiSequences: null,

    urls: {
        back: '',
        showVideo: '',
        saveVideo: '',
        setThumbnail: '',
        createSequenceFrom: '',
        libraries: {
            sequenceTemplates: ''
        },
        savePreferences: '',
        listAiSequences: '',
        editAiSequence: '',
        mediaLibrary: '',
        mediaCreate: '',
        mediaThumbnail: '',
        assetAudio: '',
        mediaPreview: '',
        mediaCaptioning: '',
        mediaCaptioningStatus: '',
        assetThumbnail: '',
        assetMusic: '',
        refreshServiceToken: '',
        refreshLogin: '',
        translationService: '',
        greenVideoInfo: '',
        universalMusicDiscover: '',
        universalMusicTos: '',
        saveBrand: '',
        create: {
            templates: '',
            sequenceTemplates: ''
        },
        produce: {
            templates: '',
            sequenceTemplates: ''
        },
        assetImage: '',
        ssoRefreshLogin: '',
        ssoCheckRefreshLogin: '',
        base: '',
        assets: '',
        listPrompts: '',
        createVideos: ''
    },

    prefixes: {
        sequenceId: '',
        mediaReference: '',
        musicReference: '',
        settingsReference: ''
    },

    currentPanel: SETTINGS_BRAND_PANEL_ID,

    defaultBrandId: null,
    availableFolders: [],
    videoQuantity: 1,
    hasRequestedVideoCreation: false,

    originalState: () => {}
});

const state = {
    ...defaultState()
};

const getters = {
    isEmotionMode: (state) => state.editionMode == EMOTION_EDITION_MODE,

    isContentMode: (state) => state.editionMode == CONTENT_EDITION_MODE,

    canChangeEditionMode: (state, getters) => state.permissions.changeEditionMode && !getters.libraryIsShown,

    currentEditedItem: (state, getters, rootState) =>
        state.currentEditedItemId == SETTINGS_ITEM_ID
            ? rootState.settings
            : rootState.sequences[state.currentEditedItemId],

    libraryIsShown: (state) => !!state.currentLibrary.id,

    mediaLibraryIsShown: (state) => !!state.currentMediaLibrary.mediaType,

    captionEditorIsShown: (state) => state.currentCaptionButton != null,

    canAddEmptySequence: (state, getters) => !state.readOnly && !state.sequenceMode,

    canAddSequence: (state) => !state.readOnly && !state.sequenceMode,

    canMoveSequence: (state) => !state.readOnly && !state.sequenceMode,

    canRemoveSequence: (state, getters, rootState, rootGetters) =>
        !state.readOnly && !state.sequenceMode && rootGetters['sequences/allVisible'].length > 1,

    canCopySequence: (state, getters) => !state.readOnly,

    canPasteSequence: (state, getters) =>
        !state.readOnly &&
        getters.canAddSequence &&
        getters['clipboard/hasPastableDataOfType'](SEQUENCE_CLIPBOARD_TYPE),

    canCreateSequenceTemplate: (state, getters) =>
        !state.readOnly && !state.sequenceMode && getters.isEmotionMode && state.permissions.create.sequenceTemplates,

    canUseSequenceTemplate: (state, getters) =>
        !state.readOnly && !state.sequenceMode && state.permissions.useSequenceTemplate,

    canCopyCard: (state, getters) => (cardId) => !state.readOnly && getters.isEmotionMode,

    canPasteCard: (state, getters) => (cardId) =>
        !state.readOnly &&
        getters.isEmotionMode &&
        getters['clipboard/hasPastableDataOfType'](CARD_CLIPBOARD_TYPE + cardId),

    canDuplicateCard: (state, getters) => !state.readOnly && getters.isEmotionMode,

    canRemoveCard: (state, getters) => !state.readOnly && getters.isEmotionMode,

    canRemoveMessage: (state, getters) => !state.readOnly && getters.isEmotionMode,

    canRemoveVisual: (state, getters) => !state.readOnly && getters.isEmotionMode,

    canRemovePanel: (state, getters) => !state.readOnly && getters.isEmotionMode,

    canRemoveAudio: (state, getters) => !state.readOnly && getters.isEmotionMode,

    canRemoveTransition: (state, getters) => !state.readOnly && getters.isEmotionMode,

    canRemoveFooter: (state, getters) => !state.readOnly && getters.isEmotionMode,

    canPreviewSequence: (state, getters) =>
        state.sequenceMode ||
        (state.currentEditedItemId != SETTINGS_ITEM_ID && !getters.currentEditedItem?.options?.hidden),

    isPostMessageAllowedOrigin: (state) => (url) => state.postMessageAllowedOrigins.includes(url),

    mediaLibraryUrl: (state) => (mediaType, mediaId) =>
        !!mediaType &&
        resolveMediaApiUrl(state.urls.mediaLibrary, state.folderId, state.organizationId, mediaType, mediaId),

    mediaCreateUrl: (state) => (mediaType, mediaId) =>
        !!mediaType &&
        resolveMediaApiUrl(state.urls.mediaCreate, state.folderId, state.organizationId, mediaType, mediaId),

    mediaThumbnailUrl: (state) => (id) => !!id && state.urls.mediaThumbnail.replace(/%%MEDIA_ID%%/, id),

    mediaPreviewUrl: (state) => (id) => !!id && state.urls.mediaPreview.replace(/%%MEDIA_ID%%/, id),

    mediaCaptioningUrl: (state) => (id) => !!id && state.urls.mediaCaptioning.replace(/%%MEDIA_ID%%/, id),

    mediaCaptioningStatusUrl: (state) => (id) => !!id && state.urls.mediaCaptioningStatus.replace(/%%MEDIA_ID%%/, id),

    assetImageUrl: (state) => (path) => !!path && state.urls.assetImage + '/' + path,

    assetThumbnailUrl: (state) => (path) => !!path && state.urls.assetThumbnail + '/' + path,

    assetAudioUrl: (state) => (path) => !!path && state.urls.assetAudio + '/' + path,

    assetMusicUrl: (state) => (id) => !!id && state.urls.assetMusic.replace(/%%MUSIC_ID%%/, id),

    aiSequenceEditUrl: (state) => (id) => !!id && state.urls.editAiSequence.replace(/%%SEQUENCE_ID%%/, id),

    availableStandardFormats: (state) => state.availableFormats.filter((format) => !format.hd),

    isHdFormat: (state) => (formatString) =>
        !!state.availableFormats.find((format) => format.string == formatString && format.hd),

    canCreateTemplate: (state, getters, rootState) => (type) => !state.readOnly && state.permissions.create[type],

    canTryProducingTemplate: (state, getters, rootState) => (type) =>
        !state.readOnly && state.permissions.produce[type],

    canHideElement: (state, getters, rootState) => !state.readOnly && state.permissions.hideElement,

    needsSave: (state, getters, rootState) =>
        !state.readOnly &&
        !!state.originalState() &&
        !_isEqual(filterStateBrand(rootState.branding), state.originalState()),

    isSaving: (state) => state.saveStatus == STATUS_SAVING,

    isRefreshingToken: (state) => state.saveStatus == STATUS_REFRESHING_TOKEN,

    assetUrl: (state) => (path) => !!path && state.urls.assets + '/' + path
};

const mutations = {
    setUserId(state, userId) {
        state.userId = userId;
    },

    setOrganizationId(state, organizationId) {
        state.organizationId = organizationId;
    },

    setOrganizationName(state, organizationName) {
        state.organizationName = organizationName;
    },

    setFolderId(state, folderId) {
        state.folderId = folderId;
    },

    setIsCte(state, isCte) {
        state.isCte = isCte;
    },

    setIsSupervisor(state, isSupervisor) {
        state.isSupervisor = isSupervisor;
    },

    setSupportEmail(state, supportEmail) {
        state.supportEmail = supportEmail;
    },

    setLanguage(state, language) {
        state.language = language.replace('_', '-');
        state.shortLanguage = language.replace(/_.+$/, '');
    },

    setAvailableTemplateLanguages(state, languages) {
        state.availableTemplateLanguages = languages;
    },

    setDefaultTemplateCategory(state, category) {
        state.defaultTemplateCategory = category;
    },

    setAvailableTemplateCategories(state, categories) {
        state.availableTemplateCategories = categories;
    },

    setAvailableTemplateFormats(state, formats) {
        state.availableTemplateFormats = Object.entries(formats).map(([key, format]) => {
            return {
                ...format,
                label: i18n.global.t('studio.template_formats.' + key),
                string: key,
                ratio: format.width / format.height,
                ratioString: key
            };
        });
    },

    setAvailableBrands(state, brands) {
        state.availableBrands = brands;
    },

    setDefaultSequenceCategory(state, category) {
        state.defaultSequenceCategory = category;
    },

    setAvailableSequenceCategories(state, categories) {
        state.availableSequenceCategories = categories;
    },

    setPostMessageAllowedOrigins(state, origins) {
        state.postMessageAllowedOrigins = Array.isArray(origins) ? origins : [origins];
    },

    setReadOnly(state, readOnly) {
        state.readOnly = readOnly;
    },

    setStudioDataVersion(state, dataVersion) {
        state.studioDataVersion = dataVersion;
    },

    setDataVersion(state, dataVersion) {
        state.dataVersion = dataVersion;
    },

    setMediaVersion(state, mediaVersion) {
        state.mediaVersion = mediaVersion;
    },

    setPermissions(state, permissions) {
        state.permissions = _merge({}, state.permissions, permissions);
    },

    setRestrictions(state, restrictions) {
        state.restrictions = _merge({}, state.restrictions, restrictions);
    },

    setPermission(state, data) {
        state.permissions[data.name] = data.value;
    },

    enableAutoSave(state, enable) {
        state.autoSave = enable;
    },

    setSaveStatus(state, status) {
        if (isSaveStatus(status)) state.saveStatus = status;
        state.saveError = null;
    },

    setSaveTimestamp(state, timestamp) {
        state.saveTimestamp = timestamp;
    },

    setSaveError(state, error) {
        state.saveStatus = STATUS_SAVE_ERROR;
        state.saveError = error;
    },

    setAuthError(state, error) {
        state.saveStatus = STATUS_AUTH_ERROR;
        state.saveError = error;
    },

    enableSequenceMode(state, enable) {
        state.sequenceMode = enable;
    },

    setEditionMode(state, mode) {
        if (isEditionMode(mode)) state.editionMode = mode;
    },

    // setCurrentEditedItem(state, item) {
    //     state.currentEditedItemId =
    //         typeof item == 'string' ? item : item.hasOwnProperty('order') ? item.id : SETTINGS_ITEM_ID;
    // },

    setCurrentEditedItem(state, itemId) {
        if (state.currentEditedItemId !== itemId) {
            state.currentEditedItemId = itemId;
        }
    },

    setCurrentLibrary(state, { id, category, tagOrder, selectedItemId, selector }) {
        state.currentLibrary = {
            id,
            category: category || null,
            tagOrder: tagOrder || null,
            selectedItemId,
            selector
        };
    },

    setCurrentLibrarySelectedItem(state, selectedItemId) {
        state.currentLibrary.selectedItemId = selectedItemId;
    },

    setCurrentMediaLibrary(state, { mediaType, selectedMediaId, selector }) {
        state.currentMediaLibrary = {
            mediaType,
            selectedMediaId,
            selector
        };
    },

    setCurrentMediaLibrarySelectedMedia(state, selectedMediaId) {
        state.currentMediaLibrary.selectedMediaId = selectedMediaId;
    },

    setCurrentCaptionButton(state, button) {
        state.currentCaptionButton = button;
    },

    setCurrentCaptionTranslating(state, translating) {
        state.currentCaptionTranslating = translating;
    },

    setAvailableFormats(state, formats) {
        state.availableFormats = formats.map((format) => ({
            ...format,
            hd: !!format.hd,
            label: i18n.global.t('studio.formats.' + format.width + 'x' + format.height),
            string: format.width + 'x' + format.height,
            ratio: format.width / format.height,
            ratioString:
                Format.RATIOS_STRING[Format.RATIOS.findIndex((ratio) => ratio == format.width / format.height)] ??
                Format.RATIO_STRING_DEFAULT
        }));
    },

    showTTSEditor(state) {
        state.showTTSEditor = true;
    },

    hideTTSEditor(state) {
        state.showTTSEditor = false;
    },

    setTTSEditorConfig(state, data) {
        state.ttsEditorConfig.seqId = data.seqId;
        state.ttsEditorConfig.useInSequence = data.useInSequence;
    },

    setCaptionsConfig(state, { languages }) {
        if (languages) state.captionsConfig.languages = languages;
    },

    setCaptionsStatus(state, status) {
        clearTimeout(_feedbackTimeoutId);
        if (isCaptionsStatus(status) || status === null) state.captionsStatus = status;
        state.captionsError = null;
    },

    setTtsStatus(state, status) {
        state.ttsStatus = status;
    },

    setCaptionsError(state, error) {
        clearTimeout(_feedbackTimeoutId);
        state.captionsStatus = CAPTIONS_STATUS_ERROR;
        state.captionsError = error;
    },

    setTranslationConfig(state, { languages }) {
        if (languages) state.translationConfig.languages = languages;
    },

    setSequenceTemplateStatus(state, status) {
        clearTimeout(_feedbackTimeoutId);
        if (isSequenceTemplateStatus(status) || status === null) state.sequenceTemplateStatus = status;
        state.sequenceTemplateError = null;
    },

    setSequenceTemplateError(state, error) {
        clearTimeout(_feedbackTimeoutId);
        state.sequenceTemplateStatus = SEQUENCE_TEMPLATE_STATUS_ERROR;
        state.sequenceTemplateError = error;
    },

    setUrls(state, urls) {
        state.urls = _merge({}, state.urls, urls);
    },

    setUrl(state, data) {
        state.urls[data.name] = data.url;
    },

    setPrefixes(state, prefixes) {
        state.prefixes = _merge({}, state.prefixes, prefixes);
    },

    setPrefix(state, data) {
        state.prefixes[data.name] = data.prefix;
    },

    setCurrentPanel(state, panel) {
        state.currentPanel = panel;
    },

    setOriginalState(state, originalState) {
        state.originalState = () => originalState;
    },

    setDefaultBrandId(state, brandId) {
        state.defaultBrandId = brandId;
    },

    setAvailableFolders(state, folders) {
        state.availableFolders = folders;
    },

    setVideoQuantity(state, quantity) {
        state.videoQuantity = quantity;
    }
};

const requestSaveVideo = async (context) => {
    let { commit, dispatch, state, rootState } = context;
    let timestamp = Date.now();

    commit('setSaveStatus', STATUS_SAVING);
    commit('setSaveTimestamp', timestamp);

    // TODO NOTE: including edition property in the filterState function throws a Vue warning and makes the production compilation crash
    const { display, settings, sequences } = rootState;

    let excludeKeys = !state.permissions.editAiSettings ? ['settings.ai'] : [];
    let data = formatStateForSaveVideo(
        filterStateVideo({ display, settings, sequences }, ['display', 'settings', 'sequences'], excludeKeys),
        state.dataVersion
    );

    const videoSavePromise = requestWithServiceTokenRefresh(
        async () => {
            await axiosInstance.post(state.urls.saveVideo, data);
            if (timestamp == state.saveTimestamp) commit('setSaveStatus', STATUS_SAVED);
        },
        (refreshError) => {
            console.log('[Error] Saving video: ' + refreshError.message);
            if (!!refreshError.response?.data?.token) {
                commit('setSaveStatus', STATUS_UNSAVED);
                maybeRefreshLogin(
                    context,
                    refreshError.response.data.token,
                    state.autoSave ? () => requestSaveVideo(context) : null
                );
            } else if (!!refreshError.response?.data?.redirect) {
                commit('setAuthError', refreshError.response.data);
                window.location.assign(refreshError.response.data.redirect);
            } else {
                commit('setSaveError', refreshError.response?.data || { error: 'save', details: refreshError.message });
            }
        },
        context
    );

    const requestPromises = [videoSavePromise];

    const templateThumbnail = rootState.ui.files.templateThumbnail;
    if (templateThumbnail instanceof File || templateThumbnail === null) {
        requestPromises.push(
            setTemplateThumbnail(axiosInstance, state.urls.setThumbnail, templateThumbnail).then((response) =>
                commit('files/setFileToType', { type: 'templateThumbnail', data: response.data.url })
            )
        );
    }

    // send all requests simultaneously
    await Promise.all(requestPromises);
};

const actions = {
    init({ state, commit }, data) {
        commit('setUserId', data.userId);
        commit('setOrganizationId', data.organizationId);
        commit('setOrganizationName', data.organizationName);
        commit('setFolderId', data.folderId);
        commit('setIsCte', data.isCte);
        commit('setIsSupervisor', data.isSupervisor);
        commit('setSupportEmail', data.supportEmail);
        commit('setLanguage', data.language);
        if (data.hasOwnProperty('availableTemplateLanguages')) {
            commit('setAvailableTemplateLanguages', data.availableTemplateLanguages);
        }
        if (data.hasOwnProperty('defaultTemplateCategory')) {
            commit('setDefaultTemplateCategory', data.defaultTemplateCategory);
        }
        if (data.hasOwnProperty('availableTemplateCategories')) {
            commit('setAvailableTemplateCategories', data.availableTemplateCategories);
        }
        if (data.hasOwnProperty('availableTemplateFormats')) {
            commit('setAvailableTemplateFormats', data.availableTemplateFormats);
        }
        if (data.hasOwnProperty('availableBrands')) {
            commit('setAvailableBrands', data.availableBrands);
        }
        if (data.hasOwnProperty('defaultSequenceCategory')) {
            commit('setDefaultSequenceCategory', data.defaultSequenceCategory);
        }
        if (data.hasOwnProperty('availableSequenceCategories')) {
            commit('setAvailableSequenceCategories', data.availableSequenceCategories);
        }
        commit('setPostMessageAllowedOrigins', data.postMessageAllowedOrigins);
        commit('setReadOnly', data.readOnly);
        commit('setStudioDataVersion', data.studioDataVersion);
        commit('setDataVersion', data.dataVersion);
        commit('setMediaVersion', data.mediaVersion);
        commit('setPermissions', data.permissions);
        commit('setRestrictions', data.restrictions);
        commit('enableAutoSave', data.autoSave);
        commit('enableSequenceMode', !!data.sequenceMode);
        commit('setEditionMode', data.editionMode);
        commit('setAvailableFormats', data.availableFormats);
        if (data.captions) commit('setCaptionsConfig', data.captions);
        if (data.translation) commit('setTranslationConfig', data.translation);
        commit('setUrls', data.urls);
        commit('setPrefixes', data.prefixes);

        commit('setDefaultBrandId', data.defaultBrandId);
        commit('setAvailableFolders', data.availableFolders);
        commit('setVideoQuantity', data.videoQuantity);
    },

    resetState({ dispatch, commit, rootState }) {
        dispatch('updateCurrentEditedItem', SETTINGS_ITEM_ID);
        commit('files/setFileToType', {
            type: 'templateThumbnail',
            data: rootState.ui.history.originalState().ui.files.templateThumbnail
        });
    },

    saveVideo({ state, commit, dispatch }, manualSave) {
        if (!state.readOnly && !!state.urls.saveVideo) {
            if (!manualSave) {
                commit('setSaveStatus', STATUS_UNSAVED);
            }

            if (state.autoSave) {
                dispatch('debouncedSaveVideo');
            } else if (manualSave) {
                dispatch('throttledSaveVideo');
            }
        }
    },

    debouncedSaveVideo: _debounce(requestSaveVideo, 2000),

    throttledSaveVideo: _throttle(
        requestSaveVideo,
        2000,
        { trailing: false } // Note: Call immediately, but deny any subsequent call during 2s
    ),

    updateEditionMode({ state, getters, commit, dispatch }, mode) {
        if (getters.canChangeEditionMode && !!state.urls.savePreferences) {
            let previousMode = state.editionMode;

            commit('setEditionMode', mode);

            if (mode != previousMode) {
                dispatch('debouncedSavePreferences', { edition_mode: mode });
            }
        }
    },

    debouncedSavePreferences: _debounce(async ({ state }, data) => {
        try {
            await axiosInstance.post(state.urls.savePreferences, data);
        } catch (error) {
            console.log('[Error] Updating edition mode: ' + error.message);
            // TODO: return error notification?
        }
    }, 500),

    updateCurrentEditedItem({ commit }, item) {
        commit('setCurrentEditedItem', item);
    },

    setTtsSuccessStatus({ commit }, status) {
        commit('setTtsStatus', status === true ? TTS_STATUS_COMPLETED : status);
        setTimeout(() => {
            commit('setTtsStatus', null);
        }, CAPTIONS_STATUS_DISPLAY_TIME);
    },

    updateCaptionsStatus({ commit, state, getters }, { status, error, modifiers }) {
        if (!getters.captionEditorIsShown || !modifiers.includes(state.currentCaptionButton.modifier)) {
            if (status != CAPTIONS_STATUS_ERROR) {
                commit('setCaptionsStatus', status);
            } else {
                commit('setCaptionsError', error);
            }

            _feedbackTimeoutId = setTimeout(() => {
                commit('setCaptionsStatus', null);
            }, CAPTIONS_STATUS_DISPLAY_TIME);
        }
    },

    async startCaptionsTranslating(context, { captions, language, success: successCallback, error: errorCallback }) {
        let { state, commit, dispatch } = context;

        let currentSaveStatus = state.saveStatus;

        commit('setCurrentCaptionTranslating', true);

        if (_captionsTranslationController) _captionsTranslationController.abort();
        _captionsTranslationController = new AbortController();

        await requestWithServiceTokenRefresh(
            async () => {
                let response = await startCaptionsTranslating(
                    context,
                    captions,
                    language,
                    _captionsTranslationController.signal
                );
                if (successCallback) successCallback(response.data.translation);
            },
            (refreshError) => {
                console.log('[Error] Captions translating:', refreshError, refreshError.response);
                if (refreshError.response?.status == 401 && !!refreshError.response?.data?.token) {
                    commit('setSaveStatus', currentSaveStatus);
                    maybeRefreshLogin(context, refreshError.response.data.token);
                }
                if (!!refreshError.response?.data?.redirect) {
                    commit('setAuthError', refreshError.response.data);
                    window.location.assign(refreshError.response.data.redirect);
                } else {
                    if (errorCallback)
                        errorCallback(
                            refreshError.response?.data || {
                                error: 'translation-unknown-error',
                                details: refreshError.message
                            }
                        );
                }
            },
            context
        );

        _captionsTranslationController = null;
        commit('setCurrentCaptionTranslating', false);
    },

    cancelCaptionsTranslating({ commit }) {
        if (_captionsTranslationController) _captionsTranslationController.abort();
        _captionsTranslationController = null;
        commit('setCurrentCaptionTranslating', false);
    },

    createSequenceTemplate(
        { state, getters, dispatch, rootState, rootGetters },
        { sequenceId, categoryIds, activatedForBrandIds }
    ) {
        if (getters.canCreateSequenceTemplate && !!state.urls.createSequenceFrom) {
            let sequenceData = formatSequenceStateForSave(filterStateVideo(rootState.sequences[sequenceId]));

            return dispatch('debouncedCreateSequenceTemplate', {
                brand_id: rootState.branding.id,
                format: rootGetters['display/formatString'],
                data: sequenceData,
                category_ids: categoryIds,
                activated_for_brand_ids: activatedForBrandIds
            });
        } else {
            throw new Error();
        }
    },

    debouncedCreateSequenceTemplate: _throttle(
        async (context, data) => {
            let { state, commit } = context;

            return await requestWithServiceTokenRefresh(
                async () => {
                    await axiosInstance.post(state.urls.createSequenceFrom, data);
                    commit('setSequenceTemplateStatus', SEQUENCE_TEMPLATE_STATUS_COMPLETED);
                    _feedbackTimeoutId = setTimeout(() => {
                        commit('setSequenceTemplateStatus', null);
                    }, SEQUENCE_TEMPLATE_STATUS_DISPLAY_TIME);
                },
                (refreshError) => {
                    console.log('[Error] Creating sequence template: ' + refreshError.message);
                    if (!!refreshError.response?.data?.token) {
                        maybeRefreshLogin(context, refreshError.response.data.token);
                    } else if (!!refreshError.response?.data?.redirect) {
                        commit('setAuthError', refreshError.response.data);
                        window.location.assign(refreshError.response.data.redirect);
                    } else {
                        commit(
                            'setSequenceTemplateError',
                            refreshError.response?.data || { error: 'create_error', details: refreshError.message }
                        );
                    }

                    throw refreshError;
                },
                context
            );
        },
        10000,
        { trailing: false } // Note: Call immediately, but deny any subsequent call during 10s
    ),

    async loadAiSequences(context) {
        let { rootState, state, commit } = context;

        if (state.aiSequences !== null) return;

        await requestWithServiceTokenRefresh(
            async () => {
                let response = await axiosInstance.get(rootState.ui.urls.listAiSequences);
                state.aiSequences = response.data;
            },
            (refreshError) => {
                console.log('[Error] Loading AI sequences:', refreshError, refreshError.response);
                if (!!refreshError.response?.data?.token) {
                    maybeRefreshLogin(context, refreshError.response.data.token);
                } else if (!!refreshError.response?.data?.redirect) {
                    commit('setAuthError', refreshError.response.data);
                    window.location.assign(refreshError.response.data.redirect);
                } else {
                    // TODO: Display error message?
                }
            },
            context
        );
    },

    showTTSEditor({ commit, state, getters }, data) {
        commit('showTTSEditor');
        commit('setTTSEditorConfig', data);
    },

    saveBrand({ state, getters, dispatch }) {
        if (getters.needsSave && !!state.urls.saveBrand) {
            return dispatch('throttledSaveBrand');
        } else {
            throw new Error();
        }
    },

    throttledSaveBrand: _throttle(
        async (context) => {
            let { commit, dispatch, state, rootState } = context;
            let timestamp = Date.now();

            commit('setSaveStatus', STATUS_SAVING);
            commit('setSaveTimestamp', timestamp);

            let data = formatStateForSaveBrand(filterStateBrand(rootState.branding), state.dataVersion);

            await requestWithServiceTokenRefresh(
                async () => {
                    await axiosInstance.post(state.urls.saveBrand, data);
                    if (timestamp == state.saveTimestamp) {
                        commit('setSaveStatus', STATUS_SAVED);
                        dispatch('saveOriginalState');
                    }
                },
                (refreshError) => {
                    console.log('[Error] Saving brand: ' + refreshError.message);
                    if (!!refreshError.response?.data?.token) {
                        commit('setSaveStatus', STATUS_UNSAVED);
                        maybeRefreshLogin(context, refreshError.response.data.token);
                    } else if (!!refreshError.response?.data?.redirect) {
                        commit('setAuthError', refreshError.response.data);
                        window.location.assign(refreshError.response.data.redirect);
                    } else {
                        commit(
                            'setSaveError',
                            refreshError.response?.data || { error: 'save', details: refreshError.message }
                        );
                    }
                },
                context
            );
        },
        2000,
        { trailing: false } // Note: Call immediately, but deny any subsequent call during 2s
    ),

    createTemplate({ state, getters, dispatch }, type) {
        if (getters.canCreateTemplate(type) && !!state.urls.create[type]) {
            return dispatch('throttledCreateTemplate', type);
        } else {
            throw new Error();
        }
    },

    throttledCreateTemplate: _throttle(
        async (context, type) => {
            let { commit, state } = context;

            return await requestWithServiceTokenRefresh(
                async () => {
                    let response = await axiosInstance.post(state.urls.create[type], {});
                    return response.data;
                },
                (refreshError) => {
                    console.log('[Error] Creating template: ' + refreshError.message);
                    if (!!refreshError.response?.data?.token) {
                        maybeRefreshLogin(context, refreshError.response.data.token);
                    } else if (!!refreshError.response?.data?.redirect) {
                        commit('setAuthError', refreshError.response.data);
                        window.location.assign(refreshError.response.data.redirect);
                    } else {
                        commit(
                            'setSaveError',
                            refreshError.response?.data || { error: 'create', details: refreshError.message }
                        );
                        // TODO: Display an error notification
                    }

                    throw refreshError;
                },
                context
            );
        },
        10000,
        { trailing: false } // Note: Call immediately, but deny any subsequent call during 10s
    ),

    duplicateTemplate({ rootGetters, dispatch }, { type, id }) {
        let template = rootGetters['branding/libraries/findLibraryItemById'](type, false, id);
        if (template && template.permissions.copy && !!template.actions.copy_url) {
            return dispatch('throttledDuplicateTemplate', template);
        } else {
            throw new Error();
        }
    },

    throttledDuplicateTemplate: _throttle(
        async (context, template) => {
            let { commit } = context;

            return await requestWithServiceTokenRefresh(
                async () => {
                    let response = await axiosInstance.post(template.actions.copy_url, {});
                    return response.data;
                },
                (refreshError) => {
                    console.log('[Error] Copying template: ' + refreshError.message);
                    if (!!refreshError.response?.data?.token) {
                        maybeRefreshLogin(context, refreshError.response.data.token);
                    } else if (!!refreshError.response?.data?.redirect) {
                        commit('setAuthError', refreshError.response.data);
                        window.location.assign(refreshError.response.data.redirect);
                    } else {
                        commit(
                            'setSaveError',
                            refreshError.response?.data || { error: 'copy', details: refreshError.message }
                        );
                        // TODO: Display an error notification
                    }

                    throw refreshError;
                },
                context
            );
        },
        2000,
        { trailing: false } // Note: Call immediately, but deny any subsequent call during 2s
    ),

    deleteTemplate({ rootGetters, dispatch }, { type, id }) {
        let template = rootGetters['branding/libraries/findLibraryItemById'](type, false, id);
        if (template && template.permissions.delete && !!template.actions.delete_url) {
            return dispatch('throttledDeleteTemplate', template);
        } else {
            throw new Error();
        }
    },

    throttledDeleteTemplate: _throttle(
        async (context, template) => {
            let { commit } = context;

            await requestWithServiceTokenRefresh(
                async () => {
                    await axiosInstance.delete(template.actions.delete_url, {});
                },
                (refreshError) => {
                    console.log('[Error] Deleting template: ' + refreshError.message);
                    if (!!refreshError.response?.data?.token) {
                        maybeRefreshLogin(context, refreshError.response.data.token);
                    } else if (!!refreshError.response?.data?.redirect) {
                        commit('setAuthError', refreshError.response.data);
                        window.location.assign(refreshError.response.data.redirect);
                    } else {
                        commit(
                            'setSaveError',
                            refreshError.response?.data || { error: 'delete', details: refreshError.message }
                        );
                        // TODO: Display an error notification
                    }

                    throw refreshError;
                },
                context
            );
        },
        2000,
        { trailing: false } // Note: Call immediately, but deny any subsequent call during 2s
    ),

    updateCurrentPanel({ state, commit }, panel) {
        commit('setCurrentPanel', panel);

        let currentHash = window.location.hash.substring(1) || SETTINGS_BRAND_PANEL_ID;
        if (isBrandPanel(panel) && panel != currentHash) {
            window.history.pushState(
                null,
                null,
                window.location.pathname + (panel != SETTINGS_BRAND_PANEL_ID ? '#' + panel : '')
            );
        }
    },

    saveOriginalState({ rootState, commit }) {
        commit('setOriginalState', filterStateBrand(rootState.branding));
    },

    updateOriginalState({ commit }, originalState) {
        commit('setOriginalState', filterStateBrand(originalState));
    },

    clearState({ state }) {
        Object.assign(state, defaultState());
    },

    async requestVideosCreation({ state, dispatch }, data) {
        if (!!state.urls.createVideos && !state.hasRequestedVideoCreation) {
            return dispatch('throttledRequestVideosCreation', data);
        } else {
            throw new Error();
        }
    },

    throttledRequestVideosCreation: _throttle(
        async (context, data) => {
            let { dispatch, state } = context;

            state.hasRequestedVideoCreation = true;

            return await requestWithServiceTokenRefresh(
                async () => {
                    let response = await axiosInstance.post(state.urls.createVideos, data);
                    state.hasRequestedVideoCreation = false;
                    return response.data;
                },
                (refreshError) => {
                    console.log('[Error] Requesting video creation: ' + refreshError.message);

                    state.hasRequestedVideoCreation = false;

                    if (!!refreshError.response?.data?.token) {
                        maybeRefreshLogin(context, refreshError.response.data.token);
                    } else if (!!refreshError.response?.data?.redirect) {
                        window.location.assign(refreshError.response.data.redirect);
                    } else {
                        // TODO: Display error message?
                    }
                },
                context
            );
        },
        2000,
        { trailing: false } // Note: Call immediately, but deny any subsequent call during 5s
    ),

    saveVideoCompanion({ dispatch }, videoData) {
        if (!!videoData.save_url && !!videoData.folder_id) {
            return dispatch('throttledSaveVideoCompanion', videoData);
        } else {
            throw new Error();
        }
    },

    throttledSaveVideoCompanion: _throttle(
        async (context, { save_url, folder_id }) => {
            let { commit, state } = context;

            return await requestWithServiceTokenRefresh(
                async () => {
                    let response = await axiosInstance.post(save_url, { folder_ids: [folder_id] });
                    return response.data[0]?.edit_url;
                },
                (refreshError) => {
                    console.log('[Error] Creating video: ' + refreshError.message);
                    if (!!refreshError.response?.data?.token) {
                        maybeRefreshLogin(context, refreshError.response.data.token);
                    } else if (!!refreshError.response?.data?.redirect) {
                        window.location.assign(refreshError.response.data.redirect);
                    } else {
                        // TODO: Display error message?
                    }

                    throw refreshError;
                },
                context
            );
        },
        2000,
        { trailing: false } // Note: Call immediately, but deny any subsequent call during 5s
    )
};

export default {
    namespaced: true,

    modules: {
        history,
        clipboard,
        processes,
        modals,
        quickcut,
        files,
        accordions
    },

    state,
    getters,
    mutations,
    actions
};

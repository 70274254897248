<template>
    <ui-caption-button
        :seqId="seqId"
        :id="cardElementId + '-video-captions'"
        v-model="visualVideoCaptions"
        :captioning="visualVideoCaptioning"
        :status="visualVideoCaptioningStatus"
        :modifier="elementStoreModulePath + '/video/setCaptions'"
        :label="$t('Captions')"
        :disabled="enableDropDown"
        :segments="videoTimerangeSegments"
        :playback-rate="playbackRate"
        :toggleStyle="toggleStyle"
        :iconOnly="iconOnly"
        :tooltip="visualVideoCaptioning ? $t('Captioning in progress...') : $t('Caption')"
        @[captionButtonShowEvent]="handleCaptioning"
        @[captionButtonCaptioningChangeEvent]="visualVideoCaptioning = $event"
    />
</template>

<script>
import { useStore } from 'vuex';
import { defineComponent, computed } from 'vue';
import UiCaptionButton from '../../cards/tools/caption/UiCaptionButton.vue';
import { useHistory } from '../../../../../composables/useHistory';
import { useElement } from '../../../../../composables/useElement';
import { useVideo } from '../../../../../composables/useVideo';
import {
    UI_CAPTION_BUTTON_SHOW,
    UI_CAPTION_BUTTON_CAPTIONING_CHANGE
} from '../../../../../components/ui/body/cards/tools/caption/UiCaptionButton.vue';

export default defineComponent({
    name: 'UiBackgroundCaptionSelector',
    components: {
        UiCaptionButton
    },
    props: {
        seqId: String,
        elementId: String,
        readOnly: Boolean,
        iconOnly: Boolean,
        cardElementId: String
    },
    setup(props) {
        const store = useStore();
        const { elementStoreModulePath, videoState, isRecordingCategory, videoTimerangeSegments, playbackRate } =
            useElement(props.seqId, props.elementId);

        const { saveVideo } = useVideo();
        const { saveHistoryStep } = useHistory();

        const captionButtonShowEvent = UI_CAPTION_BUTTON_SHOW;
        const captionButtonCaptioningChangeEvent = UI_CAPTION_BUTTON_CAPTIONING_CHANGE;

        const visualVideoCaptions = computed({
            get: () => {
                return videoState.value.captions || [];
            },
            set: (value) => {
                saveHistoryStep(() => {
                    store.commit(`${elementStoreModulePath.value}/video/setCaptions`, value);
                });
            }
        });

        const visualVideoCaptioning = computed({
            get: () => {
                return videoState.value.captioning;
            },
            set: (value) => {
                if (!!value) {
                    store.dispatch(`${elementStoreModulePath.value}/video/startCaptioning`);
                } else {
                    store.dispatch(`${elementStoreModulePath.value}/video/cancelCaptioning`);
                }
                store.commit(`${elementStoreModulePath.value}/video/setCaptioningStatus`, null);
                saveVideo();
            }
        });

        const visualVideoCaptioningStatus = computed(() => videoState.value.captioningStatus);

        const hasCaptions = computed(() => {
            return visualVideoCaptions.value.length > 0 && visualVideoCaptions.value[0].text.length > 0;
        });

        const toggleStyle = computed(() => {
            return hasCaptions.value ? { color: 'var(--edit-mode-color)' } : {};
        });

        const enableDropDown = computed(() => {
            return props.readOnly || !videoState.value?.src;
        });

        const openCaptionEditor = (button) => {
            store.commit('ui/setCurrentCaptionButton', button);
        };

        const startCaptioning = () => {
            visualVideoCaptioning.value = true;
        };

        const handleCaptioning = (button) => {
            if (hasCaptions.value) openCaptionEditor(button);
            else startCaptioning();
        };

        return {
            captionButtonShowEvent,
            captionButtonCaptioningChangeEvent,
            elementStoreModulePath,
            playbackRate,
            videoTimerangeSegments,
            toggleStyle,
            enableDropDown,
            visualVideoCaptions,
            visualVideoCaptioning,
            visualVideoCaptioningStatus,
            openCaptionEditor,
            handleCaptioning
        };
    }
});
</script>

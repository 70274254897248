/**
 * Edit video Visual Vuex store extension
 */

import _merge from 'lodash/merge';
import _pick from 'lodash/pick';
import _cloneDeep from 'lodash/cloneDeep';
import { Color, Visual, types, Duration, Sound, Dimension, Align, conversions } from 'cte-video-studio';
import { VISUAL_ELEMENT_ID, CARD_CLIPBOARD_TYPE } from '@/js/constants';
import { filterState } from '@/js/videos/utils';
import { getPrefixedUuid } from '@/js/utils';
import timeline from '../timeline.js';
import media from '../commons/media.js';
import mediaBranding from '@/js/store/modules/commons/media-branding.js';

export const resolveVisualCardData = (data, findLibraryItem) => {
    let resolvedData = _merge({}, data);

    if (!findLibraryItem('visuals', '', data.animation.type)) {
        resolvedData.animation.category = Visual.CATEGORY_DEFAULT;
        resolvedData.animation.type = Visual.DEFAULT;
    }

    return resolvedData;
};

const mergeTemplateData = (state, data, findLibraryItem) => {
    // First we remove potentially disabled component
    let resolvedData = resolveVisualCardData(data, findLibraryItem);

    // Then we pick only the relevant template data
    let mergedKeys = ['animation.category', 'animation.custom', 'animation.start', 'animation.end', 'size', 'position'];

    switch (resolvedData.animation.category) {
        case Visual.IMAGE_CATEGORY:
        case Visual.ICON_CATEGORY:
            mergedKeys.push('animation.type', 'color', 'opacity');
            if (resolvedData.animation.category != state.animation.category) {
                mergedKeys.push(
                    'image.useBranding',
                    'image.src',
                    'image.src__id',
                    !state.image.src__ref && 'image.src__ref'
                );
            }
            break;
        case Visual.LOTTIE_CATEGORY:
            mergedKeys.push('color', 'opacity');
            if (resolvedData.animation.category != state.animation.category || !state.animation.type) {
                mergedKeys.push('animation.type');
            }
            break;
        case Visual.ANIMATED_CATEGORY:
            mergedKeys.push('animation.type', 'color', 'opacity');
            break;
        case Visual.VIDEO_CATEGORY:
        case Visual.RECORDING_CATEGORY:
            mergedKeys.push('animation.type', 'opacity');
            if (resolvedData.animation.category != state.animation.category || !state.video.src) {
                mergedKeys.push(
                    'video.src',
                    'video.src__id',
                    !state.video.src__ref && 'video.src__ref',
                    'video.captions'
                );
            }
            break;
        case Visual.COLLAGE_CATEGORY:
            mergedKeys.push('animation.type', 'animation.fit', 'opacity');
            if (resolvedData.animation.category != state.animation.category || !state.collage.images.image1)
                mergedKeys.push(
                    'collage.images.image1',
                    'collage.images.image1__id',
                    !state.collage.images.image1__ref && 'collage.images.image1__ref'
                );
            if (resolvedData.animation.category != state.animation.category || !state.collage.images.image2)
                mergedKeys.push(
                    'collage.images.image2',
                    'collage.images.image2__id',
                    !state.collage.images.image2__ref && 'collage.images.image2__ref'
                );
            if (resolvedData.animation.category != state.animation.category || !state.collage.images.image3)
                mergedKeys.push(
                    'collage.images.image3',
                    'collage.images.image3__id',
                    !state.collage.images.image3__ref && 'collage.images.image3__ref'
                );
            if (resolvedData.animation.category != state.animation.category || !state.collage.images.image4)
                mergedKeys.push(
                    'collage.images.image4',
                    'collage.images.image4__id',
                    !state.collage.images.image4__ref && 'collage.images.image4__ref'
                );
            break;
    }

    resolvedData = _pick(resolvedData, mergedKeys);

    // Finally we return the merge of the current state and the relevant template keys
    return _merge({}, state, resolvedData);
};

// Initial state
const state = () => ({
    ...timeline.state(),

    id: '',
    path: [],
    color: {
        enabled: false,
        start: Color.NONE,
        second: Color.NONE,
        third: Color.NONE,
        start__ref: Color.NONE,
        second__ref: Color.NONE,
        third__ref: Color.NONE,
        angle: Color.GRADIENT_ANGLE_DEFAULT
    },
    opacity: {
        value: Color.OPACITY_DEFAULT,
        custom: Color.OPACITY_EMPTY
    },
    size: {
        width: {
            value: Dimension.AUTO,
            custom: null
        },
        height: {
            enabled: false,
            value: Dimension.AUTO,
            custom: null
        }
    },
    position: {
        alignH: Align.CENTER,
        alignV: Align.DISTRIBUTED_DEFAULT,
        alignX: Dimension.ZERO,
        alignY: Dimension.ZERO,

        // crop position
        crop: { ...Visual.DEFAULT_CROP_POSITION }
    },
    animation: {
        category: Visual.CATEGORY_DEFAULT,
        type: Visual.DEFAULT,
        custom: '',
        fit: false,
        start: {
            value: Duration.START_DEFAULT,
            custom: Duration.NONE
        },
        end: {
            value: Duration.END_DEFAULT,
            custom: Duration.NONE
        }
    },
    // affects the inner asset of the visual
    flip: {
        horizontal: false,
        vertical: false
    },
    rotation: 0,
    blur: {
        value: null,
        borderRadius: null
    },
    collage: {
        images: {
            image1: '',
            image2: '',
            image3: '',
            image4: '',
            image1__ref: '',
            image1__id: '',
            image2__ref: '',
            image2__id: '',
            image3__ref: '',
            image3__id: '',
            image4__ref: '',
            image4__id: ''
        }
    }
});

// Getters
const getters = {
    size: (state) => {
        let w = conversions.optionValue(state.size.width, Dimension.AUTO),
            h =
                (state.size.height.enabled && conversions.optionValue(state.size.height, Dimension.AUTO)) ||
                Dimension.AUTO;
        if (w == Dimension.AUTO && h == Dimension.AUTO) {
            w = Dimension.VISUAL_DEFAULT;
            // h = Dimension.VISUAL_HEIGHT_DEFAULT;
        }
        return {
            width: w,
            height: h
        };
    },

    start: (state) => conversions.optionValue(state.animation.start, [], Duration.START_DEFAULT),

    end: (state) => conversions.optionValue(state.animation.end, [], Duration.END_DEFAULT),

    opacity: (state) => conversions.optionValue(state.opacity, [], Color.OPACITY_DEFAULT),

    isDistributedAlign: (state) => {
        return (
            types.isDistributedAlign(state.position.alignH, false, false, true) ||
            types.isDistributedAlign(state.position.alignV, false, false, true)
        );
    },

    isHalfPosition: (state) => {
        return types.isHalfPosition(state.position.alignH) || types.isHalfPosition(state.position.alignV);
    },

    collageImage: (state) => (index) => state.collage.images['image' + index] || '',

    videoSrc: (state, getters, rootState) => {
        const hasTimerangeSegments =
            Array.isArray(state.video.timerangeSegments) && state.video.timerangeSegments.length > 0;

        //Optimize video playback by using media fragments if quickcut is not open
        if (hasTimerangeSegments && !rootState.ui.quickcut.isQuickCutOpen) {
            const firstSegment = state.video.timerangeSegments[0];
            const lastSegment = state.video.timerangeSegments[state.video.timerangeSegments.length - 1];

            const start = firstSegment?.start ?? 0;
            const end = lastSegment?.end ?? state.video.totalDuration();

            return conversions.mediaFragmentAssetSrc(state.video.src, state.video.totalDuration(), {
                start,
                end
            });
        }

        return state.video.src;
    },

    collageImageRef: (state) => (index) => state.collage.images['image' + index + '__ref'] || '',

    collageImageId: (state) => (index) => state.collage.images['image' + index + '__id'] || ''
};

// Mutations
const mutations = {
    ...timeline.mutations,

    setID(state, id) {
        state.id = id;
        state.path[state.path.length - 1] = id;
    },

    setPath(state, path) {
        state.path = path;
        state.id = path[path.length - 1];
    },

    enableColor(state, enabled) {
        state.color.enabled = enabled;
    },

    setOpacity(state, opacity) {
        let val = conversions.percentNum(opacity.value, false),
            custom = conversions.percentNum(opacity.custom, false);
        if (val !== undefined) {
            state.opacity.value = val.toFixed(2);
            state.opacity.custom = Color.OPACITY_EMPTY;
        } else if (custom !== undefined) {
            state.opacity.value = Color.OPACITY_CUSTOM;
            state.opacity.custom = custom.toFixed(2);
        } else {
            state.opacity.value = Color.OPACITY_DEFAULT;
            state.opacity.custom = Color.OPACITY_EMPTY;
        }
    },

    setWidth(state, width) {
        if (types.isDimension(width.value, true)) {
            state.size.width.value = width.value;
            state.size.width.custom = null;
        } else if (types.isDimension(width.custom, true)) {
            state.size.width.value = Dimension.CUSTOM;
            state.size.width.custom = width.custom;
        } else {
            state.size.width.value = Dimension.AUTO;
            state.size.width.custom = null;
        }
    },

    enableHeight(state, enabled) {
        state.size.height.enabled = enabled;
    },

    setHeight(state, height) {
        if (types.isDimension(height.value, true)) {
            state.size.height.value = height.value;
            state.size.height.custom = null;
        } else if (types.isDimension(height.custom, true)) {
            state.size.height.value = Dimension.CUSTOM;
            state.size.height.custom = height.custom;
        } else {
            state.size.height.value = Dimension.AUTO;
            state.size.height.custom = null;
        }
    },

    setPosition(state, position) {
        state.position.alignH = types.isAlign(position.alignH) ? position.alignH : Align.CENTER;
        state.position.alignV = types.isAlign(position.alignV)
            ? position.alignV
            : types.isAlign(position.alignH)
              ? Align.CENTER
              : Align.DISTRIBUTED_DEFAULT;
        state.position.alignX =
            state.position.alignH == Align.CUSTOM && types.isDimension(position.alignX)
                ? position.alignX
                : Dimension.ZERO;
        state.position.alignY =
            state.position.alignV == Align.CUSTOM && types.isDimension(position.alignY)
                ? position.alignY
                : Dimension.ZERO;
    },

    setCropPosition(state, position) {
        state.position.crop.width = position.width;
        state.position.crop.height = position.height;
        state.position.crop.left = position.left;
        state.position.crop.top = position.top;
    },

    enableHorizontalFlip(state, value) {
        state.flip.horizontal = value;

        // reverse crop position
        //if (state.position.crop.width !== 1) state.position.crop.left = 1 - state.position.crop.width;
        //if (state.position.crop.height !== 1) state.position.crop.top = 1 - state.position.crop.height;

        //console.log(state.position.crop);
    },

    enableVerticalFlip(state, value) {
        state.flip.vertical = value;
    },

    setRotationDegrees(state, rotationDegrees) {
        state.rotation = rotationDegrees || 0;
    },

    setAnimationCategory(state, category) {
        state.animation.category = types.isVisualCategory(category) ? category : Visual.CATEGORY_DEFAULT;
    },

    setAnimation(state, type) {
        state.animation.type = types.isVisual(type) ? type : Visual.DEFAULT;
        if (type != Visual.CUSTOM) state.animation.custom = '';
    },

    setFit(state, fit) {
        state.animation.fit = fit;
    },

    setAnimationCustom(state, src) {
        state.animation.custom = src;
        if (src) state.animation.type = Visual.CUSTOM;
    },

    setStart(state, start) {
        if (types.isZeroPositive(start.value)) {
            state.animation.start.value = parseFloat(start.value);
            state.animation.start.custom = Duration.NONE;
        } else if (types.isZeroPositive(start.custom)) {
            state.animation.start.value = Duration.CUSTOM;
            state.animation.start.custom = parseFloat(start.custom);
        } else {
            state.animation.start.value = Duration.START_DEFAULT;
            state.animation.start.custom = Duration.NONE;
        }
    },

    setEnd(state, end) {
        if (types.isZeroPositive(end.value)) {
            state.animation.end.value = parseFloat(end.value);
            state.animation.end.custom = Duration.NONE;
        } else if (types.isZeroPositive(end.custom)) {
            state.animation.end.value = Duration.CUSTOM;
            state.animation.end.custom = parseFloat(end.custom);
        } else {
            state.animation.end.value = Duration.END_DEFAULT;
            state.animation.end.custom = Duration.NONE;
        }
    },

    setBlur(state, blur) {
        state.blur.value = types.isZeroPositive(blur.value) ? blur.value : Visual.BLUR_DEFAULT;
        state.blur.borderRadius = types.isZeroPositive(blur.borderRadius) ? blur.borderRadius : 0;
    },

    setCollage(state, collage) {
        let p,
            images = collage.images ?? [];
        for (p in images) {
            if (images[p]) state.collage.images[p] = images[p];
        }
    },

    setColor(state, color) {
        state.color.start = types.isColor(color.start) ? color.start : Color.NONE;
        state.color.start__ref = color.start__ref || state.color.start;
        state.color.second = types.isColor(color.second) ? color.second : Color.NONE;
        state.color.second__ref = color.second__ref || state.color.second;
        state.color.third = types.isColor(color.third) ? color.third : Color.NONE;
        state.color.third__ref = color.third__ref || state.color.third;
        state.color.angle = types.isAngle(color.angle) ? color.angle : Color.GRADIENT_ANGLE_DEFAULT;
    },

    setCollageImages(state, images) {
        let p;
        for (p in images) {
            if (!/(?:__ref|__id)$/.test(p) && images[p] != state.collage.images[p]) state.collage.images[p] = images[p];
        }
    },

    setCollageImageReferences(state, images) {
        let p;
        for (p in images) {
            if (/__ref$/.test(p)) state.collage.images[p] = images[p];
        }
    },

    setCollageImageIds(state, images) {
        let p;
        for (p in images) {
            if (/__id$/.test(p)) state.collage.images[p] = images[p];
        }
    }
};

// Actions
const actions = {
    ...timeline.actions,

    init({ commit, dispatch }, data) {
        commit('enableColor', data.color.enabled);
        commit('setColor', data.color);
        commit('setOpacity', data.opacity);
        commit('setWidth', data.size.width);
        commit('enableHeight', data.size.height.enabled);
        commit('setHeight', data.size.height);
        commit('setPosition', data.position);
        commit('setCropPosition', data.position.crop || Visual.DEFAULT_CROP_POSITION);
        commit('enableHorizontalFlip', !!data.flip?.horizontal);
        commit('enableVerticalFlip', !!data.flip?.vertical);
        commit('setRotationDegrees', data.rotation);
        commit('setFit', data.animation.fit);
        commit('setAnimationCategory', data.animation.category);
        commit('setAnimation', data.animation.type);
        commit('setAnimationCustom', data.animation.custom);
        commit('setStart', data.animation.start);
        commit('setEnd', data.animation.end);
        commit('setBlur', data.blur);
        commit('setCollage', data.collage);

        if (data.image) dispatch('image/init', data.image);
        if (data.video) dispatch('video/init', data.video);
    },

    removeSelf({ commit, state }) {
        let seqPath = state.path.slice(0, -1).join('/');
        commit(
            seqPath + '/remove',
            {
                module: true,
                path: state.path
            },
            { root: true }
        );
    },

    moveSelf({ commit, state }, index) {
        // data: { id, index }
        let seqPath = state.path.slice(0, -1).join('/');
        commit(seqPath + '/move', { id: state.id, index }, { root: true });
    },

    resetImageAndVideo({ dispatch }) {
        dispatch('image/update', { src: '', src__id: '' });
        dispatch('video/update', { src: '', src__id: '' });
    },

    resetAsset({ commit }) {
        commit('setCropPosition', Visual.DEFAULT_CROP_POSITION);
        commit('enableHorizontalFlip', false);
        commit('enableVerticalFlip', false);
        commit('setRotationDegrees', 0);
    },

    applyTemplateVisual({ state, rootGetters, dispatch }, data) {
        dispatch('init', mergeTemplateData(state, data, rootGetters['branding/libraries/findLibraryItem']));
    },

    copyToClipboard({ dispatch, state }) {
        dispatch(
            'ui/clipboard/copy',
            {
                type: CARD_CLIPBOARD_TYPE + VISUAL_ELEMENT_ID,
                data: filterState(state, [], ['id', 'path', 'video.captioning'])
            },
            { root: true }
        );
    },

    pasteCard({ dispatch, rootState, rootGetters }) {
        if (rootGetters['ui/clipboard/hasPastableDataOfType'](CARD_CLIPBOARD_TYPE + VISUAL_ELEMENT_ID)) {
            const data =
                rootState.ui.clipboard.brandId == rootState.branding.id
                    ? rootState.ui.clipboard.data
                    : resolveVisualCardData(
                          rootState.ui.clipboard.data,
                          rootGetters['branding/libraries/findLibraryItem']
                      );

            // generate new media reference when pasting
            if (data.image?.src__ref) data.image.src__ref = getPrefixedUuid(rootState.ui.prefixes.mediaReference);
            if (data.video?.src__ref) data.video.src__ref = getPrefixedUuid(rootState.ui.prefixes.mediaReference);

            for (const imageIndex of [1, 2, 3, 4]) {
                const keyName = `image${imageIndex}__ref`;
                if (data.collage?.images[keyName])
                    data.collage.images[keyName] = getPrefixedUuid(rootState.ui.prefixes.mediaReference);
            }

            dispatch('init', data);
        }
    },

    updateCollageImages({ commit }, images) {
        commit('setCollageImageIds', images);
        commit('setCollageImages', images);
    }
};

export default {
    namespaced: true,

    state,
    modules: {
        image: mediaBranding,
        video: media
    },
    getters,
    mutations,
    actions
};

<template>
    <div v-if="media" ref="quickCutContainer" id="quick-cut-container" :style="sliderWrapperStyle">
        <template v-if="media.src">
            <ControlButtons
                :can-split="canSplit"
                :can-zoom-in="canZoomIn"
                :can-zoom-out="canZoomOut"
                @split="splitVideoHandler"
                @zoomIn="zoomIn"
                @zoomOut="zoomOut"
                @close="close"
            />
            <div class="timeline-container">
                <PlayButton
                    :current-time="sequenceElapsedTime"
                    :video-duration="mediaDuration"
                    :startTime="startTime"
                />
                <TimelineSlider
                    :canvas-width="canvasWidth"
                    :segments="segments"
                    :current-time-pos="currentTimePos"
                    :get-initial-gap-style="getInitialGapStyle"
                    :get-gap-style="getGapStyle"
                    :get-final-gap-style="getFinalGapStyle"
                    :is-dragging="isDragging"
                    :segment-is-dragging="dragSegmentIsDragging"
                    :current-time-is-dragging="currentTimeDrag"
                    :endTime="sequenceProps.endTime"
                    :rate="sequenceProps.rate"
                    :playback-rate="playbackRate"
                    @start-segment-drag="startSegmentDrag"
                    @start-slider-drag="handleStartSliderDrag"
                    @start-current-time-drag="startCurrentTimeDrag"
                    @elements-mount="handleElementsMount"
                >
                    <template #segments>
                        <SegmentHandler
                            v-for="(segment, index) in segments"
                            :key="index"
                            :segment="segment"
                            :index="index"
                            :get-handle-pos="getHandlePos"
                            :is-dragging="isDragging"
                            :drag-segment-index="dragSegmentIndex"
                            :selected-segment-index="selectedSegmentIndex"
                            :dragHandle="dragHandle"
                            @start-drag="handleStartDrag"
                            @select-segment="selectSegment"
                        />
                    </template>
                    <template #canvas>
                        <canvas ref="canvas" width="100%" height="58"></canvas>
                    </template>
                    <template #audio-waveform>
                        <AudioLoading v-if="isLoading" :is-loading="isLoading" :width="canvasWidth" :height="58" />
                        <AudioWaveform
                            v-else
                            :audio-data="audioData"
                            :canvas-width="canvasWidth"
                            :canvas-height="58"
                            :zoom-level="zoomLevel"
                            :waveform-type="waveformType"
                        />
                    </template>
                </TimelineSlider>
                <TimeDisplay :elapsed-time="sequenceElapsedTime" />
            </div>
            <SegmentEditor
                :segments="segments"
                :rate="sequenceProps.rate"
                :selected-segment-index="selectedSegmentIndex"
                :media-duration="mediaDuration"
                :media-max-duration="sequenceProps.endTime"
                @update-segment="handleUpdateSegment"
                @remove-segment="removeSegment"
            />
        </template>
    </div>
</template>

<script>
import { throttle } from 'lodash';
import { computed, defineComponent, nextTick, onMounted, reactive, ref, toRef, toRefs, watch, watchEffect } from 'vue';
import { useDisplay } from '@video-composables/useDisplay';
import { useVideoSegments } from './composables/useVideoSegments';
import { useMediaPlayer } from './composables/useMediaPlayer';
import { useTimelineControls } from './composables/useTimelineControls';
import { useSegmentStyles } from './composables/useSegmentStyles';
import { useDragInteractions } from './composables/useDragInteractions';
import { useZoom } from './composables/useZoom';
import { useEventHandlers } from './composables/useEventHandlers';
import { useCanvasInteraction } from './composables/useCanvasInteraction';
import { useQuickCutLayout } from './composables/useQuickCutLayout';
import { useAudioWaveform } from './composables/useAudioWaveform';

import ControlButtons from './subcomponents/ControlButtons.vue';
import TimelineSlider from './subcomponents/TimelineSlider.vue';
import SegmentHandler from './subcomponents/SegmentHandler.vue';
import PlayButton from './subcomponents/PlayButton.vue';
import TimeDisplay from './subcomponents/TimeDisplay.vue';
import SegmentEditor from './subcomponents/SegmentEditor.vue';
import AudioWaveform from './subcomponents/AudioWaveform.vue';
import AudioLoading from './subcomponents/AudioLoading.vue';

//delete find FRAME_WIDTH in extractFrames
const FRAME_WIDTH = 103;

const FRAME_RATE = 1;
const EPSILON = 0.01;

export default defineComponent({
    name: 'UiQuickCut',
    components: {
        ControlButtons,
        TimelineSlider,
        SegmentHandler,
        PlayButton,
        TimeDisplay,
        SegmentEditor,
        AudioWaveform,
        AudioLoading
    },
    props: {
        segments: {
            type: Array,
            default: () => []
        },
        media: {
            type: Object,
            default: () => ({ src: '' })
        },
        mediaType: {
            type: String,
            validator: (value) =>
                ['visual', 'audio', 'tts', 'backgroundVideo', 'recording', 'recordingVisual'].includes(value)
        },
        playbackRate: {
            type: Number,
            default: 1
        },
        startTime: {
            type: Number,
            default: 0.001
        }
    },
    emits: ['update-segments', 'close'],
    setup(props, { emit }) {
        const canvas = ref(null);
        const sliderContainer = ref(null);
        const sliderWrapper = ref(null);
        const canvasWidth = ref(0);
        const canvasHeight = ref(58);
        const segments = ref(props.segments);
        const selectedSegmentIndex = ref(0);
        const isUpdating = ref(false);

        const canSplit = computed(() => {
            const currentTime = (currentTimePos.value / canvasWidth.value) * sequenceProps.endTime;

            return segments.value.some((segment) => currentTime >= segment.start && currentTime <= segment.end);
        });

        const selectSegment = (index, event) => {
            selectedSegmentIndex.value = index;
            handleCanvasClick(event);
            updateSelectedSegment(adjustedSequenceElapsedTime.value);
        };

        watch(
            () => props.segments,
            () => {
                segments.value = props.segments;

                if (segments.value.length == 0) {
                    resetSegments();
                }
                recalculateGapStyles();
            }
        );

        const handleUpdateSegment = (index, updatedSegment) => {
            if (
                segments.value[index].start === updatedSegment.start &&
                segments.value[index].end === updatedSegment.end
            ) {
                return;
            }

            segments.value[index] = updatedSegment;

            mergeSegments();
            mergeAdjacentSegments(index);
            updateSegmentsInStore(segments.value);
        };

        //refactor dans useVideoSegments
        const updateSelectedSegment = (currentTime) => {
            if (!canSplit.value) {
                selectedSegmentIndex.value = -1;
                return;
            }

            let newSelectedIndex = -1;
            let accumulatedTime = 0;

            for (let i = 0; i < segments.value.length; i++) {
                const segment = segments.value[i];
                const segmentDuration = (segment.end - segment.start) / sequenceProps.playbackRate;

                if (currentTime >= accumulatedTime && currentTime < accumulatedTime + segmentDuration) {
                    newSelectedIndex = i;
                    break;
                }

                accumulatedTime += segmentDuration;
            }

            if (newSelectedIndex !== selectedSegmentIndex.value) {
                selectedSegmentIndex.value = newSelectedIndex;
            }
        };

        const throttledUpdateSelectedSegment = throttle(updateSelectedSegment, 100);

        const sequenceProps = reactive({
            media: { src: '' },
            mediaType: props.mediaType,
            startTime: props.startTime,
            endTime: 0,
            playbackRate: 1,
            rate: 25
        });

        const updateSegmentsInStore = (newSegments) => {
            newSegments.forEach((segment) => {
                delete segment.gapStyle;
            });

            emit('update-segments', newSegments);
        };

        const close = () => {
            emit('close');
        };

        const { elapsedTime, sequenceElapsedTime, sequenceTotalTime, playing } = useDisplay();

        const adjustedSequenceElapsedTime = computed(() => {
            const adjusted = sequenceElapsedTime.value - sequenceProps.startTime;
            return Math.max(0, Math.min(adjusted, sequenceProps.endTime + sequenceProps.startTime));
        });

        const adjustedElapsedTime = computed(() => {
            const adjusted = elapsedTime.value - sequenceProps.startTime;
            return Math.max(0, Math.min(adjusted, sequenceProps.endTime + sequenceProps.startTime));
        });

        onMounted(() => {
            initializeTimeline();
        });

        const {
            isDragging,
            updateSegmentEnd,
            splitVideo,
            removeSegment,
            mergeSegments,
            mergeAdjacentSegments,
            resetSegments,
            updateSegmentPosition,
            startDrag,
            stopDrag
        } = useVideoSegments(segments, sequenceProps);

        const { audioData, loadAudioData, isLoading } = useAudioWaveform(canvasWidth);

        const {
            handleStartDrag,
            onDrag,
            handleStopDrag,
            startSliderDrag,
            onSliderDrag,
            stopSliderDrag,
            dragSegmentIndex,
            dragHandle,
            startSegmentDrag,
            dragSegmentIsDragging
        } = useDragInteractions({
            segments,
            isDragging,
            updateSegmentPosition,
            mergeAdjacentSegments,
            mergeSegments,
            updateCurrentTimeAndPos,
            startDrag,
            stopDrag,
            sliderWrapper,
            sliderContainer,
            sequenceProps,
            updateSegmentsInStore
        });

        const { extractFrames, videoEndTime, performSeekOperation, initializeTimeline, simpleSeek } = useMediaPlayer(
            toRef(sequenceProps, 'media'),
            toRef(sequenceProps, 'mediaType'),
            handlemediaDurationChange,
            loadAudioData,
            sequenceProps,
            dragHandle,
            sequenceTotalTime
        );

        const { zoomLevel, adjustZoomLevel, handleZoom, canZoomOut, canZoomIn } = useZoom(
            FRAME_WIDTH,
            videoEndTime,
            sliderContainer,
            canvasWidth,
            segments
        );

        const { sliderWrapperStyle, quickCutContainer, handleResize } = useQuickCutLayout(
            canvasWidth,
            adjustZoomLevel,
            extractFramesHandler
        );

        const {
            currentTimePos,
            updateCurrentTimeAndPosition,
            startCurrentTimeDrag,
            onCurrentTimeDrag,
            stopCurrentTimeDrag,
            getCurrentRealTime,
            currentTimeDrag
        } = useTimelineControls(
            { FRAME_RATE, FRAME_WIDTH },
            {
                segments,
                videoEndTime: sequenceTotalTime,
                updateCurrentTimeAndPos,
                zoomLevel,
                isUpdating,
                sequenceProps
            }
        );

        const { getHandlePos, getGapStyle, getInitialGapStyle, getFinalGapStyle, recalculateGapStyles } =
            useSegmentStyles({ sequenceProps, segments, canvasWidth });

        const { addEventListeners } = useEventHandlers({
            sliderWrapper,
            sliderContainer,
            handleCurrentTimeDrag,
            stopCurrentTimeDrag,
            onDrag,
            handleStopDrag,
            onSliderDrag,
            stopSliderDrag,
            handleResize,
            close
        });

        const { onCanvasClick } = useCanvasInteraction({
            sequenceProps,
            segments,
            currentTimePos,
            canvasWidth
        });

        const mediaDuration = computed(() => {
            return (
                segments.value.reduce((acc, segment) => acc + (segment.end - segment.start), 0) /
                sequenceProps.playbackRate
            );
        });

        const waveformType = computed(() => {
            if (props.mediaType === 'audio' || props.mediaType === 'tts') {
                return 'wave-middle';
            }
            return 'wave';
        });

        function handlemediaDurationChange(duration) {
            sequenceProps.endTime = duration;
            updateSegmentEnd(duration);
            nextTick(() => {
                adjustZoomLevel();
                extractFramesHandler();
                quickCutContainer.value = document.getElementById('quick-cut-container');
                sliderWrapperStyle.value = `max-width: ${quickCutContainer.value.offsetWidth}px;`;

                // Ajouter un listener pour les clics en dehors de quickCutContainer
                document.addEventListener('mousedown', (event) => {
                    if (quickCutContainer.value && !quickCutContainer.value.contains(event.target)) {
                        close();
                    }
                });
            });
        }

        async function extractFramesHandler() {
            if (canvas.value) {
                await extractFrames(canvas.value, canvasWidth.value, canvasHeight.value);
            }
        }

        function updateCurrentTimeAndPos(newTime) {
            const { elT, segmentStart, found } = calculateElapsedTime(newTime);

            if (!found && segments.value.length > 0) {
                return;
            }

            performSeekOperation(elT);
            updateCurrentPosition(newTime, segmentStart);
        }

        function calculateElapsedTime(newTime) {
            let elT = sequenceProps.startTime;
            let segmentStart = 0;
            let found = false;
            let totalDuration = 0;

            for (const segment of segments.value) {
                const segmentDuration = segment.end - segment.start;
                totalDuration += segmentDuration;

                if (isTimeWithinSegment(newTime, segment)) {
                    elT += newTime - segment.start;
                    found = true;
                    break;
                } else if (newTime > segment.end) {
                    elT += segmentDuration;
                    segmentStart = segment.end;
                } else {
                    break;
                }

                // Vérifier si elT dépasse la durée totale de la vidéo
                if (elT > totalDuration + sequenceProps.startTime) {
                    elT = totalDuration + sequenceProps.startTime;
                    found = true;
                    break;
                }
            }

            // Si aucun segment correspondant n'a été trouvé, réinitialiser elT
            if (!found) {
                elT = sequenceProps.startTime;
                segmentStart = 0;
            }

            if (elT > mediaDuration.value - EPSILON) {
                elT = mediaDuration.value - EPSILON;
            }

            return { elT, segmentStart, found };
        }

        function isTimeWithinSegment(time, segment) {
            return time >= segment.start - EPSILON && time <= segment.end + EPSILON;
        }

        function updateCurrentPosition(newTime, segmentStart) {
            const containerWidth = sliderContainer.value.getBoundingClientRect().width;
            const segmentPosition = calculatePosition(segmentStart, containerWidth);
            const positionInSegment = calculatePosition(newTime - segmentStart, containerWidth);
            const newPosition = Math.min(segmentPosition + positionInSegment, containerWidth);

            currentTimePos.value = newPosition;
        }

        function calculatePosition(time, containerWidth) {
            return (time / sequenceProps.endTime) * containerWidth;
        }

        function splitVideoHandler() {
            const realTime = getCurrentRealTime();
            splitVideo(realTime);
            updateSegmentsInStore(segments.value);
        }

        function handleCurrentTimeDrag(event) {
            onCurrentTimeDrag(event, sliderContainer.value);
        }

        function handleStartSliderDrag(event, wrapperEl) {
            startSliderDrag(event, wrapperEl);
        }

        function handleElementsMount({ sliderWrapper: wrapperEl, sliderContainer: containerEl }) {
            sliderWrapper.value = wrapperEl;
            sliderContainer.value = containerEl;
            addEventListeners();
        }

        function handleCanvasClick(event) {
            if (isDragging.value || dragSegmentIsDragging.value) {
                return;
            }

            const elapsedTime = onCanvasClick(event, canvas.value);

            simpleSeek(elapsedTime);
        }

        watchEffect(() => {
            sequenceProps.media = props.media;
            sequenceProps.playbackRate = props.playbackRate;
        });

        watch(zoomLevel, () => {
            if (isUpdating.value) return;

            try {
                isUpdating.value = true;
                canvasWidth.value = sequenceProps.endTime * FRAME_WIDTH * zoomLevel.value;
                recalculateGapStyles();
                extractFramesHandler();

                // Utilisez nextTick pour s'assurer que le DOM est mis à jour
                nextTick(() => {
                    updateCurrentTimeAndPosition(adjustedSequenceElapsedTime.value);
                    throttledUpdateSelectedSegment(adjustedSequenceElapsedTime.value);
                });
            } finally {
                // Assurez-vous que le drapeau est toujours réinitialisé
                isUpdating.value = false;
            }
        });

        watch(adjustedSequenceElapsedTime, (newTime) => {
            if (isUpdating.value) return;

            try {
                isUpdating.value = true;
                if (!isDragging.value && playing.value && adjustedSequenceElapsedTime) {
                    // Utilisez nextTick pour éviter les conflits de mise à jour
                    nextTick(() => {
                        updateCurrentTimeAndPosition(newTime);
                    });
                }
                throttledUpdateSelectedSegment(newTime);
            } finally {
                isUpdating.value = false;
            }
        });

        return {
            ...toRefs(sequenceProps),
            splitVideoHandler,
            getHandlePos,
            canvas,
            isDragging,
            sliderContainer,
            sliderWrapper,
            sliderWrapperStyle,
            startSliderDrag,
            currentTimePos,
            elapsedTime: adjustedElapsedTime,
            sequenceElapsedTime: adjustedSequenceElapsedTime,
            segments,
            getGapStyle,
            getInitialGapStyle,
            getFinalGapStyle,
            getCurrentRealTime,
            canvasWidth,
            zoomIn: () => handleZoom(1.8),
            zoomOut: () => handleZoom(1 / 1.8),
            zoomLevel,
            canZoomIn,
            canZoomOut,
            startCurrentTimeDrag,
            handleStartDrag,
            handleCurrentTimeDrag,
            handleElementsMount,
            handleStartSliderDrag,
            handleCanvasClick,
            dragSegmentIndex,
            dragSegmentIsDragging,
            sequenceTotalTime,
            audioData,
            close,
            sequenceProps,
            startSegmentDrag,
            mediaDuration,
            selectedSegmentIndex,
            selectSegment,
            handleUpdateSegment,
            removeSegment,
            currentTimeDrag,
            dragHandle,
            canSplit,
            waveformType,
            isLoading
        };
    }
});
</script>

<style scoped>
canvas {
    background-color: #3a4452;
}
.timeline-container {
    display: flex;
    margin-bottom: 16px;
}

.time-container-content {
    display: flex;
    justify-content: center;
    width: 64px;
}

.time-container {
    padding-top: 36px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
</style>

const state = () => ({
    src: '',
    src__id: '',
    src__ref: ''
});

const mutations = {
    setSource(state, src) {
        state.src = src;
    },

    setSourceId(state, id) {
        state.src__id = id;
    },

    setSourceRef(state, ref) {
        state.src__ref = ref;
    }
};

const actions = {
    init({ commit }, data) {
        commit('setSource', data.src || '');
        commit('setSourceId', data.src__id || '');
        commit('setSourceRef', data.src__ref || '');
    },

    update({ commit }, data) {
        commit('setSourceId', data.src__id);
        commit('setSource', data.src);
    }
};

export default {
    namespaced: true,

    state,
    mutations,
    actions
};

/**
 * Video Studio
 * Loader with LRU Cache
 */

import { fetch } from 'whatwg-fetch';

let $store,
    files = {},
    // Configuration du cache
    cacheConfig = {
        maxSize: 500 * 1024 * 1024, // 500MB
        currentSize: 0,
        lastAccessed: new Map()
    };

const init = (store, config = {}) => {
    $store = store;
    if (config.maxCacheSize) {
        cacheConfig.maxSize = config.maxCacheSize;
    }
};

const updateLastAccessed = (path) => {
    cacheConfig.lastAccessed.set(path, Date.now());
};

const freeCacheSpace = (requiredSize) => {
    // Trier par dernier accès
    const sortedFiles = Array.from(cacheConfig.lastAccessed.entries()).sort(([, a], [, b]) => a - b);

    for (const [path] of sortedFiles) {
        if (cacheConfig.currentSize + requiredSize <= cacheConfig.maxSize) break;

        if (files[path] && files[path].blob) {
            cacheConfig.currentSize -= files[path].blob.size;
            if (files[path].objectUrl) {
                URL.revokeObjectURL(files[path].objectUrl);
            }
            files[path].blob = null;
            files[path].objectUrl = null;
            cacheConfig.lastAccessed.delete(path);
        }
    }
};

const load = async (path, error) => {
    $store.commit('loading/add', path);

    // Si déjà en cache et chargé
    if (files[path] && files[path].blob) {
        console.log('Already loaded', path);
        updateLastAccessed(path);
        if (!$store.getters['loading/isLoaded'](path)) {
            $store.commit('loading/loaded', {
                path,
                blob: files[path].objectUrl || URL.createObjectURL(files[path].blob)
            });
        }
        addErrorCallback(path, error);
        return files[path].promise;
    }

    // Si requête en cours
    if (files[path] && files[path].promise) {
        addErrorCallback(path, error);
        return files[path].promise;
    }

    const promise = fetch(path)
        .then(async (response) => {
            if (!response.ok) throw new Error(response.statusText);

            // Vérifier la taille avant de charger
            const contentLength = response.headers.get('content-length');
            if (contentLength) {
                const size = parseInt(contentLength, 10);
                if (size > cacheConfig.maxSize) {
                    console.warn(`File ${path} is larger than max cache size, will not be cached`);
                } else {
                    freeCacheSpace(size);
                }
            }

            return response.blob();
        })
        .then((blob) => {
            if (files[path]) {
                updateLastAccessed(path);
                files[path].blob = blob;
                cacheConfig.currentSize += blob.size;

                const objectUrl = URL.createObjectURL(blob);
                files[path].objectUrl = objectUrl;
                $store.commit('loading/loaded', { path, blob: objectUrl });
                return blob;
            }
        })
        .catch((err) => {
            callErrorCallbacks(path)();
            throw err;
        });

    files[path] = {
        promise,
        blob: null,
        callbacks: [],
        objectUrl: null
    };

    addErrorCallback(path, error);
    return promise;
};

const refresh = (path, error) => {
    if (files[path]) {
        cacheConfig.currentSize -= files[path].blob?.size || 0;
        if (files[path].objectUrl) {
            URL.revokeObjectURL(files[path].objectUrl);
        }
    }

    return load(path, error);
};

const addErrorCallback = (path, error) => {
    if (error && error instanceof Function && !files[path].callbacks.includes(error)) {
        files[path].callbacks.push(error);
        if ($store.getters['loading/hasError'](path)) error();
    }
};

const callErrorCallbacks = (path) => () => {
    $store.commit('loading/error', path);
    if (files[path]) {
        files[path].callbacks.forEach((callback) => callback());
    }
};

const unload = (path, error) => {
    $store.commit('loading/remove', path);

    if (files[path]) {
        if (error) {
            const ei = files[path].callbacks.indexOf(error);
            if (ei !== -1) files[path].callbacks.splice(ei, 1);
        }

        if (files[path].callbacks.length === 0) {
            cacheConfig.currentSize -= files[path].blob?.size || 0;
            if (files[path].objectUrl) {
                URL.revokeObjectURL(files[path].objectUrl);
            }
            cacheConfig.lastAccessed.delete(path);
            delete files[path];
        }
    }
};

// Nouvelle méthode pour configurer la taille du cache
const setCacheSize = (sizeMB) => {
    cacheConfig.maxSize = sizeMB * 1024 * 1024;
    freeCacheSpace(0);
};

export default {
    init,
    load,
    refresh,
    unload,
    setCacheSize
};

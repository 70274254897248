/**
 * Edit video Sequence background Vuex store extension
 */
import _merge from 'lodash/merge';
import _pick from 'lodash/pick';
import { Background, Color, types, Align, Dimension, Overlay, Duration, Timeline, conversions } from 'cte-video-studio';
import timeline from '../timeline.js';
import media from '../commons/media.js';
import gsap from 'gsap';
import simpleMedia from '@/js/store/modules/commons/simple-media.js';

export const resolveBackgroundCardData = (data, findLibraryItem) => {
    let resolvedData = _merge({}, data);
    if (!findLibraryItem('backgroundAnimations', '', data.animation.type))
        resolvedData.animation.type = Background.ANIMATION_DEFAULT;

    return resolvedData;
};

const mergeTemplateData = (state, data, findLibraryItem) => {
    // First we remove potentially disabled component
    let resolvedData = resolveBackgroundCardData(data, findLibraryItem);

    // Then we pick only the relevant template data
    let mergedKeys = ['type', 'color', 'blur', 'overlay'];

    switch (resolvedData.type) {
        case Background.IMAGE_TYPE:
        case Background.CARTOON_TYPE:
            mergedKeys.push('animation');
            if (resolvedData.type != state.type || !state.image.src) {
                mergedKeys.push('image.src', 'image.src__id', !state.image.src__ref && 'image.src__ref');
            }
            break;
        case Background.VIDEO_TYPE:
        case Background.ANIMATED_TYPE:
        case Background.RECORDING_TYPE:
            mergedKeys.push('animation');
            if (resolvedData.type != state.type || !state.video.src) {
                mergedKeys.push(
                    'video.src',
                    'video.src__id',
                    !state.video.src__ref && 'video.src__ref',
                    'video.captions',
                    'video.volume',
                    'video.fade'
                );
            }
            break;
        case Background.COLLAGE_TYPE:
            mergedKeys.push('animation.type', 'collage.animated');
            if (resolvedData.type != state.type || !state.collage.images.image1)
                mergedKeys.push(
                    'collage.images.image1',
                    'collage.images.image1__id',
                    !state.collage.images.image1__ref && 'collage.images.image1__ref'
                );
            if (resolvedData.type != state.type || !state.collage.images.image2)
                mergedKeys.push(
                    'collage.images.image2',
                    'collage.images.image2__id',
                    !state.collage.images.image2__ref && 'collage.images.image2__ref'
                );
            if (resolvedData.type != state.type || !state.collage.images.image3)
                mergedKeys.push(
                    'collage.images.image3',
                    'collage.images.image3__id',
                    !state.collage.images.image3__ref && 'collage.images.image3__ref'
                );
            if (resolvedData.type != state.type || !state.collage.images.image4)
                mergedKeys.push(
                    'collage.images.image4',
                    'collage.images.image4__id',
                    !state.collage.images.image4__ref && 'collage.images.image4__ref'
                );
            break;
        case Background.MAP_ZOOM_TYPE:
            mergedKeys.push('mapZoom.marker.enabled');
            if (resolvedData.type != state.type || (!state.mapZoom.coords && !state.mapZoom.address)) {
                mergedKeys.push('mapZoom.coords', 'mapZoom.address');
            }
            if (resolvedData.type != state.type || !state.mapZoom.marker.src) {
                mergedKeys.push(
                    'mapZoom.marker.src',
                    'mapZoom.marker.src__id',
                    !state.mapZoom.marker.src && 'mapZoom.marker.src__ref'
                );
            }
            break;
    }

    resolvedData = _pick(resolvedData, mergedKeys);

    // Finally we return the merge of the current state and the relevant template keys
    return _merge({}, state, resolvedData);
};

const defaultPosition = {
    alignH: Align.CENTER,
    alignV: Align.DISTRIBUTED_DEFAULT,
    alignX: Dimension.ZERO,
    alignY: Dimension.ZERO
};

const state = () => ({
    ...timeline.state(),

    el: null,
    type: Background.DEFAULT,
    color: {
        start: Color.BACKGROUND_DEFAULT,
        end: Color.NONE,
        start__ref: Color.BACKGROUND_DEFAULT,
        end__ref: Color.NONE,
        angle: Color.GRADIENT_ANGLE_DEFAULT
    },
    collage: {
        animated: false,
        images: {
            image1: '',
            image2: '',
            image3: '',
            image4: '',
            image1__ref: '',
            image1__id: '',
            image2__ref: '',
            image2__id: '',
            image3__ref: '',
            image3__id: '',
            image4__ref: '',
            image4__id: ''
        }
    },
    blur: false,
    animation: {
        type: Background.ANIMATION_DEFAULT,
        zoomTarget: Align.CENTER,
        zoomTargetX: Dimension.ZERO,
        zoomTargetY: Dimension.ZERO
    },
    mapZoom: {
        coords: Background.DEFAULT_MAP_ZOOM_COORDS,
        address: '',
        marker: {
            enabled: true,
            src: '',
            src__ref: '',
            src__id: ''
        }
    },
    overlay: {
        enabled: false,
        type: Overlay.DEFAULT,
        color: {
            start: Color.NONE,
            end: Color.NONE,
            start__ref: Color.NONE,
            end__ref: Color.NONE,
            angle: Color.GRADIENT_ANGLE_DEFAULT
        },
        opacity: Color.BACKGROUND_OPACITY_DEFAULT,
        animation: {
            enabled: false,
            start: Duration.START_DEFAULT,
            end: Duration.END_DEFAULT
        }
    },
    position: { ...defaultPosition },
    scale: { value: 1, ref: 1 },
    cover: true, // image or video is in cover mode

    timeline: () => gsap.timeline({ id: Timeline.BACKGROUND_TIMELINE_ID })
});

// Getters
const getters = {
    hasPreviousBackground: (state) => state.type == Background.PREVIOUS_TYPE,

    hasColorBackground: (state) => state.type == Background.COLOR_TYPE,

    hasImageBackground: (state) => state.type == Background.IMAGE_TYPE,

    hasVideoBackground: (state) => state.type == Background.VIDEO_TYPE,

    hasAnimatedBackground: (state) => state.type == Background.ANIMATED_TYPE,

    hasCartoonBackground: (state) => state.type == Background.CARTOON_TYPE,

    hasCollageBackground: (state) => state.type == Background.COLLAGE_TYPE,

    hasMapZoomBackground: (state) => state.type == Background.MAP_ZOOM_TYPE,

    hasRecordingBackground: (state) => state.type == Background.RECORDING_TYPE,

    hasLoadingProductionBackground: (state) => state.type == Background.LOADING_PRODUCTION_TYPE,

    backgroundTimeline: (state) => state.timeline,

    backgroundNeedsRefresh: (state) => state.needsRefresh,

    backgroundOverlayColorStart: (state, getters, rootState) =>
        state.overlay.color.start || rootState.branding.palette.color1 || Color.BACKGROUND_DEFAULT,

    collageImage: (state) => (index) => state.collage.images['image' + index] || '',

    backgroundVideoSrc: (state, getters, rootState) => {
        const hasTimerangeSegments =
            Array.isArray(state.video.timerangeSegments) && state.video.timerangeSegments.length > 0;

        //Optimize video playback by using media fragments if quickcut is not open
        if (hasTimerangeSegments && !rootState.ui.quickcut.isQuickCutOpen) {
            const firstSegment = state.video.timerangeSegments[0];
            const lastSegment = state.video.timerangeSegments[state.video.timerangeSegments.length - 1];

            const start = firstSegment?.start ?? 0;
            const end = lastSegment?.end ?? state.video.totalDuration();

            return conversions.mediaFragmentAssetSrc(state.video.src, state.video.totalDuration(), {
                start,
                end
            });
        }

        return state.video.src;
    },

    collageImageRef: (state) => (index) => state.collage.images['image' + index + '__ref'] || '',

    collageImageId: (state) => (index) => state.collage.images['image' + index + '__id'] || ''
};

// Mutations
const mutations = {
    setBackgroundEl(state, el) {
        state.el = el;
    },

    setBackgroundTimeline: timeline.mutations.setTimeline,

    enableBackgroundRefresh: timeline.mutations.enableRefresh,

    setBackground(state, type) {
        state.type = types.isBackground(type) ? type : Background.DEFAULT;
    },

    setBackgroundCollageAnimation(state, animated) {
        state.collage.animated = animated;
    },

    setBackgroundCollage(state, collage) {
        state.collage.animated = collage.animated;
        let p,
            images = collage.images ?? [];
        for (p in images) {
            if (images[p]) state.collage.images[p] = images[p];
        }
    },

    enableBackgroundOverlay(state, enable) {
        state.overlay.enabled = enable;
    },

    setBackgroundOverlay(state, type) {
        state.overlay.type = types.isBackgroundOverlay(type) ? type : Overlay.DEFAULT;
    },

    setBackgroundOverlayOpacity(state, opacity) {
        state.overlay.opacity = conversions.percentNum(opacity, false) || Color.BACKGROUND_OPACITY_DEFAULT;
    },

    enableBackgroundOverlayAnimation(state, enable) {
        state.overlay.animation.enabled = enable;
    },

    setBackgroundOverlayStart(state, start) {
        state.overlay.animation.start = types.isZeroPositive(start) ? parseFloat(start) : Duration.START_DEFAULT;
    },

    setBackgroundOverlayEnd(state, end) {
        state.overlay.animation.end = types.isZeroPositive(end) ? parseFloat(end) : Duration.END_DEFAULT;
    },

    enableBackgroundBlur(state, blur) {
        state.blur = blur;
    },

    setBackgroundAnimation(state, type) {
        state.animation.type = types.isBackgroundAnimation(type) ? type : Background.ANIMATION_DEFAULT;
    },

    setBackgroundAnimationZoom(state, data) {
        state.animation.zoomTarget = types.isAlign(data.zoomTarget) ? data.zoomTarget : Align.CENTER;
        state.animation.zoomTargetX =
            state.animation.zoomTarget == Align.CUSTOM && types.isDimension(data.zoomTargetX)
                ? data.zoomTargetX
                : Dimension.ZERO;
        state.animation.zoomTargetY =
            state.animation.zoomTarget == Align.CUSTOM && types.isDimension(data.zoomTargetY)
                ? data.zoomTargetY
                : Dimension.ZERO;
    },

    setBackgroundMapZoomCoords(state, coords) {
        state.mapZoom.coords = coords || Background.DEFAULT_MAP_ZOOM_COORDS;
    },

    enableBackgroundMapZoomMarker(state, enabled) {
        state.mapZoom.marker.enabled = enabled;
    },

    setBackgroundMapZoomMarker(state, src) {
        state.mapZoom.marker.src = src;
    },

    setBackgroundColor(state, color) {
        state.color.start = types.isColor(color.start) ? color.start : Color.BACKGROUND_DEFAULT;
        state.color.start__ref = color.start__ref || state.color.start;
        state.color.end = types.isColor(color.end) ? color.end : Color.NONE;
        state.color.end__ref = color.end__ref || state.color.end;
        state.color.angle = types.isAngle(color.angle) ? color.angle : Color.GRADIENT_ANGLE_DEFAULT;
    },

    setBackgroundOverlayColor(state, color) {
        state.overlay.color.start = types.isColor(color.start) ? color.start : Color.NONE;
        state.overlay.color.start__ref = color.start__ref || state.overlay.color.start;
        state.overlay.color.end = types.isColor(color.end) ? color.end : Color.NONE;
        state.overlay.color.end__ref = color.end__ref || state.overlay.color.end;
        state.overlay.color.angle = types.isAngle(color.angle) ? color.angle : Color.GRADIENT_ANGLE_DEFAULT;
    },

    setBackgroundMapZoomAddress(state, address) {
        state.mapZoom.address = address || '';
    },

    setBackgroundMapZoomMarkerReference(state, ref) {
        state.mapZoom.marker.src__ref = ref;
    },

    setBackgroundMapZoomMarkerId(state, id) {
        state.mapZoom.marker.src__id = id;
    },

    setCollageImages(state, images) {
        let p;
        for (p in images) {
            if (!/(?:__ref|__id)$/.test(p) && images[p] != state.collage.images[p]) state.collage.images[p] = images[p];
        }
    },

    setCollageImageReferences(state, images) {
        let p;
        for (p in images) {
            if (/__ref$/.test(p)) state.collage.images[p] = images[p];
        }
    },

    setCollageImageIds(state, images) {
        let p;
        for (p in images) {
            if (/__id$/.test(p)) state.collage.images[p] = images[p];
        }
    },

    setScaleValue(state, value) {
        state.scale.value = parseFloat(value.toFixed(3));
    },

    setScaleRef(state, ref) {
        state.scale.ref = parseFloat(ref.toFixed(3));
    },

    setCover(state, cover) {
        state.cover = cover;
    },

    setPosition(state, position) {
        state.position.alignH = types.isAlign(position.alignH) ? position.alignH : Align.CENTER;
        state.position.alignV = types.isAlign(position.alignV)
            ? position.alignV
            : types.isAlign(position.alignH)
              ? Align.CENTER
              : Align.DISTRIBUTED_DEFAULT;
        state.position.alignX =
            state.position.alignH === Align.CUSTOM && types.isDimension(position.alignX)
                ? position.alignX
                : Dimension.ZERO;
        state.position.alignY =
            state.position.alignV === Align.CUSTOM && types.isDimension(position.alignY)
                ? position.alignY
                : Dimension.ZERO;
    }
};

// Actions
const actions = {
    initBackground({ commit, dispatch }, data) {
        commit('setBackground', data.type);
        commit('setBackgroundColor', data.color);
        commit('setBackgroundCollage', data.collage || {});
        commit('enableBackgroundOverlay', data.overlay.enabled);
        commit('setBackgroundOverlay', data.overlay.type);
        commit('setBackgroundOverlayColor', data.overlay.color);
        commit('setBackgroundOverlayOpacity', data.overlay.opacity);
        commit('enableBackgroundOverlayAnimation', data.overlay.animation.enabled);
        commit('setBackgroundOverlayStart', data.overlay.animation.start);
        commit('setBackgroundOverlayEnd', data.overlay.animation.end);
        commit('enableBackgroundBlur', data.blur);
        commit('setBackgroundAnimation', data.animation.type);
        commit('setBackgroundAnimationZoom', data.animation);
        commit('setBackgroundMapZoomCoords', data.mapZoom.coords);
        commit('enableBackgroundMapZoomMarker', data.mapZoom.marker.enabled);
        commit('setBackgroundMapZoomMarker', data.mapZoom.marker.src);
        commit('setBackgroundMapZoomAddress', data.mapZoom.address);
        commit('setBackgroundMapZoomMarkerId', data.mapZoom.marker.src__id);
        commit('setBackgroundMapZoomMarkerReference', data.mapZoom.marker.src__ref);

        if (data.position) commit('setPosition', data.position);
        if (data.scale) {
            commit('setScaleRef', data.scale.ref);
            commit('setScaleValue', data.scale.value);
        }
        commit('setCover', data.hasOwnProperty('cover') ? data.cover : true);

        if (data.image) dispatch('image/init', data.image);
        if (data.video) dispatch('video/init', data.video);
    },

    updateBackgroundMapZoomLocation({ commit }, { coords, address }) {
        commit('setBackgroundMapZoomCoords', coords);
        commit('setBackgroundMapZoomAddress', address);
    },

    updateBackgroundMapZoomMarker({ commit }, data) {
        commit('setBackgroundMapZoomMarkerId', data.src__id);
        commit('setBackgroundMapZoomMarker', data.src);
    },

    updateCollageImages({ commit }, images) {
        commit('setCollageImageIds', images);
        commit('setCollageImages', images);
    },

    applyTemplateBackground({ state, rootGetters, dispatch }, data) {
        dispatch('initBackground', mergeTemplateData(state, data, rootGetters['branding/libraries/findLibraryItem']));
    },

    initializeScale({ commit }, value) {
        commit('setScaleValue', value);
        commit('setScaleRef', value);
    },

    resetPosition({ commit, dispatch }) {
        dispatch('initializeScale', 1);
        commit('setPosition', { ...defaultPosition });
        commit('setCover', true);
    }
};

export default {
    state,
    modules: {
        video: media,
        image: simpleMedia
    },
    getters,
    mutations,
    actions
};

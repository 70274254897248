<template>
    <div key="bg-map-zoom" ref="$bgMapZoom" class="studio-map-zoom" :style="mapZoomStyles">
        <div class="studio-map-zoom-level studio-map-zoom-level-1">
            <AssetImage
                name="$bgMapZoomImage1"
                :src="getMapZoomURL(0)"
                width="100%"
                height="100%"
                :cover="true"
                class="studio-map-zoom-image"
            />
            <div class="studio-map-zoom-level studio-map-zoom-level-2">
                <AssetImage
                    name="$bgMapZoomImage2"
                    :src="getMapZoomURL(1)"
                    width="100%"
                    height="100%"
                    :cover="true"
                    class="studio-map-zoom-image"
                />
                <div class="studio-map-zoom-level studio-map-zoom-level-3">
                    <AssetImage
                        name="$bgMapZoomImage3"
                        :src="getMapZoomURL(2)"
                        width="100%"
                        height="100%"
                        :cover="true"
                        class="studio-map-zoom-image"
                    />
                    <div class="studio-map-zoom-level studio-map-zoom-level-4">
                        <AssetImage
                            name="$bgMapZoomImage4"
                            :src="getMapZoomURL(3)"
                            width="100%"
                            height="100%"
                            :cover="true"
                            class="studio-map-zoom-image"
                        />
                        <div class="studio-map-zoom-level studio-map-zoom-level-5">
                            <AssetImage
                                name="$bgMapZoomImage5"
                                :src="getMapZoomURL(4)"
                                width="100%"
                                height="100%"
                                :cover="true"
                                class="studio-map-zoom-image"
                            />
                            <AssetImage
                                name="$bgMapZoomImageRoadmap"
                                :src="getMapZoomURL(4, true)"
                                width="100%"
                                height="100%"
                                :cover="true"
                                class="studio-map-zoom-image studio-map-zoom-image-roadmap"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <AssetImage
            name="$bgMapZoomClouds1"
            :src="cloudAsset"
            class="studio-map-zoom-clouds studio-map-zoom-clouds-1"
        />
        <AssetImage
            name="$bgMapZoomClouds2"
            :src="cloudAsset"
            class="studio-map-zoom-clouds studio-map-zoom-clouds-2"
        />
        <div v-show="markerEnabled" class="studio-map-zoom-marker">
            <svg class="studio-map-zoom-marker-shadow" viewBox="0 0 50 10">
                <ellipse cx="50%" cy="50%" rx="50%" ry="50%" />
            </svg>
            <div class="studio-map-zoom-marker-image studio-container">
                <svg v-if="!markerSrc" class="studio-map-zoom-marker-default" viewBox="0 0 64 83">
                    <path
                        d="M64,32A31.1,31.1,0,0,0,54.7,9.3,31.1,31.1,0,0,0,32,0,31.2,31.2,0,0,0,9.4,9.3,30.92,30.92,0,0,0,0,32Q0,43.4,9,57.6,22.8,79.3,32,83q5.4-2.3,12.6-10.9A124.4,124.4,0,0,0,54.9,57.6Q64,42.79,64,32Z"
                        fill="#87a61b"
                    />
                    <path
                        d="M43.36,20.72A15.47,15.47,0,0,0,32,16a15.2,15.2,0,0,0-11.28,4.72A15.2,15.2,0,0,0,16,32a15.2,15.2,0,0,0,4.72,11.28A15.2,15.2,0,0,0,32,48a15.47,15.47,0,0,0,11.36-4.72A15.36,15.36,0,0,0,48,32,15.36,15.36,0,0,0,43.36,20.72Z"
                        fill="#fff"
                    />
                </svg>
                <AssetImage
                    v-else
                    name="$bgMapZoomCustomMarker"
                    :src="markerSrc"
                    width="100%"
                    height="100%"
                    class="studio-map-zoom-marker-custom"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import AssetImage from '@/js/video-studio/components/assets/AssetImage.vue';
import cloudAsset from '@resources/assets/background/clouds.png';
import { Background } from '@/js/video-studio/constants/index.js';
import { computed } from 'vue';

const props = defineProps({
    markerSrc: String,
    markerEnabled: Boolean,
    coords: String,
    format: Object
});

const getMapZoomURL = (zoomLevel, roadmap = false) => {
    let url = Background.MAP_ZOOM_URL;
    url = url.replace(Background.MAP_ZOOM_COORDS_PLACEHOLDER, props.coords);
    url = url.replace(Background.MAP_ZOOM_LEVEL_PLACEHOLDER, Background.MAP_ZOOM_LEVELS[zoomLevel]);
    url = url.replace(
        Background.MAP_ZOOM_TYPE_PLACEHOLDER,
        (roadmap && Background.MAP_ZOOM_ROADMAP_TYPE) || Background.MAP_ZOOM_SATELLITE_TYPE
    );
    url = url.replace(Background.MAP_ZOOM_API_KEY_PLACEHOLDER, window.STUDIO_MAP_API_KEY || '');
    return url;
};

const mapZoomStyles = computed(() => {
    const bgSizeRect = { width: props.format.width, height: props.format.height };

    return {
        transform:
            'translate(-50%, -50%) scale(' +
            Math.max(
                1,
                Background.MAP_ZOOM_WIDTH / Background.MAP_ZOOM_HEIGHT / (bgSizeRect.width / bgSizeRect.height)
            ) +
            ')'
    };
});
</script>

import { computed } from 'vue';
import { useStore } from 'vuex';
import { Duration, Visual } from 'cte-video-studio';
import { useSequence } from './useSequence';
import { useHistory } from './useHistory';

export function useElement(seqId, elementId, store = null) {
    if (!store) {
        store = useStore();
    }
    const { saveHistoryStep } = useHistory(store);
    const { sequenceState, sequenceStoreModulePath } = useSequence(seqId, store);

    const elementState = computed(() => sequenceState.value[elementId]);
    const elementStoreModulePath = computed(() => `${sequenceStoreModulePath.value}/${elementId}`);
    const isRecordingCategory = computed(() => elementState.value.animation.category == Visual.RECORDING_CATEGORY);
    const videoState = computed(() => elementState.value.video);
    const maxDuration = computed(() => videoState.value.totalDuration());

    const startTime = computed(() => sequenceState.value.start);

    const playbackRate = computed({
        get: () => videoState.value.playbackRate || Duration.PLAY_BACK_RATE_DEFAULT,
        set: (value) => {
            saveHistoryStep(() => {
                store.commit(`${elementStoreModulePath.value}/video/setPlaybackRate`, value);
            });
        }
    });

    const videoRange = computed({
        get: () => {
            const range = { start: 0, end: -1 };
            range.start = videoState.value.timerange.start;
            range.end = videoState.value.timerange.end;

            return range;
        },
        set: ({ start, end }) => {
            if (start !== videoRange.value.start || end !== videoRange.value.end) {
                saveHistoryStep(() => {
                    store.commit(`${elementStoreModulePath.value}/video/setTimerange`, { start, end });
                });
            }
        }
    });

    const videoTimerangeSegments = computed({
        get: () => elementState.value.video.timerangeSegments,
        set: (value) => {
            saveHistoryStep(() => {
                store.commit(`${elementStoreModulePath.value}/video/setTimerangeSegments`, value);
            });
        }
    });

    const videoSrc = computed({
        get: () => elementState.value.video,
        set: (value) => {
            saveHistoryStep(() => {
                store.commit(`${elementStoreModulePath.value}/video/setSource`, value);
            });
        }
    });

    return {
        elementState,
        elementStoreModulePath,
        videoState,
        isRecordingCategory,
        maxDuration,
        playbackRate,
        videoTimerangeSegments,
        videoRange,
        videoSrc
    };
}

<template>
    <div v-if="state.enabled" class="studio-borders studio-container" :style="style" v-show="visible"></div>
</template>

<script>
import { Dimension, Production } from '../constants';
import { mapGetters, mapState } from 'vuex';

export default {
    inject: ['$stage'],

    props: {
        preview: Boolean,
        captureMode: [Boolean, String],
        currentLabel: String
    },

    computed: {
        ...mapState('settings', {
            state: (state) => state.borders
        }),

        ...mapGetters({
            formatFontSize: 'display/formatFontSize',
            borders: 'settings/borders'
        }),

        visible() {
            return (
                this.preview ||
                this.captureMode == Production.FRAME_CAPTURE_MODE ||
                this.currentLabel != Production.LOADING_SEQUENCE_ID
            );
        },

        style() {
            let size =
                    Math.round((this.state.size * this.formatFontSize) / Dimension.BORDER_SIZE_DEFAULT) +
                    Dimension.PIXEL_UNIT,
                borderWidth =
                    '' +
                    (this.borders && this.borders.top.size !== Dimension.ZERO ? size : 0) +
                    ' ' +
                    (this.borders && this.borders.right.size !== Dimension.ZERO ? size : 0) +
                    ' ' +
                    (this.borders && this.borders.bottom.size !== Dimension.ZERO ? size : 0) +
                    ' ' +
                    (this.borders && this.borders.left.size !== Dimension.ZERO ? size : 0);

            return {
                borderWidth: borderWidth,
                borderColor: this.state.color,
                borderStyle: 'solid'
            };
        }
    }
};
</script>

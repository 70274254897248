<template>
    <ui-card :id="id" :class="'ui-card-' + id" class="ui-card-templates-library" :title="title">
        <div class="ui-templates-library-inner">
            <ul v-if="!!libraryFilteredAndSorted.length" class="ui-templates">
                <li
                    v-for="item in libraryPaged"
                    :key="'library-template-' + item.id"
                    class="ui-template"
                    :class="'ui-template-status-' + item.status"
                >
                    <a
                        :href="getRoute(item.id).href"
                        :ref="'$template-' + item.id + '-edit'"
                        class="ui-template-edit-link"
                        @[item.clickEvent]="startDuplicateCteTemplate(item, $event)"
                    >
                        <h4 class="visually-hidden">{{ item.name }}</h4>
                    </a>
                    <input
                        type="checkbox"
                        class="ui-checkbox ui-template-checkbox"
                        :checked="itemIsSelected(library, item.id)"
                        :disabled="readOnly"
                        :aria-label="sprintf($t('Select template %1$s'), item.name)"
                        @change="toggleItem({ library, id: item.id })"
                    />
                    <div class="ui-template-thumbnail" :style="item.styles">
                        <svg-id-randomized-icon
                            v-if="!item.thumbnails.static_src"
                            icon="cte-icon"
                            class="default-icon"
                            aria-hidden="true"
                        />
                        <img
                            v-if="!!item.thumbnails.static_src"
                            :src="item.thumbnails.static_src"
                            class="visually-hidden"
                            :alt="sprintf($t('Preview image of template %1$s'), item.name)"
                        />
                        <fa-icon icon="fa-solid fa-eye-slash" class="icon status-hidden-icon" aria-hidden="true" />
                    </div>
                    <div class="ui-template-details">
                        <h4 class="template-name">{{ item.name }}</h4>
                        <p class="template-updated-at">{{ item.readable_updated_at }}</p>
                    </div>
                    <div class="ui-template-taxonomy">
                        <div v-if="canViewAiSettings && item.ai_enabled" class="ui-template-ai">
                            <svg-icon icon="ai-icon" :title="$t('Enabled for AI')" />
                            <h5 class="ai-name">{{ item.ai_name }}</h5>
                        </div>
                        <ul class="ui-template-categories">
                            <li :key="category" v-for="category in item.sortedCategories">{{ category }}</li>
                        </ul>
                    </div>
                    <div class="ui-template-owner">
                        <template v-if="!!item.owner_id || item.is_made_by_cte">
                            <div
                                v-if="item.is_made_by_cte || !item.owner_avatar_src"
                                class="owner-avatar"
                                :class="{ initials: !item.is_made_by_cte && !item.owner_avatar_src }"
                            >
                                <svg-id-randomized-icon
                                    v-if="item.is_made_by_cte"
                                    icon="cte-logo"
                                    :title="item.owner_name"
                                />
                                <span v-else class="owner-initials" :title="item.owner_name">{{
                                    item.owner_initials
                                }}</span>
                            </div>
                            <img v-else :src="item.owner_avatar_src" class="owner-avatar" :alt="item.owner_name" />
                            <p class="owner-name">{{ item.owner_name }}</p>
                        </template>
                    </div>
                    <fieldset :id="id + '-template-' + item.id" class="ui-template-activation" :disabled="readOnly">
                        <legend class="visually-hidden">
                            {{ sprintf($t('Change %1$s activation status'), item.name) }}
                        </legend>
                        <template :key="status" v-for="status in allowedLibraryStatus">
                            <input
                                type="radio"
                                :id="id + '-template-' + item.id + '-status-' + status"
                                :name="id + '-template-' + item.id + '-status'"
                                :value="status"
                                :checked="item.status == status"
                                aria-hidden="true"
                            />
                            <label :for="id + '-template-' + item.id + '-status-' + status" aria-hidden="true">
                                {{ $t('brands.status.' + status + '.label') }}
                            </label>
                        </template>
                        <button
                            v-if="!readOnly"
                            class="activation-label ui-simple-button"
                            @click.prevent="setItemNextStatus(item, $event)"
                        >
                            <span class="visually-hidden">{{
                                $t('brands.status.' + nextLibraryStatus[item.status] + '.action')
                            }}</span>
                            <fa-icon class="icon" :icon="libraryStatusIcons[item.status]" aria-hidden="true" />
                            <span aria-hidden="true">{{ $t('brands.status.' + item.status + '.label') }}</span>
                        </button>
                    </fieldset>
                    <component
                        :is="item.disableExport ? 'div' : 'button'"
                        class="ui-template-export ui-loading-button"
                        :class="{ loading: item.showLoading, 'has-error': item.has_failed_production }"
                        v-tooltip="item.exportTooltip"
                        :disabled="item.disableExport"
                        :tabindex="item.disableExport ? '0' : null"
                        @[item.exportEvent]="openExportTemplate(item)"
                    >
                        <fa-icon class="icon" icon="fa-regular fa-arrow-down-to-line" />
                        <svg-icon class="loader" icon="circle-loader" />
                    </component>
                    <ui-dropdown
                        :ref="'$template-' + item.id + '-menu'"
                        :id="id + '-template-' + item.id + '-menu'"
                        class="ui-template-actions"
                        :toggle-class="[
                            'ui-action-button',
                            'ui-loading-button',
                            { loading: processingTemplates.includes(item.id) }
                        ]"
                        :disabled="processingTemplates.includes(item.id)"
                        :icon="true"
                        :caret="false"
                        menu-placement="bottom-end"
                    >
                        <template #dropdown-toggle>
                            <span class="visually-hidden">{{ $t('Template actions...') }}</span>
                            <svg-icon icon="menu-icon" />
                            <svg-icon class="loader" icon="circle-loader" />
                        </template>
                        <ui-dropdown-item
                            v-if="item.permissions.copy"
                            :disabled="readOnly"
                            @click.prevent="startDuplicateTemplate(item)"
                            >{{ $t('Duplicate') }}</ui-dropdown-item
                        >
                        <ui-dropdown-item
                            v-if="item.permissions.move"
                            :disabled="true"
                            @click.prevent="copyTemplate(item)"
                            >{{ $t('Copy to...') }}</ui-dropdown-item
                        >
                        <ui-dropdown-item
                            v-if="item.permissions.produce"
                            :disabled="readOnly"
                            @click.prevent="startProducingTemplate(item)"
                            >{{ $t('Produce') }}</ui-dropdown-item
                        >
                        <ui-dropdown-item
                            v-if="item.permissions.delete"
                            classes="ui-remove-item"
                            :disabled="readOnly"
                            @click.prevent="startDeleteTemplate(item)"
                            >{{ $t('Delete') }}</ui-dropdown-item
                        >
                    </ui-dropdown>
                </li>
            </ul>
            <div v-else class="ui-templates-empty">
                <p class="m-0">{{ $t('No matching template.') }}</p>
            </div>
        </div>
    </ui-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { printf as sprintf } from 'fast-printf';
import {
    CATEGORIES_INCLUDING_BRAND_NAME,
    LIBRARY_FILTER_PROPERTIES,
    LIBRARY_SORT_AI_NAME,
    LIBRARY_SORT_CATEGORY,
    LIBRARY_SORT_LANGUAGE,
    LIBRARY_SORT_NAME,
    LIBRARY_SORT_OWNER,
    LIBRARY_SORT_UPDATED_AT,
    LIBRARY_STATUS_ACTIVABLE,
    LIBRARY_STATUS_ACTIVE,
    LIBRARY_STATUS_HIDDEN,
    TEMPLATE_LIBRARY_PAGE_SIZE
} from '../../../../../constants';
import { UI_SCROLLABLE_ENDED_EVENT } from '../../../../../components/UiScrollable.vue';
import { UI_MODAL_ACTION_EVENT } from '../../../../../components/modals/UiModal.vue';
import { UsesTooltip } from '../../../../../mixins';
import { BrandCard } from '../../../../mixins';
import { parseEventName } from '../../../../../utils';
import router from '@/router/index.js';
import ProduceVideoService from '@/js/application/services/video/ProduceVideoService.js';
import { useStore } from 'vuex';

const store = useStore();

const STATUS_ICONS = {
    [LIBRARY_STATUS_HIDDEN]: 'fa-solid fa-eye-slash',
    [LIBRARY_STATUS_ACTIVABLE]: 'fa-solid fa-xmark',
    [LIBRARY_STATUS_ACTIVE]: 'fa-solid fa-check'
};

export default {
    mixins: [UsesTooltip, BrandCard],

    components: {
        //
    },

    inject: ['scrollEmitters'],

    props: {
        title: {
            type: String,
            default: ''
        },
        library: {
            type: String,
            default: ''
        },
        panel: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
            libraryStatusHidden: LIBRARY_STATUS_HIDDEN,
            libraryStatusActivable: LIBRARY_STATUS_ACTIVABLE,
            libraryStatusActive: LIBRARY_STATUS_ACTIVE,
            libraryStatusIcons: STATUS_ICONS,

            librarySortedIds: [],
            maxLibraryPage: 1,
            processingTemplates: []
        };
    },

    computed: {
        ...mapState({
            shortLanguage: (state) => state.ui.shortLanguage,
            canViewAiSettings: (state) => state.ui.permissions.viewAiSettings,
            currentPanel: (state) => state.ui.currentPanel,
            originalState: (state) => state.ui.originalState,
            libraries: (state) => state.branding.libraries,
            brandId: (state) => state.branding.id,
            organizationName: (state) => state.ui.organizationName
        }),

        ...mapGetters({
            canHideElement: 'ui/canHideElement',
            getLibrarySortedByTag: 'branding/libraries/librarySortedByTag',
            getLibraryTags: 'branding/libraries/libraryTags',
            getLibraryFiltered: 'branding/libraries/libraryFiltered',
            itemIsSelected: 'branding/libraries/libraryItemIsSelected'
        }),

        allowedLibraryStatus() {
            let status = [];
            if (this.canHideElement) status.push(LIBRARY_STATUS_HIDDEN);
            if (!this.state.isMaster) status.push(LIBRARY_STATUS_ACTIVABLE);
            status.push(LIBRARY_STATUS_ACTIVE);

            return status;
        },

        nextLibraryStatus() {
            return this.allowedLibraryStatus.reduce((next, status, index) => {
                next[status] =
                    this.allowedLibraryStatus[
                        (index + this.allowedLibraryStatus.length - 1) % this.allowedLibraryStatus.length
                    ];
                return next;
            }, {});
        },

        libraryFilter() {
            return this.libraries.filters[this.library] || '';
        },

        librarySortType() {
            return this.libraries.sortTypes[this.library] || LIBRARY_SORT_UPDATED_AT;
        },

        libraryLanguage() {
            return this.libraries.languages[this.library] || this.shortLanguage;
        },

        libraryFiltered() {
            let originalState = this.originalState(),
                originalLibrary = originalState.libraries[this.library],
                originalLanguages = originalState.enabledTemplateLanguages;

            return this.getLibraryFiltered(this.library, false, LIBRARY_FILTER_PROPERTIES).map((item) => {
                let clickEvent = !item.permissions.edit && item.permissions.copy ? 'click' : null,
                    needsProduction =
                        !item.has_failed_production && (!item.latest_produced_url || item.latest_produced_is_outdated),
                    disableExport =
                        item.status != LIBRARY_STATUS_ACTIVE ||
                        item.is_producing ||
                        needsProduction ||
                        item.has_failed_production,
                    exportEvent = !disableExport ? 'click' : null,
                    statusChanged =
                        originalLibrary.find((libraryItem) => libraryItem.id == item.id)?.status !== item.status,
                    languagesChanged =
                        this.state.enabledTemplateLanguages.includes(item.language) &&
                        !originalLanguages.includes(item.language),
                    showLoading =
                        item.is_producing ||
                        (item.status == LIBRARY_STATUS_ACTIVE &&
                            !statusChanged &&
                            !languagesChanged &&
                            needsProduction),
                    exportTooltip = '';

                if (disableExport) {
                    switch (true) {
                        case item.status != LIBRARY_STATUS_ACTIVE:
                            exportTooltip = this.$t('This video will be downloadable after being enabled.');
                            break;
                        case item.is_producing:
                            exportTooltip = this.$t('Video in production...');
                            break;
                        case item.has_failed_production:
                            exportTooltip = this.$t('An error occured during the production of this video.');
                            break;
                        case needsProduction:
                            exportTooltip =
                                statusChanged || languagesChanged
                                    ? this.$t(
                                          'The production of this video will start after saving the current changes.'
                                      )
                                    : this.$t('The production of this video will start in a few minutes.');
                            break;
                    }
                }

                let sortedCategories = item.categories
                    .map((category) => {
                        const tagName = this.$t('brands.categories.' + this.library + '.' + category);
                        return CATEGORIES_INCLUDING_BRAND_NAME.includes(category)
                            ? sprintf(tagName, this.organizationName)
                            : tagName;
                    })
                    .sort((categoryA, categoryB) =>
                        this.getSortableLabel(categoryA).localeCompare(
                            this.getSortableLabel(categoryB),
                            this.shortLanguage
                        )
                    );

                return {
                    ...item,
                    clickEvent,
                    disableExport,
                    exportEvent,
                    exportTooltip,
                    needsProduction,
                    showLoading,
                    statusChanged,
                    sortedCategories,
                    styles: {
                        '--item-thumbnail-static': !!item.thumbnails.static_src
                            ? "url('" + item.thumbnails.static_src + "')"
                            : null,
                        '--item-thumbnail-preview': !!item.thumbnails.preview_src
                            ? "url('" + item.thumbnails.preview_src + "')"
                            : null
                    }
                };
            });
        },

        libraryFilteredAndSorted() {
            return [...this.libraryFiltered].sort((itemA, itemB) => {
                return this.librarySortedIds.indexOf(itemA.id) - this.librarySortedIds.indexOf(itemB.id);
            });
        },

        libraryPaged() {
            return this.libraryFilteredAndSorted.slice(0, this.maxLibraryPage * TEMPLATE_LIBRARY_PAGE_SIZE);
        },

        deleteTemplateLabel() {
            switch (this.library) {
                case 'templates':
                    return this.$t('Are you sure you want to delete the template "%1$s"?');
                case 'sequenceTemplates':
                    return this.$t('Are you sure you want to delete the sequence template "%1$s"?');
            }

            return '';
        }
    },

    watch: {
        currentPanel(newValue) {
            if (newValue == this.panel) {
                this.scrollEmitters[0].$el.addEventListener(UI_SCROLLABLE_ENDED_EVENT, this.handleScrollEnd);
            } else {
                this.scrollEmitters[0].$el.removeEventListener(UI_SCROLLABLE_ENDED_EVENT, this.handleScrollEnd);
                this.maxLibraryPage = 1;
            }
        },

        libraryFilter() {
            this.librarySortedIds = this.getLibrarySortedIds();
            this.maxLibraryPage = 1;
        },

        librarySortType() {
            this.librarySortedIds = this.getLibrarySortedIds();
            this.maxLibraryPage = 1;
        },

        libraryLanguage() {
            this.librarySortedIds = this.getLibrarySortedIds();
            this.maxLibraryPage = 1;
        }
    },

    methods: {
        ...mapMutations({
            addModal: 'ui/modals/add',
            removeModal: 'ui/modals/remove'
        }),

        ...mapActions({
            updateOriginalState: 'ui/updateOriginalState',
            duplicateTemplate: 'ui/duplicateTemplate',
            deleteTemplate: 'ui/deleteTemplate',
            toggleItem: 'branding/libraries/toggleItem',
            removeItem: 'branding/libraries/removeItem'
        }),

        sprintf,

        setItemNextStatus(item, event) {
            this.modifyBrand(() => {
                this.setLibraryItemStatus({
                    library: this.library,
                    id: item.id,
                    status: this.nextLibraryStatus[item.status]
                });
            });
        },

        getLibrarySortedIds() {
            return [...this.libraryFiltered]
                .sort((itemA, itemB) => {
                    return this.compareBySortType(this.librarySortType, itemA, itemB);
                })
                .map((item) => item.id);
        },

        getSortableLabel(label) {
            try {
                return label.replace(
                    new RegExp(
                        '^(?:\\p{Emoji_Modifier_Base}\\p{Emoji_Modifier}?|\\p{Emoji_Presentation}|\\p{Emoji}\\u{FE0F}*)\\s',
                        'gu'
                    ),
                    ''
                );
            } catch (error) {
                return label;
            }
        },

        compareBySortType(sortType, templateA, templateB) {
            if (
                [LIBRARY_SORT_NAME, LIBRARY_SORT_OWNER, LIBRARY_SORT_LANGUAGE, LIBRARY_SORT_AI_NAME].includes(sortType)
            ) {
                if (!templateA[sortType]) return 1;
                if (!templateB[sortType]) return -1;
                return templateA[sortType].localeCompare(templateB[sortType], this.shortLanguage);
            } else if (sortType === LIBRARY_SORT_CATEGORY) {
                if (!templateA.sortedCategories.length) return 1;
                if (!templateB.sortedCategories.length) return -1;

                const categoriesA = templateA.sortedCategories
                    .map((category) => this.getSortableLabel(category))
                    .join('\t');
                const categoriesB = templateB.sortedCategories
                    .map((category) => this.getSortableLabel(category))
                    .join('\t');
                return categoriesA.localeCompare(categoriesB, this.shortLanguage);
            } else {
                return templateB[sortType] - templateA[sortType];
            }
        },

        openExportTemplate(template) {
            event.preventDefault();

            this.addModal({
                id: 'export-template-' + template.id,
                type: 'modal-export',
                template
            });
        },

        startDuplicateTemplate(template, event) {
            if (!this.needsSave) {
                this.handleDuplicateTemplate(template);
            } else {
                this.addModal({
                    id: 'duplicate-template-' + template.id,
                    type: 'modal-create-without-saving',
                    actionLabel: this.$t('Duplicate without saving'),
                    waitingAction: async () => await this.handleDuplicateTemplate(template)
                });
            }
        },

        startDuplicateCteTemplate(template, event) {
            event.preventDefault();
            let newTemplateId;

            this.addModal({
                id: 'duplicate-template-' + template.id,
                type: 'modal-duplicate-edit',
                unsavedChanges: this.needsSave,
                waitingAction: async () => {
                    let response = await this.handleDuplicateTemplate(template);
                    newTemplateId = response.id;
                },
                afterDestroyAction: () => {
                    if (newTemplateId) router.push(this.getRoute(newTemplateId));
                }
            });
        },

        async handleDuplicateTemplate(template) {
            this.$refs['$template-' + template.id + '-menu'][0].hide();
            this.processingTemplates.push(template.id);
            try {
                return await this.duplicateTemplate({ type: this.library, id: template.id });
            } catch (error) {
                this.processingTemplates.splice(this.processingTemplates.indexOf(template.id), 1);
                // TODO: Display an error notification
            }
        },

        copyTemplate(template) {
            // TODO: Open modal with list of organizations
        },

        startProducingTemplate(template) {
            if (template.isProducing) return;

            template.isProducing = true;
            new ProduceVideoService(store).handleTemplates([template.id], this.brandId).catch(() => {
                template.isProducing = false;
            });
        },

        startDeleteTemplate(template) {
            this.addModal({
                id: 'delete-template-' + template.id,
                type: 'ui-confirm-modal',
                message: sprintf(this.deleteTemplateLabel, template.name),
                actionLabel: this.$t('Delete'),
                waitingAction: async () => await this.deleteTemplate({ type: this.library, id: template.id }),
                oldFocus: this.$refs['$template-' + template.id + '-menu'][0].$refs.$toggleElement,
                [parseEventName(UI_MODAL_ACTION_EVENT)]: () => this.endDeleteTemplate(template)
            });
        },

        endDeleteTemplate(template) {
            this.removeItem({ library: this.library, id: template.id, applyToOriginal: true });
        },

        handleScrollEnd(event) {
            this.maxLibraryPage = Math.min(
                this.maxLibraryPage + 1,
                Math.ceil(this.libraryFilteredAndSorted.length / TEMPLATE_LIBRARY_PAGE_SIZE)
            );
        },

        getRoute(id) {
            return router.resolve({
                name: this.library === 'sequenceTemplates' ? 'EditSequenceTemplate' : 'EditTemplate',
                params: { id: id, brandId: this.brandId }
            });
        }
    },

    created() {
        this.librarySortedIds = this.getLibrarySortedIds();
    },

    mounted() {
        if (this.currentPanel == this.panel) {
            this.scrollEmitters[0].$el.addEventListener(UI_SCROLLABLE_ENDED_EVENT, this.handleScrollEnd);
            this.handleScrollEnd();
        }
    },

    beforeUnmount() {
        if (this.currentPanel == this.panel) {
            this.scrollEmitters[0].$el.removeEventListener(UI_SCROLLABLE_ENDED_EVENT, this.handleScrollEnd);
            this.maxLibraryPage = 1;
        }
    }
};
</script>

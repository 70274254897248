<template>
    <div class="empty-folder-create-video-wrapper">
        <div class="ui-simple-button ui-simple-button-gradient empty-folder-create-button">
            <div class="ui-button-gradient-overlay" />
            <fa-icon class="icon" icon="fa-solid fa-plus" />
            <div class="ui-simple-button-text">{{ $t('Create a video') }}</div>
            <fa-icon class="icon button-dropdown-item-arrow-icon" icon="fa-solid fa-chevron-right" />
        </div>
        <div class="empty-folder-create-video-list">
            <div class="ui-simple-button" @click="createVideo(true)">
                <fa-icon class="icon" icon="fa-regular fa-square-dashed" />
                <span>{{ $t('From scratch') }}</span>
            </div>
            <div class="ui-simple-button" @click="createVideoFromTemplate()">
                <svg-icon icon="video-from-template-dark" />
                <span>{{ $t('From a template') }}</span>
            </div>
            <div class="ui-simple-button" @click="createVideoFromPrompt()">
                <svg-id-randomized-icon icon="video-from-ai-dark" />
                <span>{{ $t('From a prompt') }}</span>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useModal } from '@/js/composables/useModal.js';
import { useRouter } from 'vue-router';
import SvgIdRandomizedIcon from '@/js/components/SvgIdRandomizedIcon.vue';

const { openModal } = useModal();
const router = useRouter();

const createVideo = (notUsingLastFolder) => {
    openModal('CreateVideoModal', { notUsingLastFolder });
};

const createVideoFromTemplate = () => {
    router.push({ name: 'Home', hash: '#templates-list' });
};

const createVideoFromPrompt = () => {
    router.push({ name: 'Companion' });
};
</script>

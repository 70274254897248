<template>
    <div id="studio-controls" class="preview-controls">
        <!--UI Quick Cut -->
        <ui-quick-cut-wrapper></ui-quick-cut-wrapper>

        <div class="preview-timeline skeleton-element" v-if="skeleton">
            <div class="preview-control">
                <svg-icon icon="play-icon"></svg-icon>
            </div>
            <div class="preview-control-timeline"></div>
            <div class="preview-control-time">
                <span class="time-elapsed">0:00.00</span>
                <span class="time-total">0:00.00</span>
            </div>
            <div class="preview-control">
                <svg-icon icon="sound-on-icon"></svg-icon>
            </div>
        </div>
        <div class="preview-actions skeleton-element" v-if="skeleton">
            <div v-if="!externalPreview" class="preview-action">
                <!--<span class="action-icon">
                    <svg-icon icon="cte-icon"></svg-icon>
                </span>-->
                <span class="action-label">{{ $t('Sequence preview') }}</span>
            </div>
            <div class="preview-action">
                <span class="action-icon">
                    <svg-icon icon="rotate-play-icon"></svg-icon>
                </span>
                <span class="action-label">{{ $t('Full preview') }}</span>
            </div>
            <div class="preview-action ui-action-button">
                <span class="ui-dropdown-toggle-inner">
                    <svg-icon icon="play-icon"></svg-icon>
                </span>
            </div>
        </div>
        <div id="timeline-visualiser"></div>

        <controls-timeline v-cloak></controls-timeline>
        <controls-actions
            :enable-sequence-preview="!externalPreview"
            :enable-actions-menu="!externalPreview"
            :is-template="isTemplate"
            v-cloak
        ></controls-actions>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { ControlsActions, ControlsTimeline } from '@/js/videos/components/controls/index.js';
import UiQuickCutWrapper from '@/js/videos/components/controls/VideoEditor/subcomponents/UiQuickCutWrapper.vue';

export default defineComponent({
    name: 'StudioControls',

    components: {
        ControlsTimeline,
        ControlsActions,
        UiQuickCutWrapper
    },

    props: {
        skeleton: {
            type: Boolean,
            default: false
        },
        externalPreview: {
            type: Boolean,
            default: false
        },
        isTemplate: {
            type: Boolean,
            default: false
        }
    }
});
</script>

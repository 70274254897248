<template>
    <div>
        <div class="sequence-background-type ui-card-row">
            <UiColorSelector
                v-if="backgroundType === COLOR_TYPE"
                :id="'card-seq-' + seqId + '-bg-color'"
                key="color-selector-bg-color"
                v-model:color="backgroundColor"
                :default-color="backgroundDefaultColor"
                :palette="brandPalette"
                :enable-other-colors="brandEnableOtherColors"
                :label="$t('studio.background_types.color')"
                :showLabel="false"
                :disabled="readOnly || hasPreviousBackground"
            />
            <UiMediaSelector
                v-else-if="isMediaSelectorType"
                :id="'card-seq-' + seqId + '-bg-' + backgroundMediaType"
                :key="'media-selector-bg-' + backgroundMediaType"
                v-model:media="backgroundMedia"
                :type="mediaType"
                :label="$t('studio.background_types.' + backgroundMediaType)"
                :showLabel="false"
                :disabled="readOnly || hasPreviousBackground"
                :icon="mediaSelectorIcon"
                :trigger-opening="triggerSelection && isMediaSelectorType && !libraryId"
                @[mediaSelectorShowEvent]="openMediaLibrary"
                @[mediaSelectorChangeEvent]="handleMediaSelectorChange"
            />
            <UiLibrarySelector
                v-else-if="backgroundType === COLLAGE_TYPE"
                :id="'card-seq-' + seqId + '-bg-animation-collage'"
                key="library-selector-bg-animation-collage"
                v-model="backgroundAnimationType"
                :default-value="backgroundCollageDefaultAnimationType"
                library="backgroundAnimations"
                category="background-collage"
                preview-path="animations/background/"
                :label="$t('Collage')"
                :showLabel="false"
                :disabled="readOnly || hasPreviousBackground"
                icon="fa-solid fa-sparkles"
                :trigger-opening="triggerSelection && backgroundType === COLLAGE_TYPE && !libraryId"
                @[librarySelectorShowEvent]="openLibrary"
                @[librarySelectorPreviewChangeEvent]="previewBackgroundAnimationType"
            />
            <UiLocationSelector
                v-if="backgroundType === MAP_ZOOM_TYPE"
                :id="'card-seq-' + seqId + '-bg-map-zoom'"
                key="color-selector-bg-map-zoom"
                v-model:location="backgroundMapZoom"
                :label="$t('studio.background_types.mapZoom')"
                :showLabel="false"
                :disabled="readOnly || hasPreviousBackground"
            />
            <UiRecordingSelector
                v-else-if="backgroundType == RECORDING_TYPE"
                :id="'card-seq-' + seqId + '-bg-recording'"
                :key="'recording-selector-bg-recording'"
                v-model:recording="backgroundRecording"
                :seqId="seqId"
                :type="RECORDING_TYPE_SCREEN"
                :excluded-types="[RECORDING_TYPE_MICROPHONE]"
                :label="$t('studio.background_types.' + backgroundType)"
                :showLabel="false"
                :disabled="readOnly || hasPreviousBackground"
                :trigger-opening="triggerSelection && backgroundType == RECORDING_TYPE && !libraryId"
                @[recordingSelectorChangeEvent]="resetVideoCaptions"
            />

            <UiMediaTypeSelector
                v-if="backgroundTypes"
                :id="'card-seq-' + seqId + '-bg-type'"
                :seqId="seqId"
                v-model="backgroundType"
                :backgroundTypes="backgroundTypes"
                :backgroundType="backgroundType"
                :shouldDisablePreviousType="shouldDisablePreviousType(backgroundType)"
            />

            <div
                v-if="backgroundType === VIDEO_TYPE || backgroundType === RECORDING_TYPE"
                class="sequence-tools-selector-container"
            >
                <UiBackgroundCaptionSelector
                    :seqId="seqId"
                    :cardElementId="cardElementId"
                    :readOnly="readOnly || isMediaConverting"
                    :iconOnly="true"
                />
                <UiBackgroundVideoSpeedSelector
                    :seqId="seqId"
                    :cardElementId="cardElementId"
                    :readOnly="readOnly || isMediaConverting"
                    :iconOnly="true"
                />
                <UiBackgroundVolumeSelector
                    :seqId="seqId"
                    :cardElementId="cardElementId"
                    :readOnly="readOnly"
                    :iconOnly="true"
                />
            </div>
        </div>
        <div
            class="sequence-background-animation ui-card-row"
            v-if="
                (backgroundType === IMAGE_TYPE ||
                    backgroundType === VIDEO_TYPE ||
                    backgroundType === ANIMATED_TYPE ||
                    backgroundType === CARTOON_TYPE ||
                    backgroundType === RECORDING_TYPE) &&
                isEmotionMode
            "
        >
            <UiLibrarySelector
                :id="'card-seq-' + seqId + '-bg-animation'"
                class="ui-card-row"
                key="library-selector-bg-animation"
                v-model="backgroundAnimationType"
                :default-value="backgroundDefaultAnimationType"
                library="backgroundAnimations"
                category="background"
                preview-path="animations/background/"
                :label="$t('Animation')"
                :use-selection-label="false"
                :disabled="readOnly || hasPreviousBackground"
                icon="fa-solid fa-sparkles"
                @[librarySelectorShowEvent]="openLibrary"
                @[librarySelectorPreviewChangeEvent]="previewBackgroundAnimationType"
            />
            <UiLibrarySelector
                v-if="backgroundAnimationTypeUses.includes('animation.zoomTarget')"
                :id="'card-seq-' + seqId + '-bg-animation-zoom-target'"
                key="library-selector-bg-animation-zoom-target"
                v-model="backgroundAnimationZoomTarget"
                :default-value="backgroundDefaultAnimationZoomTarget"
                library="zoomTargets"
                preview-path="animations/zoom-target/"
                :label="$t('Zoom target')"
                :use-selection-label="false"
                :disabled="readOnly || hasPreviousBackground"
                @[librarySelectorShowEvent]="openLibrary"
                @[librarySelectorPreviewChangeEvent]="previewBackgroundAnimationZoomTarget"
            />
        </div>
        <div class="ui-card-row" v-if="!isEmotionMode && backgroundType !== COLOR_TYPE && enableOverlay">
            <ui-background-overlay-selector :seq-id="seqId"></ui-background-overlay-selector>
        </div>

        <div v-if="backgroundType === COLLAGE_TYPE" class="sequence-background-collage-images ui-card-row">
            <ul>
                <li
                    v-for="(collageImage, index) in backgroundCollageImages"
                    :key="'collage-image' + index"
                    class="sequence-background-collage-image"
                >
                    <UiMediaSelector
                        :id="'card-seq-' + seqId + '-image' + (index + 1)"
                        :key="'media-selector-collage-image' + (index + 1)"
                        :media="collageImage"
                        :type="mediaTypeImage"
                        :label="sprintf($t('Image %1$d'), index + 1)"
                        :showLabel="false"
                        :disabled="readOnly"
                        icon="selector-image-icon"
                        @[mediaSelectorChangeEvent]="setCollageImage(index, $event)"
                        @[mediaSelectorShowEvent]="openMediaLibrary"
                    />
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapGetters, mapState } from 'vuex';
import { printf as sprintf } from 'fast-printf';
import { Align, Background, Color, STAGE_LOADING_ENDED_EVENT } from 'cte-video-studio';
import UiMediaSelector, {
    UI_MEDIA_SELECTOR_CHANGE,
    UI_MEDIA_SELECTOR_SHOW,
    UI_MEDIA_TYPE_ANIMATED,
    UI_MEDIA_TYPE_AUDIO,
    UI_MEDIA_TYPE_CARTOON,
    UI_MEDIA_TYPE_GIF,
    UI_MEDIA_TYPE_ICON,
    UI_MEDIA_TYPE_IMAGE,
    UI_MEDIA_TYPE_MUSIC,
    UI_MEDIA_TYPE_VIDEO
} from '../../../../components/UiMediaSelector.vue';
import UiColorSelector from '../../../../components/UiColorSelector.vue';
import UiLibrarySelector, {
    UI_LIBRARY_SELECTOR_PREVIEW_CHANGE,
    UI_LIBRARY_SELECTOR_SHOW
} from '../../../../components/UiLibrarySelector.vue';
import UiRecordingSelector, { UI_RECORDING_SELECTOR_CHANGE } from '../../../../components/UiRecordingSelector.vue';
import UiDropdown from '../../../../components/UiDropdown.vue';
import UiDropdownItem from '../../../../components/UiDropdownItem.vue';
import UiLocationSelector from './UiLocationSelector.vue';
import { UiIcon } from '../../../../components';
import UiIntervalInput from '../../../../components/UiIntervalInput.vue';
import { getPrefixedUuid } from '../../../../utils';

import UiBackgroundVolumeSelector from './selectors/volume/UiBackgroundVolumeSelector.vue';
import UiBackgroundVideoSpeedSelector from './selectors/speed/UiBackgroundVideoSpeedSelector.vue';
import UiBackgroundCaptionSelector from './selectors/caption/UiBackgroundCaptionSelector.vue';
import UiMediaTypeSelector from './selectors/media/UiMediaTypeSelector.vue';
import { RECORDING_TYPE_MICROPHONE, RECORDING_TYPE_SCREEN } from '../../../../constants';
import ModifiesHistory from '../../../mixins/ModifiesHistory';
import SavesVideo from '../../../mixins/SavesVideo';
import UiBackgroundOverlaySelector from './UiBackgroundOverlaySelector.vue';

export default defineComponent({
    mixins: [ModifiesHistory, SavesVideo],

    inject: ['$videoStudio'],

    components: {
        UiBackgroundOverlaySelector,
        UiIntervalInput,
        UiIcon,
        UiDropdownItem,
        UiDropdown,
        UiLocationSelector,
        UiRecordingSelector,
        UiLibrarySelector,
        UiMediaSelector,
        UiColorSelector,
        UiMediaTypeSelector,
        UiBackgroundVolumeSelector,
        UiBackgroundVideoSpeedSelector,
        UiBackgroundCaptionSelector
    },

    props: {
        seqId: {
            type: String,
            default: ''
        },
        cardElementId: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
            COLOR_TYPE: Background.COLOR_TYPE,
            IMAGE_TYPE: Background.IMAGE_TYPE,
            VIDEO_TYPE: Background.VIDEO_TYPE,
            CARTOON_TYPE: Background.CARTOON_TYPE,
            ANIMATED_TYPE: Background.ANIMATED_TYPE,
            COLLAGE_TYPE: Background.COLLAGE_TYPE,
            MAP_ZOOM_TYPE: Background.MAP_ZOOM_TYPE,
            RECORDING_TYPE: Background.RECORDING_TYPE,
            RECORDING_TYPE_SCREEN,
            RECORDING_TYPE_MICROPHONE,
            backgroundDefaultAnimationType: Background.ANIMATION_DEFAULT,
            backgroundDefaultAnimationZoomTarget: Align.CENTER,
            overlayDefaultOpacity: Color.BACKGROUND_OPACITY_DEFAULT,
            mediaTypeImage: UI_MEDIA_TYPE_IMAGE,
            mediaTypeIcon: UI_MEDIA_TYPE_ICON,
            mediaTypeGif: UI_MEDIA_TYPE_GIF,
            mediaTypeVideo: UI_MEDIA_TYPE_VIDEO,
            mediaTypeCartoon: UI_MEDIA_TYPE_CARTOON,
            mediaTypeAnimated: UI_MEDIA_TYPE_ANIMATED,
            mediaTypeAudio: UI_MEDIA_TYPE_AUDIO,
            mediaTypeMusic: UI_MEDIA_TYPE_MUSIC,
            mediaSelectorShowEvent: UI_MEDIA_SELECTOR_SHOW,
            mediaSelectorChangeEvent: UI_MEDIA_SELECTOR_CHANGE,
            librarySelectorShowEvent: UI_LIBRARY_SELECTOR_SHOW,
            librarySelectorPreviewChangeEvent: UI_LIBRARY_SELECTOR_PREVIEW_CHANGE,
            recordingSelectorChangeEvent: UI_RECORDING_SELECTOR_CHANGE,
            backgroundDefaultColor: Color.BACKGROUND_DEFAULT,
            backgroundCollageDefaultAnimationType: Background.COLLAGE_DEFAULT_TYPE,
            backgroundTypesRender: 0,
            triggerSelection: false
        };
    },

    computed: {
        ...mapState({
            readOnly: (state) => state.ui.readOnly,
            brandPalette: (state) => state.branding.palette,
            brandEnableOtherColors: (state) => state.branding.enableOtherColors,
            libraryTagOrders: (state) => state.branding.libraries.tagOrders,
            prefixes: (state) => state.ui.prefixes,
            sequenceState(state) {
                return state.sequences[this.seqId];
            },
            libraryId: (state) => state.ui.currentLibrary.id
        }),

        ...mapGetters({
            findLibraryItem: 'branding/libraries/findLibraryItem',
            isEmotionMode: 'ui/isEmotionMode',
            getMediaSrc: 'ui/quickcut/getMediaSrc'
        }),

        isMediaSelectorType() {
            return [this.IMAGE_TYPE, this.VIDEO_TYPE, this.CARTOON_TYPE, this.ANIMATED_TYPE].includes(
                this.backgroundType
            );
        },

        backgroundMediaType() {
            switch (this.backgroundType) {
                case this.IMAGE_TYPE:
                    return 'image';
                case this.VIDEO_TYPE:
                    return 'video';
                case this.CARTOON_TYPE:
                    return 'cartoon';
                case this.ANIMATED_TYPE:
                    return 'animated';
                default:
                    return '';
            }
        },

        mediaType() {
            switch (this.backgroundType) {
                case this.IMAGE_TYPE:
                    return this.mediaTypeImage;
                case this.VIDEO_TYPE:
                    return this.mediaTypeVideo;
                case this.CARTOON_TYPE:
                    return this.mediaTypeCartoon;
                case this.ANIMATED_TYPE:
                    return this.mediaTypeAnimated;
                default:
                    return '';
            }
        },

        mediaSelectorIcon() {
            switch (this.backgroundType) {
                case this.IMAGE_TYPE:
                case this.CARTOON_TYPE:
                    return 'fa-solid fa-image';
                case this.VIDEO_TYPE:
                case this.ANIMATED_TYPE:
                    return 'fa-solid fa-play';
                default:
                    return '';
            }
        },

        backgroundMedia: {
            get() {
                switch (this.backgroundType) {
                    case this.IMAGE_TYPE:
                    case this.CARTOON_TYPE:
                        return this.backgroundImage;
                    case this.VIDEO_TYPE:
                    case this.ANIMATED_TYPE:
                        return this.backgroundVideo;
                    default:
                        return {};
                }
            },
            set(media) {
                this.$store.commit('edition/setEditingElement', null);
                this.$store.dispatch(this.sequenceStoreModulePath + '/resetPosition'); // reset background position when media changes

                switch (this.backgroundType) {
                    case this.IMAGE_TYPE:
                    case this.CARTOON_TYPE:
                        this.backgroundImage = media;
                        break;
                    case this.VIDEO_TYPE:
                    case this.ANIMATED_TYPE:
                        this.backgroundVideo = media;
                        break;
                }
            }
        },

        sequenceStoreModulePath() {
            return 'sequences/' + this.seqId;
        },

        isFirstSequence() {
            return this.$store.getters[this.sequenceStoreModulePath + '/isFirstVisible'];
        },

        hasPreviousBackground() {
            return this.$store.getters[this.sequenceStoreModulePath + '/hasPreviousBackground'];
        },

        resolvedId() {
            return this.$store.getters[this.sequenceStoreModulePath + '/resolvedId'];
        },

        resolvedBackgroundState() {
            return this.$store.state.sequences[this.resolvedId].background;
        },

        backgroundTypes() {
            return this.isEmotionMode ? Background.TYPES.slice(0, -2) : this.contentModeCategories();
        },

        backgroundAnimationTypeMetadata() {
            return this.findLibraryItem('backgroundAnimations', '', this.backgroundAnimationType) || null;
        },

        backgroundAnimationTypeUses() {
            return (this.backgroundAnimationTypeMetadata && this.backgroundAnimationTypeMetadata.uses) || [];
        },

        enableOverlay() {
            return this.resolvedBackgroundState.overlay.enabled;
        },

        backgroundType: {
            get() {
                return this.sequenceState.background.type;
            },
            set(value) {
                this.saveHistoryStep(() => {
                    this.$store.commit(this.sequenceStoreModulePath + '/setBackground', value);
                });
            }
        },

        backgroundColor: {
            get() {
                let color = { ref: Color.BACKGROUND_DEFAULT, value: Color.BACKGROUND_DEFAULT };

                if (this.backgroundType === this.COLOR_TYPE) {
                    color.ref = this.resolvedBackgroundState.color.start__ref;
                    color.value = this.resolvedBackgroundState.color.start;
                }

                return color;
            },
            set({ ref, value }) {
                if (!this.hasPreviousBackground) {
                    this.saveHistoryStep(() => {
                        this.$store.commit(this.sequenceStoreModulePath + '/setBackgroundColor', {
                            start__ref: ref,
                            start: value
                        });
                    });
                }
            }
        },

        backgroundImage: {
            get() {
                let ref = { src: '', id: '' };

                if ([this.IMAGE_TYPE, this.CARTOON_TYPE].includes(this.backgroundType)) {
                    ref.src = this.resolvedBackgroundState.image.src;
                    ref.id = this.resolvedBackgroundState.image.src__id;
                }

                return ref;
            },
            set({ src, id }) {
                if (!this.hasPreviousBackground) {
                    this.saveHistoryStep(() => {
                        if (!this.sequenceState.background.image.src__ref) {
                            this.$store.commit(
                                this.sequenceStoreModulePath + '/image/setSourceRef',
                                getPrefixedUuid(this.prefixes.mediaReference)
                            );
                        }
                        this.$store.dispatch(this.sequenceStoreModulePath + '/image/update', {
                            src,
                            src__id: id
                        });
                    });
                }
            }
        },

        backgroundVideo: {
            get() {
                let ref = { src: '', id: '' };

                if ([this.VIDEO_TYPE, this.ANIMATED_TYPE].includes(this.backgroundType)) {
                    ref.src = this.resolvedBackgroundState.video.src;
                    ref.id = this.resolvedBackgroundState.video.src__id;
                }

                return ref;
            },
            set({ src, id }) {
                // reset video timerange segments and timerange if the video source is changed for UiQuickCut
                if (this.getMediaSrc !== src) {
                    this.$store.commit(`${this.sequenceStoreModulePath}/video/setTimerangeSegments`, []);
                    this.$store.commit(`${this.sequenceStoreModulePath}/video/setTimerange`, {
                        start: 0,
                        end: 0
                    });
                }
                if (!this.hasPreviousBackground) {
                    this.saveHistoryStep(() => {
                        if (!this.sequenceState.background.video.src__ref) {
                            this.$store.commit(
                                this.sequenceStoreModulePath + '/video/setSourceRef',
                                getPrefixedUuid(this.prefixes.mediaReference)
                            );
                        }
                        this.$store.dispatch(this.sequenceStoreModulePath + '/video/update', {
                            src,
                            src__id: id
                        });
                    });
                }
            }
        },

        backgroundRecording: {
            get() {
                let ref = { src: '', id: '' };

                if (this.backgroundType === this.RECORDING_TYPE) {
                    ref.src = this.resolvedBackgroundState.video.src;
                    ref.id = this.resolvedBackgroundState.video.src__id;
                }

                return ref;
            },
            set({ src, id }) {
                if (!this.hasPreviousBackground) {
                    this.saveHistoryStep(() => {
                        if (!this.sequenceState.background.video.src__ref) {
                            this.$store.commit(
                                this.sequenceStoreModulePath + '/video/setSourceRef',
                                getPrefixedUuid(this.prefixes.mediaReference)
                            );
                        }
                        this.$store.dispatch(this.sequenceStoreModulePath + '/video/update', {
                            src,
                            src__id: id
                        });
                    });
                }
            }
        },

        hasMapZoomBackground() {
            return this.$store.getters[`sequences/${this.resolvedId}/hasMapZoomBackground`];
        },

        backgroundMapZoom: {
            get() {
                let location = { coords: Background.DEFAULT_MAP_ZOOM_COORDS, address: '' };

                if (this.hasMapZoomBackground) {
                    location.coords = this.resolvedBackgroundState.mapZoom.coords;
                    location.address = this.resolvedBackgroundState.mapZoom.address;
                }

                return location;
            },
            set({ coords, address }) {
                if (!this.hasPreviousBackground) {
                    this.saveHistoryStep(() => {
                        this.$store.dispatch(this.sequenceStoreModulePath + '/updateBackgroundMapZoomLocation', {
                            coords,
                            address
                        });
                    });
                }
            }
        },

        backgroundAnimationType: {
            get() {
                return (
                    (this.backgroundType === this.IMAGE_TYPE ||
                        this.backgroundType === this.VIDEO_TYPE ||
                        this.backgroundType === this.ANIMATED_TYPE ||
                        this.backgroundType === this.COLLAGE_TYPE ||
                        this.backgroundType === this.CARTOON_TYPE ||
                        this.backgroundType === this.RECORDING_TYPE) &&
                    this.resolvedBackgroundState.animation.type
                );
            },
            set(type) {
                if (!this.hasPreviousBackground) {
                    this.saveHistoryStep(() => {
                        this.$store.commit(this.sequenceStoreModulePath + '/setBackgroundAnimation', type);
                    });
                }
            }
        },

        backgroundAnimationZoomTarget: {
            get() {
                return (
                    (this.backgroundType === this.IMAGE_TYPE ||
                        this.backgroundType === this.VIDEO_TYPE ||
                        this.backgroundType === this.ANIMATED_TYPE ||
                        this.backgroundType === this.RECORDING_TYPE) &&
                    this.resolvedBackgroundState.animation.zoomTarget
                );
            },
            set(zoomTarget) {
                if (!this.hasPreviousBackground) {
                    this.saveHistoryStep(() => {
                        this.$store.commit(this.sequenceStoreModulePath + '/setBackgroundAnimationZoom', {
                            zoomTarget
                        });
                    });
                }
            }
        },

        overlayColor: {
            get() {
                let color = { ref: Color.NONE, value: Color.NONE, alpha: Color.BACKGROUND_OPACITY_DEFAULT };

                if (this.enableOverlay) {
                    color.ref = this.resolvedBackgroundState.overlay.color.start__ref;
                    color.value = this.resolvedBackgroundState.overlay.color.start;
                    color.alpha = this.resolvedBackgroundState.overlay.opacity;
                }

                return color;
            },
            set({ ref, value, alpha }) {
                if (!this.hasPreviousBackground) {
                    this.saveHistoryStep(() => {
                        this.$store.commit(this.sequenceStoreModulePath + '/setBackgroundOverlayColor', {
                            start__ref: ref,
                            start: value
                        });
                        this.$store.commit(this.sequenceStoreModulePath + '/setBackgroundOverlayOpacity', alpha);
                    });
                }
            }
        },

        backgroundCollageImages: {
            get() {
                let index,
                    images = [];
                for (index = 0; index < Background.COLLAGE_MAX_MEDIA; index++) {
                    images[index] = {
                        src: this.$store.getters[this.sequenceStoreModulePath + '/collageImage'](index + 1),
                        id: this.$store.getters[this.sequenceStoreModulePath + '/collageImageId'](index + 1)
                    };
                }
                return images;
            },
            set(images) {
                let imagesStore = images.reduce((store, image, index) => {
                    if (image.id != this.$store.getters[this.sequenceStoreModulePath + '/collageImageId'](index + 1)) {
                        store['image' + (index + 1)] = image.src;
                        store['image' + (index + 1) + '__id'] = image.id;
                        if (!this.$store.getters[this.sequenceStoreModulePath + '/collageImageRef'](index + 1)) {
                            store['image' + (index + 1) + '__ref'] = getPrefixedUuid(this.prefixes.mediaReference);
                        }
                    }
                    return store;
                }, {});

                if (!!Object.keys(imagesStore).length) {
                    this.saveHistoryStep(() => {
                        this.$store.commit(this.sequenceStoreModulePath + '/setCollageImageReferences', imagesStore);
                        this.$store.dispatch(this.sequenceStoreModulePath + '/updateCollageImages', imagesStore);
                    });
                }
            }
        },

        isMediaConverting() {
            return (
                this.backgroundType === this.RECORDING_TYPE &&
                this.$store.getters['loading/isConverting'](this.backgroundRecording.src)
            );
        }
    },

    watch: {
        backgroundTypes: {
            handler() {
                this.backgroundTypesRender++;
            },
            deep: true
        },

        backgroundType() {
            this.triggerSelection = true; // need to be false at mounted
        }
    },

    methods: {
        sprintf,

        saveHistoryStep(actions) {
            this.startHistoryStep();
            actions();
            this.saveVideo();
        },

        openLibrary(selector) {
            const isMusicLibrary = selector.library === 'musics';

            this.$store.commit('ui/setCurrentLibrary', {
                id: selector.library,
                category: selector.category,
                tagOrder: selector.tagOrder || this.libraryTagOrders[selector.library] || null,
                selectedItemId: isMusicLibrary ? selector.modelValue.id : selector.modelValue,
                selector
            });
        },

        openMediaLibrary(selector) {
            this.$store.commit('ui/setCurrentMediaLibrary', {
                mediaType: selector.type,
                selectedMediaId: selector.media.id,
                selector
            });
        },

        contentModeCategories() {
            let cat1 = [
                    Background.IMAGE_TYPE,
                    Background.VIDEO_TYPE,
                    Background.COLLAGE_TYPE,
                    Background.RECORDING_TYPE
                ],
                cat2 = [Background.ANIMATED_TYPE, Background.CARTOON_TYPE],
                cat3 = [Background.COLOR_TYPE],
                cat4 = [Background.MAP_ZOOM_TYPE],
                actualType = this.backgroundType;

            return Background.TYPES.slice(0, -2).filter((type) => {
                return (
                    (cat1.includes(type) && cat1.includes(actualType)) ||
                    (cat2.includes(type) && cat2.includes(actualType)) ||
                    (cat3.includes(type) && cat3.includes(actualType)) ||
                    (cat4.includes(type) && cat4.includes(actualType))
                );
            });
        },

        playPreview(time) {
            this.$store.commit('preview/setCurrentTimelineId', this.seqId);
            this.$videoStudio.studio.$stage.seekSequenceTimeline(this.seqId, time || 0.001);
            this.$videoStudio.studio.$stage.playTimeline();
        },

        playBackgroundAnimationTypePreview() {
            this.playPreview(0.001);
        },

        previewBackgroundAnimationType(type, cancel) {
            this.ignoreHistoryStep(() => {
                this.$store.commit(this.sequenceStoreModulePath + '/setBackgroundAnimation', type);
                if (!cancel)
                    this.$videoStudio.studio.$stage.$el.addEventListener(
                        STAGE_LOADING_ENDED_EVENT,
                        this.playBackgroundAnimationTypePreview,
                        { once: true }
                    );
            });
        },

        shouldDisablePreviousType(type) {
            return this.isFirstSequence && type === Background.PREVIOUS_TYPE;
        },

        setSequenceTimelineToTime(time, willPlay = false) {
            this.$videoStudio.studio.$stage.seekSequenceTimeline(this.currentTimelineId, time);
            if (willPlay) {
                this.$videoStudio.studio.$stage.playTimeline();
            }
        },

        previewBackgroundAnimationZoomTarget(zoomTarget, cancel) {
            this.ignoreHistoryStep(() => {
                this.$store.commit(this.sequenceStoreModulePath + '/setBackgroundAnimationZoom', { zoomTarget });
                if (!cancel)
                    this.$videoStudio.studio.$stage.$el.addEventListener(
                        STAGE_LOADING_ENDED_EVENT,
                        this.playBackgroundAnimationTypePreview,
                        { once: true }
                    );
            });
        },

        setCollageImage(index, media) {
            let images = [...this.backgroundCollageImages];
            images[index] = media;
            this.backgroundCollageImages = [...images];
        },

        handleMediaSelectorChange() {
            if (this.backgroundMediaType === Background.VIDEO_TYPE) {
                this.resetVideoCaptions();
            }
        },

        resetVideoCaptions() {
            this.saveHistoryStep(() => {
                this.$store.commit(this.sequenceStoreModulePath + '/video/setCaptions', false);
            });
        }
    }
});
</script>

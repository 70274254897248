/**
 * Video Studio
 * Dimension constants
 */

const TOP = 'top';
const RIGHT = 'right';
const BOTTOM = 'bottom';
const LEFT = 'left';

const Dimension = {
    TOP,
    RIGHT,
    BOTTOM,
    LEFT,
    SIDES: [TOP, RIGHT, BOTTOM, LEFT],

    PERCENT_UNIT: '%',
    PIXEL_UNIT: 'px',
    EM_UNIT: 'em',
    DEGREE_UNIT: 'deg',

    AUTO: 'auto',

    ZERO: '0',
    FULL: '100%',

    CUSTOM: 'custom',

    PANEL_DEFAULT: '50%',
    VISUAL_DEFAULT: '30%',
    VISUAL_HEIGHT_DEFAULT: '40%',
    MESSAGE_DEFAULT: '45%',
    MESSAGE_HEIGHT_DEFAULT: '40%',
    MESSAGE_PADDING_DEFAULT: 36,
    CAPTIONS_PADDING_DEFAULT: 36,
    BORDER_SIZE_DEFAULT: 36,

    EDITING_FONT_SIZE_DEFAULT: 20,
    CONTROLS_FONT_SIZE_DEFAULT: 20,
    CONTROLS_REDUCED_MAX_WIDTH: 340
};

export default Dimension;

import ProduceVideoApiService from '@/js/infrastructure/api/video/ProduceVideoApiService.js';

export default class ProduceVideoService {
    constructor(store) {
        this.produceVideoApiService = new ProduceVideoApiService(store);
    }

    async handle(videoId) {
        return (await this.produceVideoApiService.handle(videoId)).data;
    }

    async handleTemplates(templateIds, brandId) {
        return (await this.produceVideoApiService.handleTemplates(templateIds, brandId)).data;
    }
}

<template>
    <div
        v-if="!error"
        class="asset-container video-container"
        ref="$videoContainer"
        :class="containerClasses"
        :style="containerStyles"
    >
        <video
            :ref="videoReference"
            v-show="!syncBy && !syncedAssets.length"
            :src="blobSrc || null"
            :style="videoComputedStyles"
            :class="videoComputedClasses"
            playsinline
            x-webkit-playsinline
        />
        <svg v-if="detection.browser.edge">
            <foreignObject width="100%" height="100%" class="studio-container" :style="svgContainerStyles">
                <div class="studio-container" xmlns="http://www.w3.org/1999/xhtml">
                    <video
                        :ref="svgVideoReference"
                        :src="blobSrc || null"
                        :class="svgVideoClasses"
                        :style="svgVideoStyles"
                    />
                </div>
            </foreignObject>
        </svg>
        <canvas
            v-if="syncBy || syncedAssets.length"
            :ref="canvasVideoReference"
            :width="videoSize.width"
            :height="videoSize.height"
            class="video-canvas"
        ></canvas>
    </div>
</template>

<script>
import { Dimension, Duration, Production, Sound, Timeline } from '../../constants';
import { conversions, randomID } from '../../utils';
import gsap from 'gsap';
import { mapGetters, mapState } from 'vuex';
import loader from '@/js/video-studio/loader.js';
import Croppable from '@/js/video-studio/mixins/Croppable.js';
import debounce from 'lodash/debounce';

export default {
    inject: ['$stage'],

    props: {
        src: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            default: ''
        },
        basisVolume: {
            type: Number,
            default: Sound.VOLUME_DEFAULT
        },
        volume: {
            type: Number,
            default: Sound.VOLUME_DEFAULT
        },
        captions: {
            type: [Boolean, Array],
            default: false
        },
        seqId: {
            type: String,
            default: ''
        },
        syncBy: {
            type: Object,
            default: null
        },
        classes: {
            type: String,
            default: ''
        },
        videoClasses: {
            type: String,
            default: ''
        },
        width: {
            type: String,
            default: Dimension.AUTO
        },
        height: {
            type: String,
            default: Dimension.AUTO
        },
        position: {
            type: Object,
            default: () => ({})
        },
        cover: {
            type: Boolean,
            default: false
        },
        styles: {
            type: Object,
            default: () => ({})
        },
        videoStyles: {
            type: Object,
            default: () => ({})
        },
        timerange: {
            type: Object,
            default: () => ({})
        },
        segments: {
            type: Array
        },
        playbackRate: {
            type: Number,
            default: 1
        },
        forceSize: {
            type: Boolean,
            default: false
        }
    },

    mixins: [Croppable],

    data() {
        return {
            error: false,
            active: false,
            size: {
                width: 0,
                height: 0
            },
            videoSize: {
                width: 0,
                height: 0
            },
            videoWidthAuto: false,
            videoHeightAuto: false,
            videoSeeking: false,
            videoTextTrack: null,
            cachedVideoElement: null,
            cachedCanvasVideoElement: null,
            currentTime: -1,
            duration: 0,
            endTime: 0,
            totalDuration: 0,
            syncedAssets: [],
            _isUpdatingTimeline: false
        };
    },

    computed: {
        ...mapState({
            detection: (state) => state.detection,
            preview: (state) => state.display.preview,
            format: (state) => state.display.format,
            playing: (state) => state.display.timeline.playing,
            muted: (state) => state.display.timeline.muted,
            captureMode: (state) => state.display.captureMode,
            avoidTimelineReflow: (state) => state.display.avoidTimelineReflow
        }),

        ...mapGetters({
            loading: 'loading/active',
            mobile: 'detection/mobile',
            ios: 'detection/ios',
            seeking: 'display/seeking'
        }),

        parentActive() {
            let parent = this.$parent;
            while (parent && !Object.hasOwn(parent, 'active')) {
                parent = parent.$parent;
            }
            return !parent || parent.active;
        },

        shouldPlay() {
            return this.active && this.parentActive && this.$stage.isCurrentSequenceTimeline(this.seqId);
        },
        isPlaying() {
            return this.playing && this.shouldPlay;
        },

        isSyncing() {
            return this.syncBy && this.syncBy.shouldPlay;
        },
        allSyncedAssets() {
            if (!this.syncedAssets.length) return [];
            let assets = [];
            this.syncedAssets.forEach((asset) => {
                assets.push(asset);
                if (asset.allSyncedAssets.length) assets = [...assets, ...asset.allSyncedAssets];
            });
            return assets;
        },

        showingCaptions() {
            return !this.isSyncing && this.shouldPlay;
        },

        containerClasses() {
            return [
                this.classes,
                {
                    'cover-mode': this.cover,
                    'mobile-mode': this.mobile,
                    'svg-mode': this.detection.browser.edge,
                    'asset-cropped': this.hasCropPosition
                }
            ];
        },

        alignmentStyles() {
            const { alignH, alignV, alignX, alignY } = this.position;
            return conversions.alignment(alignH, alignV, alignX, alignY);
        },

        containerStyles() {
            return {
                ...this.styles,
                width: this.forceSize ? this.width : this.size.width || this.width,
                height: this.forceSize ? this.height : this.size.height || this.height,
                ...this.alignmentStyles
            };
        },

        blobSrc() {
            return this.$store.getters['loading/getBlob'](this.src);
        },

        hasError() {
            return this.$store.getters['loading/hasError'](this.src);
        },

        videoReference() {
            return this.name + '-video';
        },

        videoComputedStyles() {
            return Object.assign(
                {},
                this.videoStyles,
                { width: this.width == Dimension.AUTO ? Dimension.AUTO : null },
                this.cropStyles
            );
        },

        videoComputedClasses() {
            return ['asset', this.videoClasses];
        },

        svgVideoReference() {
            return this.name + '-svg-video';
        },

        canvasVideoReference() {
            return this.name + '-canvas-video';
        },

        svgVideoClasses() {
            return {
                'video-width-auto': this.videoWidthAuto,
                'video-height-auto': this.videoHeightAuto
            };
        },

        svgForeignObjectStyles() {
            let { clipPath, mask, ...styles } = this.videoComputedStyles;
            return { clipPath, mask };
        },

        svgContainerStyles() {
            let { border, borderTop, borderRight, borderBottom, borderLeft, ...styles } = this.videoComputedStyles;
            return { border, borderTop, borderRight, borderBottom, borderLeft };
        },

        svgVideoStyles() {
            let { clipPath, mask, border, borderTop, borderRight, borderBottom, borderLeft, ...styles } =
                this.videoComputedStyles;
            return styles;
        },

        isValidTimerange() {
            return this.timerange.end > this.timerange.start;
        }
    },

    watch: {
        playbackRate(newValue) {
            if (this.blobSrc) {
                this.onVideoLoaded();
            }
        },
        segments: {
            handler: debounce(function () {
                this.updateTimeline(this.getVideoElement());
                this.$emit('update');
            }, 1000),
            deep: true
        },
        format(newValue, oldValue) {
            if (!this.cover && (newValue.width != oldValue.width || newValue.height != oldValue.height))
                this.updateSize();
        },

        src(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.updateSrc(newValue, oldValue);
            }
        },

        blobSrc(newValue) {
            if (newValue && this.mobile)
                this.$nextTick(() => {
                    this.resetVideoElementCache();
                    this.resetCanvasVideoElementCache();
                    this.getVideoElement().load();
                });
        },

        width() {
            if (!this.cover) this.updateSize();
        },

        height() {
            if (!this.cover) this.updateSize();
        },

        volume(newValue) {
            let videoElement = this.getVideoElement();
            videoElement.volume = !this.isSyncing ? this.basisVolume * newValue : 0;
            videoElement.muted = this.muted || (this.mobile && this.basisVolume * newValue == 0);
        },

        basisVolume(newValue) {
            let videoElement = this.getVideoElement();
            videoElement.volume = !this.isSyncing ? newValue * this.volume : 0;
            videoElement.muted = this.muted || (this.mobile && newValue * this.volume == 0);
        },

        captions: {
            handler() {
                this.updateCaptions();
            },
            deep: true
        },

        showingCaptions() {
            this.sendCaptionEvent();
        },

        syncBy(newValue, oldValue) {
            if (!!oldValue) oldValue.removeSyncedAsset(this);
            if (!!newValue) newValue.addSyncedAsset(this);
        },

        allSyncedAssets: {
            handler(newValue) {
                if (newValue.length) this.$nextTick(this.drawFrame);
            },
            deep: true
        },

        currentTime(newValue, oldValue) {
            let videoElement = this.getVideoElement();

            this._lastFrame = -1;
            if (
                (!this.error &&
                    Math.abs(newValue - videoElement.currentTime) > Duration.VIDEO_SYNC_THRESHOLD * this.playbackRate &&
                    !videoElement.seeking) ||
                (!this.preview && this.captureMode == Production.FRAME_CAPTURE_MODE)
            ) {
                if (!this.loading /* && this.preview*/) {
                    this.videoSeeking = true;
                    this.$store.commit('display/seek', this._internalID);
                    videoElement.addEventListener('seeked', this.waitSeekedFrame);
                }

                videoElement.currentTime = newValue;
                this.sendCaptionEvent();
            }
            this.drawFrame();
        },

        isPlaying(newValue, oldValue) {
            let videoElement = this.getVideoElement();
            if (!newValue) {
                this.endSeeking();
                if (!videoElement.paused) videoElement.pause();
            } else if (newValue && !this.seeking && videoElement.paused) this.playElement();
            this.sendCaptionEvent();
        },

        isSyncing(newValue, oldValue) {
            let videoElement = this.getVideoElement();

            videoElement.volume = !newValue ? this.basisVolume * this.volume : 0;
            videoElement.muted = this.muted || (this.mobile && this.basisVolume * this.volume == 0);

            this.sendCaptionEvent();
        },

        muted(newValue, oldValue) {
            this.getVideoElement().muted = newValue || (this.mobile && this.basisVolume * this.volume == 0);
        },

        seeking(newValue, oldValue) {
            let videoElement = this.getVideoElement();

            if (newValue && !this.videoSeeking && !videoElement.paused) videoElement.pause();
            else if (!newValue && this.isPlaying && videoElement.paused) this.playElement();
            this.sendCaptionEvent();
        },

        videoSeeking(newValue, oldValue) {
            let videoElement = this.getVideoElement();

            if (!newValue && this.seeking && !videoElement.paused) videoElement.pause();
            else if (newValue && this.isPlaying && videoElement.paused) this.playElement();
        },

        canvasVideoReference(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.resetCanvasVideoElementCache();
            }
        },

        'detection.browser.edge': () => {
            this.resetVideoElementCache();
        }
    },

    methods: {
        getVideoElement() {
            if (this.cachedVideoElement) {
                return this.cachedVideoElement;
            }

            const refName = this.detection.browser.edge ? this.svgVideoReference : this.videoReference;

            this.cachedVideoElement = this.$refs[refName] || this._emptyVideoElement;

            this.currentTime = this.cachedVideoElement.currentTime;

            return this.cachedVideoElement;
        },

        resetVideoElementCache() {
            this.cachedVideoElement = null;
        },

        getCanvasVideoElement() {
            if (this.cachedCanvasVideoElement) {
                return this.cachedCanvasVideoElement;
            }

            this.cachedCanvasVideoElement = this.$refs[this.canvasVideoReference] || this._emptyCanvasVideoElement;
            return this.cachedCanvasVideoElement;
        },

        resetCanvasVideoElementCache() {
            this.cachedCanvasVideoElement = null;
        },

        playElement() {
            const p = this.getVideoElement().play();
            this.drawFrame();
            if (p)
                p.catch((error) => {
                    if (error.name == 'NotAllowedError') {
                        this.$stage.muteTimeline();
                        this.$nextTick(() => {
                            this.getVideoElement().play();
                        });
                    }
                });
        },

        start() {
            this._timeline.restart();
        },

        play() {
            this._timeline.play();
        },

        pause() {
            let videoElement = this.getVideoElement();

            this._timeline.pause();
            videoElement.pause();
            videoElement.currentTime = this.currentTime;
        },

        timeline() {
            return this._timeline;
        },

        addSyncedAsset(asset) {
            this.syncedAssets.push(asset);
        },

        removeSyncedAsset(asset) {
            this.syncedAssets.splice(this.syncedAssets.indexOf(asset), 1);
        },

        drawFrame(event, force = false) {
            if ((this.syncBy || this.allSyncedAssets.length) && (!this.isSyncing || force)) {
                let canvasVideoElement = this.getCanvasVideoElement();

                canvasVideoElement.getContext('2d').drawImage(this.getVideoElement(), 0, 0);
                if (this.shouldPlay) {
                    this.allSyncedAssets.forEach((asset) => {
                        if (asset.shouldPlay) {
                            asset.getCanvasVideoElement().getContext('2d').drawImage(canvasVideoElement, 0, 0);
                        }
                    });
                }
            }
        },

        updateSrc(newValue, oldValue) {
            let videoElement = this.getVideoElement();

            // TODO: add support for fetch cancellation
            videoElement.removeEventListener('seeked', this.waitFirstFrame);
            videoElement.removeEventListener('seeked', this.onVideoFirstFrame);

            this.size = Object.assign({}, this.size, { width: 0, height: 0 });
            if (oldValue) loader.unload(oldValue, this.onVideoError);
            if (newValue) {
                this.$store.commit('loading/prepare', this);
                if (videoElement.isConnected) {
                    this.setVideoListeners(videoElement);
                    loader.load(newValue, this.onVideoError);
                } else {
                    this.$nextTick(() => {
                        this.resetVideoElementCache();
                        if (this.videoTextTrack)
                            this.videoTextTrack.removeEventListener('cuechange', this.sendCaptionEvent);
                        this.videoTextTrack = null;
                        this.setVideoListeners(this.getVideoElement());
                        loader.load(newValue, this.onVideoError);
                    });
                }
            } else {
                this.removeVideoListeners(videoElement);
                videoElement.removeEventListener('seeked', this.drawFrame);
                this.$nextTick(() => {
                    this.updateTimeline(videoElement);
                    this.$store.commit('loading/prepared', this);
                });
            }
            this.updateCaptions();
            this.error = !newValue;
        },

        updateTimeline(videoElement) {
            if (this.avoidTimelineReflow) return;

            if (this._isUpdatingTimeline) return;
            this._isUpdatingTimeline = true;
            try {
                let segments = this.segments;
                if (!segments || segments.length === 0) {
                    segments = [{ start: 0, end: -1 }];
                }

                // Valider et ajuster les segments
                const adjustedSegments = segments.map((segment) => {
                    const start = Number(segment.start) || 0;
                    const rawEnd = segment.end === -1 ? videoElement.duration : Number(segment.end);
                    const end = isFinite(rawEnd) ? rawEnd : videoElement.duration;
                    const duration = (end - start) / (this.playbackRate || 1);

                    // Vérifier que la durée est valide
                    return {
                        start: isFinite(start) ? start : 0,
                        end: isFinite(end) ? end : videoElement.duration,
                        duration: isFinite(duration) ? duration : 0
                    };
                });

                this._timeline = gsap.timeline({ paused: true, id: Timeline.VIDEO_TIMELINE_ID });

                adjustedSegments.forEach(({ start, end, duration }) => {
                    if (videoElement.isConnected && duration > 0) {
                        this._timeline.set(this, { active: true }, 0.0001);
                        this._timeline.fromTo(
                            this,
                            { currentTime: start },
                            {
                                duration: duration,
                                currentTime: end,
                                ease: 'none'
                            }
                        );
                    }
                });

                if (videoElement.isConnected) {
                    const finalTime = adjustedSegments[0]?.end;
                    if (isFinite(finalTime)) {
                        this._timeline.set(this.getVideoElement(), { currentTime: finalTime });
                    }
                    this._timeline.set(this, { active: false });
                }
            } finally {
                this._isUpdatingTimeline = false;
            }
        },

        updateSize(callback) {
            let videoElement = this.getVideoElement();

            if (videoElement.isConnected) {
                this.size = Object.assign({}, this.size, {
                    width: 0,
                    height: 0
                });

                this.$nextTick(() => {
                    let s = { width: 0, height: 0 },
                        r = videoElement.videoWidth / videoElement.videoHeight,
                        rc = this.$refs.$videoContainer.clientWidth / this.$refs.$videoContainer.clientHeight;

                    if (!this.cover || this.height == Dimension.AUTO) {
                        if (rc > r) {
                            s.width = Math.round(r * this.$refs.$videoContainer.clientHeight) + Dimension.PIXEL_UNIT;
                            if (this.height == Dimension.AUTO)
                                s.height = this.$refs.$videoContainer.clientHeight + Dimension.PIXEL_UNIT;
                        } else {
                            s.height = Math.round(this.$refs.$videoContainer.clientWidth / r) + Dimension.PIXEL_UNIT;
                            if (this.width == Dimension.AUTO)
                                s.width = this.$refs.$videoContainer.clientWidth + Dimension.PIXEL_UNIT;
                        }
                    } else {
                        this.videoWidthAuto = rc <= r;
                        this.videoHeightAuto = rc > r;
                    }
                    this.size = s;
                    if (callback) this.$nextTick(callback);
                });
            }
        },

        updateCaptions() {
            if (!this.videoTextTrack) {
                this.videoTextTrack = this.getVideoElement().addTextTrack('captions');
                this.videoTextTrack.addEventListener('cuechange', this.sendCaptionEvent);
            }
            while (this.videoTextTrack.cues.length) {
                this.videoTextTrack.removeCue(this.videoTextTrack.cues[0]);
            }
            if (this.captions) {
                this.captions.forEach((caption) => {
                    if (
                        !isNaN(caption.startTime) &&
                        isFinite(caption.startTime) &&
                        !isNaN(caption.endTime) &&
                        isFinite(caption.endTime)
                    )
                        this.videoTextTrack.addCue(new VTTCue(caption.startTime, caption.endTime, caption.text));
                });
            }
            this.sendCaptionEvent();
        },

        sendCaptionEvent() {
            this.$stage.updateActiveCaptions(
                this.showingCaptions ? this.videoTextTrack.activeCues : [],
                this._internalID
            );
        },

        waitFirstFrame() {
            let videoElement = this.getVideoElement();

            videoElement.removeEventListener('seeked', this.waitFirstFrame);
            videoElement.addEventListener('seeked', this.onVideoFirstFrame);
            videoElement.currentTime = this.isValidTimerange ? this.timerange.start : 0;
        },

        waitSeekedFrame() {
            let videoElement = this.getVideoElement();

            videoElement.removeEventListener('seeked', this.waitSeekedFrame);
            this.drawFrame();
            if (!this.isPlaying || (!this.detection.browser.safari && !this.ios)) {
                this.endSeeking();
            } else {
                this._lastFrame = videoElement.currentTime;
                videoElement.addEventListener('timeupdate', this.waitPlayingFrame);
            }
        },

        waitPlayingFrame() {
            if (!this.isPlaying || (this._lastFrame != -1 && this.getVideoElement().currentTime > this._lastFrame))
                this.endSeeking();
        },

        endSeeking() {
            this.getVideoElement().removeEventListener('timeupdate', this.waitPlayingFrame);
            this.videoSeeking = false;
            this._lastFrame = -1;
            this.$store.commit('display/seeked', this._internalID);
        },

        setVideoListeners(videoElement) {
            videoElement.addEventListener('canplaythrough', this.onVideoLoaded);
            videoElement.addEventListener('error', this.onVideoError);
        },

        removeVideoListeners(videoElement) {
            videoElement.removeEventListener('canplaythrough', this.onVideoLoaded);
            videoElement.removeEventListener('error', this.onVideoError);
        },

        onVideoLoaded() {
            if (this._isLoading) return;
            this._isLoading = true;

            try {
                let videoElement = this.getVideoElement();
                this.totalDuration = videoElement.duration;
                videoElement.playbackRate = this.playbackRate;

                this.removeVideoListeners(videoElement);
                videoElement.addEventListener('seeked', this.drawFrame);
                videoElement.addEventListener('seeked', this.waitFirstFrame);

                this.videoSize = {
                    width: videoElement.videoWidth,
                    height: videoElement.videoHeight
                };

                videoElement.volume = this.basisVolume * this.volume;
                videoElement.muted = this.muted || (this.mobile && this.basisVolume * this.volume == 0);

                this.updateCaptions();
                videoElement.currentTime = 0;

                if (this.syncBy) {
                    this.$nextTick(() => {
                        this.syncBy.drawFrame();
                        this._isLoading = false;
                    });
                } else {
                    this._isLoading = false;
                }
            } catch (error) {
                console.error('Error in onVideoLoaded:', error);
                this._isLoading = false;
            }
        },

        onVideoFirstFrame() {
            let videoElement = this.getVideoElement();

            videoElement.removeEventListener('seeked', this.onVideoFirstFrame);

            this.updateTimeline(videoElement);
            if (this.cover && !this.detection.browser.edge) {
                this.drawFrame();
                this.$emit('load-success');
                if (!this.avoidTimelineReflow) this.$emit('update', { el: this.$refs.$videoContainer });
                this.$store.commit('loading/prepared', this);
            } else {
                this.updateSize(() => {
                    this.drawFrame();
                    this.$emit('load-success');
                    if (!this.avoidTimelineReflow) this.$emit('update', { el: this.$refs.$videoContainer });
                    this.$store.commit('loading/prepared', this);
                });
            }
        },

        onVideoError() {
            if (this.blobSrc || this.hasError) {
                let videoElement = this.getVideoElement();
                this.totalDuration = 0;

                this.removeVideoListeners(videoElement);
                videoElement.removeEventListener('seeked', this.drawFrame);
                this.videoSize = {
                    width: 0,
                    height: 0
                };
                this.error = true;
                this.$nextTick(() => {
                    this.updateTimeline(videoElement);
                    this.$store.commit('loading/prepared', this);
                    this.$emit('load-error');
                });
            }
        }
    },

    created() {
        this._internalID = randomID('video_');
        this._timeline = gsap.timeline({ paused: true, id: Timeline.VIDEO_TIMELINE_ID });
        // this._lastFrame = -1;
    },

    mounted() {
        this._emptyVideoElement = document.createElement('video');
        this._emptyCanvasVideoElement = document.createElement('canvas');
        if (this.syncBy) this.syncBy.addSyncedAsset(this);
        this.updateSrc(this.src);
        this.getCanvasVideoElement();
    },

    beforeUnmount() {
        if (this.syncBy) this.syncBy.removeSyncedAsset(this);
        this.active = false;
        this.updateSrc('', this.src);
        this._timeline.kill();
    }
};
</script>

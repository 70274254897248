<template>
    <svg
        width="58"
        height="58"
        viewBox="0 0 58 58"
        preserveAspectRatio="xMidYMid slice"
        class="studio-bg-loading-production"
        :style="loadingProductionStyles"
    >
        <defs>
            <symbol id="loading-production-logo-icon" width="58" height="58" viewBox="0 0 58 58">
                <linearGradient
                    id="linear-gradient"
                    x1="-0.054"
                    y1="0.04"
                    x2="0.917"
                    y2="0.846"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stop-color="#fff" stop-opacity="0.898" />
                    <stop offset="0.24" stop-color="#fff" stop-opacity="0.89" />
                    <stop offset="0.32" stop-color="#fff" stop-opacity="0.871" />
                    <stop offset="0.38" stop-color="#fff" stop-opacity="0.82" />
                    <stop offset="0.43" stop-color="#fff" stop-opacity="0.749" />
                    <stop offset="0.47" stop-color="#fff" stop-opacity="0.671" />
                    <stop offset="0.5" stop-color="#fff" stop-opacity="0.6" />
                </linearGradient>
                <g id="b" transform="translate(-84.217 170)">
                    <g id="c" transform="translate(84.217 -170)">
                        <rect
                            id="Rectangle_1"
                            data-name="Rectangle 1"
                            width="7.142"
                            height="24.723"
                            rx="3.571"
                            transform="translate(18.82 17.5)"
                            fill="#fff"
                        />
                        <rect
                            id="Rectangle_2"
                            data-name="Rectangle 2"
                            width="7.142"
                            height="24.723"
                            rx="3.571"
                            transform="translate(21.071 43.523) rotate(-120)"
                            fill="#fff"
                        />
                        <rect
                            id="Rectangle_3"
                            data-name="Rectangle 3"
                            width="7.142"
                            height="14.834"
                            rx="3.571"
                            transform="translate(26.082 27.335) rotate(-60)"
                            opacity="0.9"
                            fill="url(#linear-gradient)"
                        />
                    </g>
                </g>
            </symbol>
            <pattern id="loading-production-logo-pattern" width="10%" height="10%" viewBox="0 0 58 58">
                <use xlink:href="#loading-production-logo-icon" class="loading-production-logo-pattern-icon" />
            </pattern>
        </defs>
        <rect class="loading-production-bg" x="0" y="0" width="100%" height="100%" :fill="loadingProductionPattern" />
    </svg>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
    stageScale: Number,
    isSafari: Boolean
});

const loadingProductionStyles = computed(() => ({ transform: 'scale(' + 1 / props.stageScale + ')' }));
const loadingProductionPattern = computed(
    () => 'url(' + (props.isSafari ? window.location.href : '') + '#loading-production-logo-pattern)'
);
</script>

import media from './media';
import TTS from '@/js/video-studio/constants/tts.js';

const state = () => ({
    ...media.state(),

    voiceId: '',
    text: '',
    status: null,
    exaggeration: 50,
    upToDate: false
});

const getters = {
    ...media.getters
};

const mutations = {
    ...media.mutations,

    setVoiceId(state, id) {
        state.voiceId = id;
    },

    setText(state, text) {
        state.text = text;
    },

    setStatus(state, status) {
        state.status = status;
    },

    setExaggeration(state, exaggeration) {
        state.exaggeration = exaggeration;
    },

    setUpToDate(state, upToDate) {
        state.upToDate = upToDate;
    }
};

const actions = {
    ...media.actions,

    init({ commit, dispatch }, data) {
        media.actions.init({ commit, dispatch }, data);

        if (data.voiceId) commit('setVoiceId', data.voiceId);
        if (data.text) commit('setText', data.text);
        if (data.status) commit('setStatus', data.status);
        if (data.exaggeration) commit('setExaggeration', data.exaggeration);
        if (data.upToDate) commit('setUpToDate', data.upToDate);
    },

    resetVoice({ commit }) {
        commit('setStatus', null);
        commit('setSource', '');
        commit('setSourceId', '');
    },

    setVoiceProcessing({ commit }) {
        commit('setStatus', TTS.STATUS_PROCESSING);
    },

    stopVoiceProcessing({ commit }) {
        commit('setStatus', null);
    },

    handleVoiceResult({ commit }, result) {
        commit('setSource', result.src);
        commit('setSourceId', result.src__id);
        commit('setTimerange', null);
        commit('setTimerangeSegments', []);
    },

    handleVoiceError({ commit }, error) {
        commit('setStatus', error);
        commit('setSource', '');
        commit('setSourceId', '');
    }
};

export default {
    namespaced: true,

    state,
    getters,
    mutations,
    actions
};
